<script setup>
import { defineProps, ref } from "vue";
import { useRouter } from 'vue-router';
import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/auth.js";


    const auth = useAuthStore();
    const route = useRoute();
    const router = useRouter();
    const props = defineProps(["accepted_students"]);
    const id = ref(route.params.id);
    function studentDetails (obj, id) {
        $('.student-btn').removeClass('active');
        $(obj).addClass('active');
        router.push({ name: 'parent-student-details', params: { id: id } })
    }

    function pendingStudents() {
        router.push({ name: 'dashboard' })
    }
</script>
<template>
     <div class="card">
          <div class="card-body p-0" style="overflow: hidden;">
              <div class="row" :class="$attrs.parent_child?'parent-child':''" style="padding:8px 16px;">
                <div class="col-md-3">
                    <button class='btn btn-clean btn-block' :class="(auth.curentEndPoint != 'parent-student-details' ) ? 'active' : ''" @click="pendingStudents()">Pending</button>
                </div>
                    <div  class="col-md-3" v-for="(accepted_student, index) in accepted_students" :key="index">
                        <button class='btn btn-clean btn-block student-btn' :class="accepted_student.student.id == id ? 'active' : ''" @click="studentDetails($event.target,accepted_student.student.id)">{{ accepted_student.student.full_name }}</button>
                    </div>
               </div>
          </div>
     </div>
 </template>
 
 <style scoped>
 /* Form Card CSS Start */
 .btn-clean{
     background: white;
     border-radius: 8px;
     color: #89939E;
 }
 .btn-clean.active{
     background: rgba(36, 99, 235, 0.1) !important;
     border-radius: 8px;
     color: var(--primary);
 }
 .card {
     background: #FFFFFF;
 
     box-shadow: 0px 15px 30px rgba(186, 186, 186, 0.1);
     border-radius: 12px;
     ;
 }
 
 .custom-back-btn:hover {
     background: #D0DEFB;
     color: var(--primary);
 }
 
 .custom-back-btn {
     border-radius: 8px;
     padding: 6px 12px 6px 10px;
     gap: 8px;
     color: #9CA3AF;
 }
 
 .card-body .bgimg {
     position: absolute;
     right: 0px;
     top: 0px;
 }
 
 .card.parent-students-list .col-md-3 {
    width: fit-content;
}
.parent-child{
    display: flex;
    flex-flow: nowrap;
    max-width: 100%;
    overflow: auto;
}
.parent-child::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.parent-child {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
 /* Form Card CSS End */</style>