<template>
  <div :class="$attrs.class">
    <div class="form-group mb-3">
      <label v-if="label && modelValue" class="form-label" :for="id">{{ label }}</label>
      <textarea :id="id" :rows="($attrs.rows)?$attrs.rows:4" ref="input" v-bind="{ ...$attrs, class: null }" class="form-control"
      :class="((label && modelValue)?' label-input ':' ')" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
      <div v-if="error" class="form-error">{{ error }}</div>
      <div class="col-form-label text-danger" v-if="$attrs.textinfo != undefined && $attrs.textinfo != null && $attrs.textinfo != ''">{{$attrs.textinfo}}</div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `textarea-input-${uuid()}`
      },
    },
    error: String,
    label: String,
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
  },
}
</script>
<style scoped>
.form-control{     
  padding: 12px 20px;
  font-family: var(--def-fontfamily);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #4D4D4D;
}

.label-input{
  padding: 22px 12px 14px 16px !important;
} 
label{
  position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    padding-left: 20px;
    padding-top: 12px;
    color: #4D4D4D;
    z-index: 1;
}
</style>
