<template>
  <div  style="padding-bottom: 24px !important;">
  <div class="iconbox">
    <div class="icon-placeholder">
      <img :src="$attrs.img" style="max-width: 64px;" class="icon" v-if="$attrs.isSvg" />
      <i :class="$attrs.icon" v-else></i>
    </div>
    <h3 class="modile-h4">{{ $attrs.heading }}</h3>
    <h6>{{ $attrs.shortdesc }}</h6>
  </div>
  </div>
</template>
<style scoped>
.icon-placeholder{
  position: relative;
  width: 104px;
  height: 100.97px; 
  background: rgba(36, 99, 235, 0.1);
  border-radius: 12px;
  margin-bottom: 32px;
}
.iconbox {
  background: rgba(36, 99, 235, 0.05);
  border-radius: 16px;
  align-items: flex-start;
  padding: 40px;
  gap: 32px;
  min-height: 342.97px;
}
.icon{
  position: absolute;
  width: 64px;
  height: 64px;
  left: 20px;
  top: 18px;
 
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 83px;
}


h6 {
  text-align: left;
}
</style>
 
