import {
  createApp,
  watch
} from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import axios from "axios";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import {
  createPinia
} from "pinia";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment';
// import messaging from './service/firebase-init';
// ********* for bootstrap 5 uncomment it *********
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap";
// ********* for bootstrap 5 uncomment it *********

const pinia = createPinia();
// pinia.use(piniaPluginPersistedState);

var hours = 24; // to clear the localStorage after 1 hour
var now = new Date().getTime();
var setupTime = localStorage.getItem('setupTime');
if (setupTime == null) {
    localStorage.setItem('setupTime', now)
} else {
    if(now-setupTime > hours*60*60*1000) {
        localStorage.clear()
        // localStorage.setItem('setupTime', now);
    }
}

// var localstoragejson = JSON.parse(localStorage.getItem("state"));
if (localStorage.getItem("state")) { 
  pinia.state.value = JSON.parse(localStorage.getItem("state"));
} 



watch(
  pinia.state,
  (state) => {
    localStorage.setItem("state", JSON.stringify(state));
  }, {
    deep: true
  }
);
const app = createApp(App);
// window.$ = window.jQuery = require("jquery");
app.config.globalProperties.$axios = axios;
// app.config.globalProperties.$moment = moment;
app.use(pinia);
app.use(router);
app.config.globalProperties.$moment = moment
app.component('VueDatePicker', VueDatePicker);
// app.config.globalProperties.$messaging = firebaseMessaging
// app.config.globalProperties.messaging = messaging;

 



app.mount("#app");