<script setup>
import NewChild from "../views/parent/NewChild.vue";
import ParentStudents from "@/views/parent/ParentStudents.vue";
import { ref, onMounted } from "vue";
import CommonSvgs from '@/components/CommonSvgs.vue';
import CardBox from "@/components/CardBox.vue";
import { useAuthStore } from "@/store/auth.js";
import FoundChilds from "./FoundChilds.vue";
import factory from "@/common/factory.js";
import TextInput from "@/components/TextInput.vue";
import Toastr from "@/components/Toastr.vue";

// import router from "@/router";
import { useRouter } from 'vue-router';
     
const auth = useAuthStore();
     var form = ref({
          user_name: '',
          phone: auth.userinfo.phone1
     });
     const isModal = ref(true);
     let credientials = ref();

     const phoneNo = ref('');
     const studentCount = ref('');
     const parentChilds = ref('');
     const isStudents = ref(true);
     var currentpage= ref(1);
     const step = ref(1);
     const router = useRouter();
     const searchFullName = ref('');
     const searchSchoolName = ref('');
     const searchChildDOB = ref('');
     const searchChildId=  ref('');
     const studentRecord = ref('');
     var invitations= ref(1);
     const bgColor = ref(true);
     
     const created_user_name = ref('');
     const created_user_password = ref('');

     phoneNo.value = auth.userinfo.phone1;
     function getToken() {
      const token = {
               headers: {
               // 'Content-Type': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + auth.token,
               },
          };
          return token;
     }

     function getAge(dateString) {
          var today = new Date();
          var birthDate = new Date(dateString);
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
               age--;
          }
          return age;
     }

     async function student() {
          const students = new factory(getToken(), 'parent-students');
          await students.getHttp().then((response) => {
               swal.close();
               if (response.data.status) {
                    studentCount.value = response.data.data.students.length;
                    parentChilds.value = response.data.data.students;
                    if(studentCount.value>0) {
                         isStudents.value = true;
                    }
                    else {
                         step.value = 2;
                         isStudents.value = false;
                    }
               } else {
               }
          });
     };

     onMounted(() => {
          student();   
     });

     function fetchStudents() {
          checkUserName();
          student();
     }

     function studentChilds () {
          step.value = 1;
     }

     function childCard () {
          step.value = 2;
     }

     function searchChildCard () {
          step.value = 3;
     }

     function newAccountChild() {
          step.value = 5;
     }

     function goToDashboard () {
          router.push('/dashboard');
     }

     
async function checkUserName() {
     const checkUserNameAvailability = new factory(getToken(), 'get-parent-child-by-username', form.value);
     await checkUserNameAvailability.getHttp().then((response) => {
     if (response.data.status) {
          searchFullName.value = response.data.data.full_name;
          searchSchoolName.value = response.data.data.student.student_class.school.name;
          searchChildDOB.value = getAge(response.data.data.student.dob);
          searchChildId.value = response.data.data.student.id;
          invitations.value = response.data.data.student.parent_student_invitations;

          $('#alreadyAccount').modal('hide');
          $('#foundChild').modal('show');
          step.value = 4;
          // student();
     } 
     else {
          $('.not_found_message').removeClass('d-none').addClass('d-block');
     }
  });
};

const copy = async () => {
     let user_name = document.getElementById('created_user_name').innerHTML;
     let user_password = document.getElementById('created_user_password').innerHTML;
     try {
          let message = "The account of your child has been \nsuccessfully activated. Please login\nusing the below username and\npassword. Here are the credentials\nof your child(s) account.\n\n" +
               "Username: " + user_name + "\n" +
               "Password: " + user_password + "\n\n" +
               "Contact our customer support in\ncase you need any assistance.\n\n" +
               "Alternates";
          credientials.value = await navigator.clipboard.writeText(message);
          $('.credientials').val(credientials.value);
          $(".custom-toaster").removeClass('d-none');
          setTimeout(() => {
               $(".custom-toaster").addClass('d-none');
          }, 5000);

     } catch (err) {
          console.error('Failed to copy: ', err);
     }
}

function getUserNameAndPassword(username,password) {
     created_user_name.value = username;
     created_user_password.value = password;
     $('#addNewChild').modal('hide');
     setTimeout(() => {
          $('#accountCreated').modal('show');
     }, 1000);
     step.value = 6;
     student();
}

</script>

<template>
     <div class="container" style="padding-top: 80px">
          <div class="row" style="min-height:100vh;justify-content: center;align-items: center;display:flex;padding-top:34px;padding-bottom:34px;">
               <div class="col-md-6" >

                    <card-box v-if="step==1" title="Select Your Child" :cardBodyCol="'margin-bottom:40px !important'" backtitle="Back" id="parentChildCard">
                         <p class="found_childs mb-0 mb-40">Found {{ studentCount }} profiles registered using this number {{ phoneNo }}</p>
                         <found-childs v-for="parentChild in parentChilds" :isModal = "isModal" :full_name="parentChild.full_name" :school_name="parentChild.student_class.school.name" :age="getAge(parentChild.dob)" :id="parentChild.id" :invitations="parentChild.parent_student_invitations" @getStudents="fetchStudents"></found-childs>
                         <button class="btn btn-primary w-100 mt-40" style="padding: 16px 0px !important;font-size: 18px !important;" @click="childCard()">Continue</button>
                         <p class="m-0 skip-for-now" @click="goToDashboard()" style="text-align: center;">Skip for Now</p>
                    </card-box>

                    <card-box v-if="step==2" @back-btn-clicked="studentChilds" :cardBodyCol="'margin-bottom:40px !important'" title="Add a Child" backtitle="Back" id="addChildCard">
                         <p class="found_childs mb-0 mb-40">Please add them to your account and track their Progress.</p>
                         <button class="btn btn-primary child_already_have_account" @click="searchChildCard()">My child already have account</button>
                         <button class="btn btn-primary new_account_for_child" @click="newAccountChild()">Create a new account for my child</button>
                         <p class="m-0 skip-for-now" @click="goToDashboard()" style="text-align: center; cursor: pointer;">Skip for Now</p>
                    </card-box>

                    <card-box v-if="step==3" @back-btn-clicked="childCard" :cardBodyCol="'margin-bottom:40px !important'" title="Enter username" backtitle="Back" id="addChildCard">
                         <p class="found_childs mb-0 mb-40">Please enter the username that is associated with your child's account.</p>
                         <form @submit.prevent="checkUserName">
                              <text-input class="col-md-12 username" id="username" :noformgroup="true" v-model="form.user_name" label="Username"  placeholder="Username"></text-input>
                              <p class="not_found_message mb-0 d-none">
                                   <common-svgs type="not-found"></common-svgs>
                                   No child found against this username. Please check the spelling and try again.
                              </p>
                              <button class="btn btn-primary continue_btn w-100 mt-40">Continue</button>
                              <p class="m-0 skip-for-now" @click="goToDashboard()" style="text-align: center; cursor: pointer;">Skip for Now</p>
                         </form>
                    </card-box>

                    <card-box v-if="step==4" @back-btn-clicked="searchChildCard" :cardBodyCol="'margin-bottom:40px !important'" title="Finally ! We’ve found your child 🎉" backtitle="Back" id="foundChildCard">
                         <p class="found_childs mb-0 mb-40">Add Child to your child for account access.</p>
                         <parent-students :bgColor="bgColor" :isModal = "isModal" :full_name="searchFullName" :school_name="searchSchoolName" :age="searchChildDOB" :id="searchChildId" :invitations="invitations" @myStudents="fetchStudents"></parent-students>
                         <button class="btn btn-primary continue_btn w-100 mt-40" @click="goToDashboard()">Continue</button>
                    </card-box>

                    <card-box v-if="step==5" @back-btn-clicked="childCard" :cardBodyCol="'margin-bottom:40px !important'" title="Add a Child" backtitle="Back" id="foundChildCard">
                         <p class="found_childs mb-0 mb-40">Let’s create an account for your child..</p>
                         <new-child @UsernamePassword="getUserNameAndPassword"></new-child>
                              
                    </card-box>
                    <toastr :text="'Copied To Clipboard'"></toastr>
                    <card-box v-if="step==6" @back-btn-clicked="newAccountChild" :cardBodyCol="'margin-bottom:40px !important'" title="Account Created ✨" backtitle="Back" id="foundChildCard">
                         <p class="found_childs mb-0 mb-40">Please use this username and password to login to your child account, you can change it later in account setting.</p>
                         <div class="account_created_div">
                              <div class="inner_div">
                                   <label for="Username" class="label">Username</label>
                                   <h4 class="content" id="created_user_name">{{ created_user_name }}</h4>
                                   <hr>
                                   <label for="Username" class="label">Password</label>
                                   <h4 class="content" id="created_user_password">{{ created_user_password }}</h4>
                              </div>
                         </div>
                         <div class="row">
                         <div class="col-md-6">
                              <div class="copy-to-clipboard" @click="copy()">
                                   <common-svgs style="margin: 0 auto;" type="copy-clipboard"></common-svgs>
                                   <span class="copy-clipboard-text">Copy to Clipboard</span>
                              </div>
                         </div>
                    </div>
                         <button class="btn btn-primary continue_btn w-100 mt-40" @click="goToDashboard()">Continue</button>
                         
                    </card-box>
               </div>
          </div>

     </div>
</template>

<style scoped>
.found_childs {
     color:#717171 !important;
     font-size:14px !important;
     /* margin-top: 12px !important; */
}

.mb-40 {
     margin-bottom: 40px !important;
}

.mt-40 {
     margin-top: 40px !important;
}

.skip-for-now {
     /* color: #2463EB;
     display: flex;
     justify-content: center;
     margin-top: 24px !important; */
     border-radius: 12px;
     border-color: snow;
     background-color: #FFFFFF;
     color: #2463EB;
     padding: 16px 0px !important;
     margin-top: 12px !important;
     font-size: 18px !important;
     cursor: pointer;
}

.child_already_have_account {
     /* background: rgba(36, 99, 235, 0.1); */
     background: #FAFAFA;
     color: #000000;
     border-radius: 12px;
     border-color: snow;
     font-size: 16px;
     padding: 16px 0px !important;
}
.new_account_for_child {
     margin-top: 16px !important;
     color: #000000;
     font-size: 16px;
     background-color: #FAFAFA;
     border-color: snow;
     border-radius: 12px;
     padding: 16px 0px !important;
}
.child_already_have_account:hover, .new_account_for_child:hover, .skip-for-now:hover {
     background: rgba(36, 99, 235, 0.1);
}
.not_found_message {
     font-size: 11px;
     color: #E02B1D;
     margin-left: 12px !important;
     margin-right: 12px !important;
     margin-top: 10px !important;
}
.enter_username {
     color: #212121;
     font-size: 36px;
}
.enter_username_text {
     margin-top: 12px;
     color: #717171;
     font-size: 16px;
}
.label {
     font-size: 14px !important;
     color: #89939E !important;
}
.content {
     font-size: 20px;
     color: #212121;
     margin-top: 8px !important;
}
.account_created_div {
     background: linear-gradient(90deg, rgba(36, 99, 235, 0.08) 0%, rgba(255, 186, 0, 0.08) 100%);
     border-radius: 12px;
}
.inner_div {
     padding: 25px 10px !important;
}
.continue_btn {
     padding: 16px 0px !important;
}
.copy-to-clipboard {
     padding: 8px 16px;
     margin-top: 24px !important;
     border-radius: 8px !important;
     background: rgba(36, 99, 235, 0.10) !important;
     cursor: pointer;
}
.copy-clipboard-text {
     font-size: 14px !important;
     color: #2463EB !important;
     font-weight: 400 !important;
     margin-left: 8px !important;
}

</style>

