<script setup>

import { defineProps, ref, onUpdated, defineEmits } from 'vue';
import factory from '@/common/factory.js';
import { useAuthStore } from "@/store/auth.js";

     const props = defineProps(["full_name", "school_name", "id", "age", "isModal", "invitations"]);
     const studentInvitations = ref(props.invitations);
     const auth = useAuthStore();
     const emit = defineEmits('getStudents');
     var student_id = ref(props.id);
     function getToken() {
          const token = {
               headers: {
                    // 'Content-Type': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + auth.token,
               },
          };
          return token;
     }
     onUpdated(()=>{
          student_id.value = props.id;
          studentInvitations.value = props.invitations;
     });

     async function inviteStudent() {
          var form = {
               'student_id': student_id.value,
          };
          const invitation = new factory(getToken(), 'invite-student', form);
          await invitation.postForm().then((response) => {
               if (response.data.status) {
                    swal.fire('Done!', 'Invitation Send to the Student Successfully.', 'success');
                    $('#foundChild').modal('hide');
                    emit('getStudents');
               } 
               else {
                    swal.fire('Error!', 'Something went wrong.', 'error');
               }
          });
     }; 
</script>

<template>
     <div class="card-body">
          <div class="col-md-2 p-0">
               <img src="../../public/img/student_icon.png" alt="" class="img_rounded">
          </div>
          <div class="col-md-5 mt-2 content" >
               <p class="name mb-0" >{{ full_name }}</p>
               <p class="years_old mb-0" >{{ age }} years old</p>
               <p class="school_name mb-0" >{{ school_name }}</p>
          </div>
          <div class="col-md-5 buttons-group-else" v-if="studentInvitations.length<=0">
               <button class="btn btn-primary invite_child_btn"  @click="inviteStudent()">Add Child</button>
          </div>
          <div v-else class="col-md-5 buttons-group-else">
               <button class="btn btn-primary invited_button" disabled>Invite Send</button>
          </div>
     </div>
</template>

<style scoped>

#parent-student-card {
     background: #FAFAFA !important;
}

.card {
     box-shadow: 0px 15px 30px rgba(186, 186, 186, 0.1);
     border-radius: 12px;
}

.custom-back-btn:hover {
     background: #D0DEFB;
     color: var(--primary);
}

.custom-back-btn {
     border-radius: 8px;
     padding: 6px 12px 6px 10px;
     gap: 8px;
     color: #9CA3AF;
}

.card-body {
     padding: 0px;
     background: #FAFAFA !important;
     display: flex;
     padding: 16px;
     align-items: center;
     margin-bottom: 12px;
     border-radius: 12px !important;
}

.card-body .bgimg {
     position: absolute;
     right: 0px;
     top: 0px;
}

.content {
     padding-left: 12px !important;
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     /* justify-content: flex-end; */
     gap: 4px;
}

.name {
     font-size: 18px !important;
     color: #000000 !important;
     line-height: 19px !important;
     font-weight: 500 !important;
}

.years_old {
     color: #717171 !important;
     font-size: 12px !important;
}

.school_name {
     font-size: 12px !important;
     color: #212121 !important;
}

.delete_btn {
     color: #89939E !important;
     background-color: inherit;
     border-color: snow;
}

.invite_child_btn {
     background: rgba(36, 99, 235, 0.1);
     font-size: 14px !important;
     color: #2463EB !important;
     border-radius: 4px !important;
     border: none;
     padding: 8px 24px !important;
}

/* .buttons_group {
     display: flex;
    align-items: center;
    justify-content: center;
} */
.buttons-group-else {
     display: flex; 
     justify-content: end; 
     align-items: end;
}
.invited_button {
     background: #EEF5FC;
     color: #ABBED1;
     font-size: 14px !important;
     border-radius: 4px !important;
     border: none;
     padding: 8px 24px !important;
}</style>