<template>
  <div class="card email-verify-card">
    <div class="card-body p-0">
      <div class="row m-0">
        <div class="col-md-12 d-flex comp-main-container">
          <common-svgs :type="'warning-icon'"></common-svgs>
          <div class="text-portion">
            <span v-if="type == 'noemail'" class="btext-3" style="font-weight: 400;">Please add your email to stay updated
              with alternates!</span>
            <span v-else class="btext-3" style="font-weight: 400;">An email verification link has been sent to you, please
              verify your email.</span>
          </div>
          <div class="btn-portion">
            <router-link v-if="type == 'noemail'" class="btn btn-clean-primary-sm" :to="{ name: 'account-settings',query:{addemail:1} }">
              Add Email Address
            </router-link>

            <button v-else class="btn btn-clean-primary" @click="resendLink()">Resend link</button>
            <common-svgs :type="'cross-icon'" class="cross-icon" @click="removeComponent()"></common-svgs>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, ref, onUpdated, defineProps } from "vue";
import { appConsts } from "@/common/constants.js";

import CommonSvgs from "@/components/CommonSvgs.vue";
import factory from '@/common/factory.js';
import { useAuthStore } from "@/store/auth.js";
const auth = useAuthStore();

var props = defineProps(['type']);
var type = ref(props.type);
onUpdated(() => {
  type.value = props.type;
});
function removeComponent() {
  $('.email-verify-card').remove();
}
function resendLink() {
  const UserInfo = new factory(getToken(), 'resend-verify-email');
  UserInfo.getHttp().then((response) => {
    if (response.data.status) {
      displayToaster('Verification link sent');
    } else {
      swal.fire('Error!', response.data.message, 'error');
    }
  });

}
function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + auth.token,
    },
  };
  return token;
}


</script>
<style scoped>
/* Form Card CSS Start */
.comp-main-container {
  position: relative;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 16px;
  column-gap: 16px;
  padding-left: 16px;
}
.cross-icon{
  cursor: pointer;
}

.btn-portion {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 12px;
  align-items: center;
}

.withoutimg {
  padding: 24px 32px;
}

.withimg {
  padding: 17px 24px;
}

.text-portion {
  padding: 18px 0px;
  flex: 1 0 0;
}

.card {
  background: #FFFFFF;

  box-shadow: 0px 15px 30px rgba(186, 186, 186, 0.1);
  border-radius: 12px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 4px solid #FCA004;
  padding-right: 16px;
}

.custom-back-btn:hover {
  background: #D0DEFB;
  color: var(--primary);
}

.custom-back-btn {
  border-radius: 8px;
  padding: 6px 12px 6px 10px;
  gap: 8px;
  color: #9CA3AF;
}

.card-body .bgimg {
  position: absolute;
  right: 0px;
  top: 0px;
  min-height: 100%;
}

.btn-clean-primary-sm {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  color: var(--primary);
}

/* Form Card CSS End */
/* ===**************** Media Queries ****************=== */
@media (max-width: 915px) {
  .card-body .bgimg {
    opacity: 0.1;
  }
}

/* bgimg */
</style>
