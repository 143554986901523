<template>
  <header-view></header-view>
  <toastr :text="'Copied To Clipboard'"></toastr>
  <div v-if="auth.token != '' && auth.token != null && !auth.isWebsite" class="container" style="padding-top: 136px;padding-bottom: 100px;">
    <div class="row mb-5">
      <div v-if="auth.curentEndPoint != 'verify' && !auth.isWebsite" class="col-lg-3 col-md-1 col-sm-2 col-2">
        <sidebar-view></sidebar-view>
      </div>
      <div :class="(auth.curentEndPoint == 'verify') ? 'col-md-6 offset-md-3' : 'col-lg-9 col-md-11 col-sm-10 col-10 right-router-view'">
        <router-view />
      </div>
    </div>
  </div>
  <router-view v-else />
  <footer-view v-if="!auth.noFooter"></footer-view>
</template>
 
<script setup>

import HeaderView from './views/layout/Header.vue';
import FooterView from './views/layout/Footer.vue';
import SidebarView from './views/layout/Sidebar.vue';
import { useAuthStore } from '@/store/auth.js';
// import Toastr from '@/Com';
import Toastr from "@/components/Toastr.vue";
const auth = useAuthStore();

// export default {
//   setup() {
//     const auth = useAuthStore()
//     return { auth }
//   },
//   components: {
//     HeaderView, FooterView
//   },
// }
</script>
