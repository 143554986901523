<template>
  <search-banner></search-banner>
  <div v-if="!this.$route.query.keyword">
    <div class="container" style="overflow-x: hidden; padding-top: 30px">
      <div
        v-if="role === 'student' || role === 'school' || role === 'parent'"
        class="row"
      >
        <side-bar></side-bar>
        <content></content>
      </div>
    </div>
  </div>

  <div style="min-height: 500px" v-if="search">
    <div class="container p-5">
      <div class="row">
        <h2 class="search-heading">Search Results</h2>
        <p class="search-result-count">
          {{ filteredData ? filteredData.length : "0" }} results for “{{
            this.$route.query.keyword
          }}”
        </p>
      </div>

      <div class="row" v-if="filteredData">
        <div  v-for="(data, index) in filteredData" :key="index">
          <router-link 
          :to="data.path+'?id='+data.id"
            class=" mt-3 mb-3"
            
          > 

          <div class="mt-3 mb-3" :class="{ 'border-bottom': index !== filteredData.length - 1 }">
            <p
              class="question d-flex justify-content-between align-items-center collapsable-sub-heading"
              
             
              role="button"
            
            >
              {{ data.question }}
         
            </p>
            <p class="answer">{{ data.answer.substr(0, 150) }}{{ data.answer.length > 150 ? '...' : '' }}</p>
            <p  class="path-title">{{ data["path-title"] }}</p>
          </div>

            
           
          </router-link>
        </div>
      </div>

      <div
        v-if="!filteredData || filteredData.length == 0"
        class="row"
        style="align-items: center"
      >
        <CommonSvgs type="search-no-result"></CommonSvgs>
        <p class="no-result-text text-center">
          No results found. Please try other keywords
        </p>
      </div>
    </div>
  </div>

  <help-contact-banner></help-contact-banner>
</template>

<style scoped>
.no-result-text {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.border-bottom {
  border-bottom: 0.6px solid #abbed1;
}

.question {
  color: var(--Primary-Blue, #2463eb);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.answer {
  color: rgba(25, 25, 25, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
}

.path-title {
  cursor: pointer !important;
  color: var(--neutral-l-grey, #89939e);
  font-family: Apercu;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search-result-count {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #89939E;

}

.search-heading {
  font-family: Apercu;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}

span:hover {
  text-decoration: underline;
  color: #2463eb;
}
</style>
<script>
import roleCard from "@/components/help/roleCard.vue";
import search from "@/components/help/search.vue";
import sidebar from "@/components/help/sidebar.vue";
import content from "@/components/help/content.vue";
import helpContactBanner from "@/components/help/helpContactBanner.vue";
import CommonSvgs from "@/components/CommonSvgs.vue";
import jsonData from "@/static/help-content.json";

export default {
  data() {
    return {
      jsonData: jsonData,
      role: this.$route.params.role || "",
      search: this.$route.query.keyword || "",
      filteredData: [],
    };
  },
  created() {
    
    this.search_(this.jsonData, this.search);
  },
  updated() {
    this.search = this.$route.query.keyword;
    this.role = this.$route.params.role;
    this.search_(this.jsonData, this.search);
  },

  components: {
    CommonSvgs,
    "role-card": roleCard,
    "help-contact-banner": helpContactBanner,
    "search-banner": search,
    "side-bar": sidebar,
    content: content,
  },

  methods: {
    search_(jsonData, searchQuestion) {
      const categories = jsonData.student.concat(
        jsonData.parent,
        jsonData.school
      );
      const filteredData = [];

      for (const category of categories) {
        for (const dataItem of category.data) {
       

          if (
            dataItem?.question?.toLowerCase().includes(this.search?.toLowerCase()) ||
            dataItem?.answer?.toLowerCase().includes(this.search?.toLowerCase())
          ) {
            dataItem["path"] = category["path"];
            dataItem["path-title"] = category["path-title"];
            filteredData.push(dataItem);
          }
        }
      }

      this.filteredData = filteredData;
      // console.log(this.filteredData);
    },
  },
};
</script>
