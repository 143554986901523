<template>
  
    <div class="sidebar col-md-3 col-12">
        <router-link to="general"><span  :class="{'active-side-menu':$route.params.option === 'general'}">General</span></router-link>
        <router-link to="child_account"><span :class="{'active-side-menu':$route.params.option === 'child_account'}">Child Account</span></router-link>
        <router-link to="manage_child"><span :class="{'active-side-menu':$route.params.option === 'manage_child'}">Manage Child</span></router-link>
        <router-link to="account_settings"><span :class="{'active-side-menu':$route.params.option === 'account_settings'}">Account Settings</span></router-link>
    </div>
 
</template>

<script >

import CommonSvgs from "@/components/CommonSvgs.vue";

export default {
  props: {
    customClass: String,
    title: String,
    svgType: String,
    stroke: String,
  },
  components: {
    CommonSvgs,
  },
  methods:{
  
  }
};
</script>

<style scoped>

.active-side-menu{
  text-decoration: underline;
  color: #2463EB;
}

.sidebar a:hover .active-side-menu{
  text-decoration: underline;
  color: #2463EB;

}

.sidebar {
  margin: 0;
  padding: 0;
  /* width: 400px; */
  position: relative;
  height: 100%;
  text-align:left;
  overflow: auto;
}

.sidebar a {
  display: block;
  color:#212121;
  padding: 16px;
  text-decoration: none;
  font-family: Apercu;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;

}



@media screen and (max-width: 900px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }


  .sidebar a {float: left;}
 
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
</style>
