<template>
  <div :class="$attrs.class ?? 'col-md-6'">
    <div :class="($attrs.noformgroup) ? '' : 'mb-3'">
      <label v-if="label && searchValue" class="form-label" :for="id">{{ label }}</label>
      <div class="search-field" style="position: relative;">  
        <input :maxlength="maxLength" :id="id" ref="input" v-bind="{ ...$attrs, class: null }" class="form-control"
        :class="((label && modelValue) ? ' label-input ' : ' ')" :type="type" :value="modelValue" @input="inputTriggerd($event)" />
        <div class="password-field-icon" @click="changeType"></div>
      </div>
      <small v-if="$attrs.helptext" class=" atext-1">{{ $attrs.helptext }}</small>
      <div v-if="error" class="form-error"><span class="error-icon"></span> {{ error }}</div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${uuid()}`
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    error: String,
    label: String,
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
    inputTriggerd(event) {
      if (this.type == 'phone') {
        const inputValue = event.target.value;
        const numericRegex = /^[0-9]+$/;

        if (!numericRegex.test(inputValue)) {
          // Remove non-numeric characters from the input value
          this.numericValue = inputValue.replace(/[^0-9]/g, '');
        } else {
          this.numericValue = inputValue;
        }
        event.target.value = this.numericValue;
        this.$emit('update:modelValue', this.numericValue)
      } else {
        this.$emit('update:modelValue', event.target.value)
      }
    }
  },
}
</script>
<style scoped>
.search-field .form-control {
  background: rgba(137, 147, 158, 0.05);
  border: 0.5px solid #E5E7EB;
  border-radius: 8px;
  padding: 6px 0px 6px 40px;
  padding-right: 6px !important;
  /* E5E7EB */
  font-family: var(--def-fontfamily);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #4D4D4D;
}
.search-field .password-field-icon {
  content: "";
  background-image: url('/public/files/assets/img/search-icon.svg');
  background-repeat: no-repeat;
  /* background-size: 16px 16px; adjust size as needed */
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
}

.atext-1 {
  padding-left: 20px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #89939E;
}

.password-field {
  position: relative;
}


.label-input{
  padding: 22px 12px 14px 16px !important;
}
label{
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding-left: 20px;
  padding-top: 12px;
  color: #4D4D4D;
  z-index: 1;
}
.form-error {
  padding-top: 9px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #E02B1D;
}
.error-icon{
  content: "";
  background-image: url('/public/files/assets/img/error.webp');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  padding-right: 20px;
  
}
</style>
