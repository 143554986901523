<template>
  <div class="container" style="padding-top: 170px;">
    <div class="row mb-5">
      <div class="col-md-7 offset-md-3">

        <info-box v-if="verified" @btn-clicked="goBack"
          :btntext="(auth.token != '' && auth.token != null) ? 'Go to Dashboard' : 'Back to Home'" svgtype="action-complete"
          svgwidth="95" title="Email Address has been verified"></info-box>
        <info-box v-if="issue" @btn-clicked="goBack"
          :btntext="(auth.token != '' && auth.token != null) ? 'Go to Dashboard' : 'Back to Home'" svgtype="dialog-warning-icon"
          svgwidth="95" title="Invalid or Expired Link" shortdesc="Please retry again with new email link."></info-box>
      </div>
    </div>
  </div>
</template>
<script setup>
import InfoBox from "@/components/InfoBox.vue";
import factory from '@/common/factory.js';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";
import { onMounted, ref } from "vue";

const auth = useAuthStore();
const route = useRoute();
const router = useRouter();

var verified = ref(false);
var issue = ref(false);

onMounted(() => {
  verifyEmail();
});

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
}
function goBack() {
  if (auth.token != '' && auth.token != null) {
    router.push({ name: 'dashboard' });
  } else {
    router.push({ name: 'home' });

  }
}
async function verifyEmail(){
var resendlinkparms = {
  activation_code: route.query.code,
  activation_token: route.query.token
}
const VerifyEmail = new factory(getToken(), 'verify-email', resendlinkparms);
await VerifyEmail.postForm().then((response) => {
  swal.close();
  if (response.data.status) {
    verified.value = true;
    auth.userinfo.email_verification = 1;
  } else {
    issue.value = true; 
    // swal.fire('Error!', response.data.message, 'error');
  }
});
}

</script> 