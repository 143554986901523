<template>
  <div class="container page-maincontainer">
    <div class="row">
      <div class="col-md-12 section-1">
        <h1 class="coursepagetitle">Learn without limits</h1>
        <p class="btext-1 text-center" style="line-height: 28px;">Our courses are customised to the specific requirements
          of the school, covering
          Class 1 to 8,
          and are <br />in line
          with the curriculum, syllabus, and content being taught at your school.</p>
      </div>
      <div class="col-md-12 section-2 mb-4">
        <h2>All Courses</h2>

        <div class="row">
          <!-- {{ course_names }} -->
          <card-block v-for="course in course_names" :img="functions.displayImg(course.file_name)"
            :onerror="`this.src = '${assetUrl}files/assets/img/school-avatar.svg'`" :title="functions.truncate(course.name, 60)"
            :book_name="functions.truncate(course.book_name, 30)"
            class="col-md-3">
          </card-block>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import CardBlock from "@/components/website/CardBlock.vue";
import { useAuthStore } from '@/store/auth.js';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import factory from "@/common/factory.js";
import { functions } from "@/common/functions";
import { appConsts } from "@/common/constants.js";
var assetUrl = ref(appConsts.assetBaseUrl);
var auth = useAuthStore();

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
}

let currentpage = ref(1);
var course_names = ref([]);
var inProgress = ref(false);
var totalpages = ref(0);

function courses() {

  if (inProgress.value == true) {
    let paginate = {
      page: currentpage.value
    }
    const subject_courses = new factory(getToken(), 'courses', paginate);
    subject_courses.getHttp().then((response) => {
      if (response.data.status) {
        inProgress.value = false;
        totalpages.value = response.data.data.last_page;
        course_names.value = course_names.value.concat(response.data.data.data);
      }
    });
  }
};

onMounted(() => {
  inProgress.value = true;
  courses();
  window.addEventListener('scroll', handleScroll);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
const handleScroll = () => {
  const scrollY = window.scrollY;
  const visibleHeight = window.innerHeight;
  const totalHeight = document.documentElement.scrollHeight;
  const scrollHeight = (scrollY + visibleHeight)-200;
  // console.log("Scroll Y", scrollY, "visible Height", visibleHeight, "scroll y + visible height", (scrollY+visibleHeight)-200, "total Height", totalHeight);
  // if ((scrollY + visibleHeight) >= totalHeight) {
  if ((scrollY + visibleHeight) >= totalHeight-200) {
    if (currentpage.value < totalpages.value) {
      currentpage.value++;
      inProgress.value = true;
      courses();
    }
  }
};

courses();
</script>
<style scoped>
.coursepagetitle {
  font-size: 64px;
  line-height: 48px;
}

.section-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;
  margin-bottom: 74px;
}

.section-2 {
  row-gap: 32px;
  display: grid;
}

@media (max-width: 915px) {
  .coursepagetitle {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
  }

  .section-1 br {
    display: none;
  }

  .section-1 {
    margin-bottom: 40px;
  }

  .section-1 p {
    line-height: 24px !important;
  }

}</style>
