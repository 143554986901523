import {
  reactive,
  ref
} from "vue";
import {
  appConsts
} from "@/common/constants.js";
var initialColorIndex = ref(0);
const bgColors = reactive(
  [
    'bg-primary',
    'bg-warning',
    'bg-success',
    'bg-danger'
  ]
);

export const functions = reactive({
  callOnReady() {

  },
  errorResponse(errorsObj, msgdisplay = '') {
    if (errorsObj.error != null && (Object.keys(errorsObj.error)).length > 0) {
      var errorArr = {};
      $.each(errorsObj.error, function (k, v) {
        errorArr[k] = v;
      });
      swal.close();
      return errorArr;
    } else {
      if (msgdisplay != '') {
        swal.fire('Error!', errorsObj.message, 'error');
      }
      return {};
    }
  },
  shortNameGenerator(className) {
    let splitClassName = className.split(/ (.*)/);
    let getLength = Object.keys(splitClassName).length;
    if (getLength > 2) {
      let shortName = splitClassName[0].charAt(0) + '' + splitClassName[1].charAt(0);
      // console.log(shortName);
      return shortName.toUpperCase();
    }
    return (splitClassName[0].substring(0, 2)).toUpperCase();
  },
  returnColorIndex() {
    if (initialColorIndex.value >= bgColors.length) {
      initialColorIndex.value = 0;
    }
    var color = bgColors[initialColorIndex.value];
    initialColorIndex.value++;
    return color;
  },
  displayImg(file) {
    if (file) {
      return appConsts.fileBaseUrl + file;
    } else {
      return appConsts.assetBaseUrl + 'files/assets/img/placeholder.svg';
    }
  },
  formatDate($momentObj,$date){
    const targetDate = $momentObj($date);
    const currentDate = $momentObj();
    const isToday = targetDate.isSame(currentDate, 'day');
    const yesterday = currentDate.clone().subtract(1, 'days');
    const isYesterday = targetDate.isSame(yesterday, 'day');
    if(isToday){
      return $momentObj($date).format('[Today] [at] HH:mm');
    }else if(isYesterday){
      return $momentObj($date).format('[Yesterday] [at] HH:mm');
    }else{
        return $momentObj($date).format('D MMM YYYY [at] HH:mm');
    }
  },
  initLoader(){
    Swal.fire({
      title: "Loading....",
      text: "Please Wait, This may take a while...",
      iconHtml: `<div class="loader-block">
    <svg id="loader2" viewBox="0 0 100 100">
    <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
    </svg>
    </div>`,
      showConfirmButton: false,
    });
  },
  truncate(string, len) {
    if(string != null) {
      if (string.length>len) {
        string = string.substring(0, len-1) + "...";
        return string;
      }
      else {
        return string;
      }
    }
  },
  dateFormat(date) {
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();

    const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
      "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return day + ' '+monthNames[dateObj.getMonth()]+' '+year;
  }
});