<script setup>
import { defineProps, ref, onUpdated, defineEmits } from "vue";
import CommonSvgs from "@/components/CommonSvgs.vue";

var prop = defineProps(['title', 'desc', 'iscompleted']);
var emits = defineEmits(['slideClicked']);
var title = ref(prop.title);
var desc = ref(prop.desc);
var iscompleted = ref(prop.iscompleted);
onUpdated(() => {
  title.value = prop.title;
  desc.value = prop.desc;
  iscompleted.value = prop.iscompleted;
});

var toggleShow = ref(false);
function slideToggle() {
  toggleShow.value = !toggleShow.value;
  emits('slideClicked');
}

</script>
<template>
  <div class="accordian-main">
    <div class="accordian"   @click="slideToggle">
      <div class="accordian-start">
        <div class="icon">
          <common-svgs v-if="!toggleShow" :type="'accordian-down'"></common-svgs>
          <common-svgs v-if="toggleShow" :type="'accordian-up'"></common-svgs>
        </div>
        <span class="btext-1">{{ title }}</span>
      </div>
      <div>
        <span class="right-text">{{ desc }}</span>
        <button v-if="iscompleted" type="button" class="btn btn-clean-success"
          style="padding: 8px 24px;border-radius: 4px;margin-left: 32px;">Completed</button>
      </div>
    </div>
    <div class="accordian-contents" v-if="toggleShow">
      <slot></slot>
    </div>
  </div>
</template>
<style scoped>
.accordian-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 95%;
}

.accordian-main {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 16px;
}

.completedtopics {
  background: #dffaf1 !important;
}

.accordian-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
}

.accordian {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 42px;
  background: #FFFFFF;
  border-radius: 12px;
  width: 100%;
}

.right-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #717171;
}
</style> 