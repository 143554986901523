<template>
  <!-- <div class="container-parent"> -->
  <img class="signupschoolimg" :src="auth.baseUrl + 'files/assets/img/signup/sideimage.webp'" />
  <div class="container" style="padding-top: 170px;">
    <div class="row" style="    margin-bottom: 185px;">
      <div class="col-md-6 ">
        <card-box class=" SubmitOnEnter" title="Submit a request"
          shortdesc="After submitting request our staff will review your application and will contact you.">
          <text-input @keyup="validate();" class="col-md-12" v-model="form.user_name" label="Your Name"
            placeholder="Your Name"></text-input>
            <text-input @keyup="validate();" class="col-md-12" v-model="form.school_name" label="School Name"
            placeholder="School Name"></text-input>
            <select-box class="col-md-12" v-model="form.education_system" label="Education_System" placeholder="Education System"
              @do-change="validate();">
              <option value="Matric">Matric</option>
              <option value="Cambridge">Cambridge</option>
            </select-box>
          <text-input @keyup="validate();" class="col-md-12" type="phone" v-model="form.phone" label="Phone Number"
            maxLength="11" placeholder="Phone Number"></text-input>
          <select-box class="col-md-12" v-model="form.province_id" label="Province" placeholder="Province"
            @do-change="cityProvinceAutomation('province'); validate();">
            <option v-for="province in fil_provinces" :value="province.id">{{ province.name }}</option>
          </select-box>
          <select-box class="col-md-12" v-model="form.city_id" label="City" placeholder="City"
            @do-change="cityProvinceAutomation('city'); validate();">
            <option v-for="city in fil_cities" :value="city.id">{{ city.name }}</option>
          </select-box>

          <div class="col-md-12 mb-3">
            <button class="btn btn-primary btn-block submitItBtn" :disabled="inValid" @click="signupSchool();">Submit
              request</button>
          </div>
        </card-box>

      </div>
      <div class="col-md-6 ">
        <div class="signup-right-text">
          <h3 class="text-white">Register your School with us</h3>
          <div class="text-content-box">
            <div class="text-content">
              <div class="text-content-icon">
                <common-svgs type="bag-icon" style="margin: 0 auto;" ></common-svgs>
              </div>
              <div class="text-content-text">
                Experience the flexibility of our customizable courses designed to meet your unique learning needs and goals.
              </div>
            </div>
            <div class="text-content">
              <div class="text-content-icon">
                <common-svgs type="clip-board" style="margin: 0 auto;" ></common-svgs>
              </div>
              <div class="text-content-text">
                Track progress effortlessly with our intuitive platform that allows you to view student progress in real-time.
              </div>
            </div>
            <div class="text-content">
              <div class="text-content-icon">
                <common-svgs type="open-book" style="margin: 0 auto;" ></common-svgs>
              </div>
              <div class="text-content-text">
                Our commitment to your learning journey extends beyond the lessons, we have 24/7 Customer Support to assist you.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<style scoped>
.text-content-text {
  font-weight: 500;
font-size: 14px;
line-height: 26px;
}
.text-content {
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;

}
.text-content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}

.signupschoolimg {
  position: absolute;
  right: 0;
  background-position: center left;
  padding-top: 75px;
  max-width: 50%;
}
.text-content{
  column-gap: 16px;
}

.signup-right-text {
  color: white;
  z-index: 11;
  position: relative;
  padding-left: 170px;
  padding-top: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
</style>
<script setup>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import CardBox from "@/components/CardBox.vue";
import { reactive, ref } from 'vue';
// import { useAuthStore } from '@/store/auth.js'
import factory from '@/common/factory.js'
import { useAuthStore } from '@/store/auth.js';
import CommonSvgs from "@/components/CommonSvgs.vue";
// import { useRouter, useRoute  } from 'vue-router';

// const router = useRouter();
// const route = useRoute();
// const authStore = useAuthStore();
var auth = useAuthStore();
var provinces = ref([]);
var fil_provinces = ref([]);
var cities = ref([]);
var fil_cities = ref([]);

var inValid = ref(true);

var form = ref({
  user_name: '',
  school_name: '',
  phone: '',
  province_id: '',
  city_id: '',
  education_system: ''
});

function validate() {
  inValid.value = false;
  if (form.value.user_name == '') {
    inValid.value = true;
  }
  if (form.value.school_name == '') {
    inValid.value = true;
  }
  if (form.value.education_system == '') {
    inValid.value = true;
  }
  if (form.value.phone == '') {
    inValid.value = true;
  }
  if (form.value.province_id == '') {
    inValid.value = true;
  }
  if (form.value.city_id == '') {
    inValid.value = true;
  }
}

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
}

var ProvincesCities = new factory(getToken(), 'provinces-cities');
ProvincesCities.getHttp().then((response) => {
  if (response.data.status) {
    fil_provinces.value = provinces.value = response.data.data.provinces;
    fil_cities.value = cities.value = response.data.data.cities;
  }
});

async function signupSchool() {
  const SignUp = new factory(getToken(), 'school-submission-request', form.value);
  await SignUp.postForm().then((response) => {
    if (response.data.status) {
      swal.fire('Done!', 'Our staff will review your application and will contact you.', 'success');
      form.value = {
        user_name: '',
        school_name: '',
        education_system: '',
        phone: '',
        province_id: '',
        city_id: '',
      };
      $('.select2').val('').change();
    } else {
      console.log(response.data.message);
    }
  });
};
function cityProvinceAutomation(condition) {
  if (condition == 'city') {
    var selectedcity = cities.value.find((item) => (item.id == form.value.city_id));
    var selectedprovince = provinces.value.find((item) => (item.id == selectedcity.province_id));
    form.value.province_id = selectedprovince.id;
  } else {
    fil_cities.value = cities.value.filter((item) => (item.province_id == form.value.province_id));

  }
  setTimeout(function () {

    $('.select2').select2();
  }, 500);
}

function stepOneBack() {
  router.push('/');
} 
</script>