<script setup>
import CommonSvgs from '@/components/CommonSvgs.vue';
import { ref } from 'vue';

     const props = defineProps(['text']);
     const toastr_text = ref(props.text);
</script>

<template>
     <div class="d-none custom-toaster">
          <div class="d-flex">
               <span class="copied-to-clipboard-text">{{ toastr_text }}</span>
               <div onclick="$('.custom-toaster').addClass('d-none')">
                    <common-svgs type="cross-icon"></common-svgs>
               </div>
          </div>
     </div>
</template>

<style scoped>
     .custom-toaster {
          display: flex;
          padding: 8px 12px;
          background: #fff;
          border-radius: 8px;
          border: 1px solid #E5E7EB;
          position: absolute;
          right: 130px !important;
     }

     .copied-to-clipboard-text {
          font-size: 12px;
          margin-right: 48px !important;
          color: #6B7280;
          font-weight: 600;
          margin-top: 7px;
     }
</style>