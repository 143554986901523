<script setup>
import { ref, defineProps, onUpdated, defineEmits } from "vue";
import ParentStudents from "@/views/parent/ParentStudents.vue";
import { useAuthStore } from "@/store/auth.js";
import NewChild from "./NewChild.vue";
import CommonSvgs from '@/components/CommonSvgs.vue';
import TextInput from "@/components/TextInput.vue";
import factory from "@/common/factory.js";
import Toastr from "@/components/Toastr.vue";

     let credientials = ref();
     const props = defineProps('student','acceptStatusStudent');
     const emit = defineEmits('getChildren')
     const student = ref('');
     const created_user_name = ref('');
     const created_user_password = ref('');
     const isModal = ref(true);
     const searchFullName = ref('');
     const searchSchoolName = ref('');
     const searchChildDOB = ref('');
     const searchChildId=  ref('');
     const invitations=  ref('');
     const auth = useAuthStore();
     const bgColor = ref(true);

     var form = ref({
          user_name: '',
          phone: auth.userinfo.phone1
     });

     onUpdated(() => {
          student.value = props.student;
     });

     function getToken() {
          const token = {
                    headers: {
                    // 'Content-Type': 'application/json',
                         'Content-Type': 'application/x-www-form-urlencoded',
                         'Authorization': 'Bearer ' + auth.token,
                    },
               };
          return token;
     }
     function formInputReset() {
          form.value.user_name = '';
     }
     function openAddNewChildModal() {
          formInputReset();
          $('.not_found_message').removeClass('d-block').addClass('d-none');
          $('#alreadyAccount').modal('hide');
          $('#addAChild').modal('show');
     }

     function getUserNameAndPassword(username,password) {
          created_user_name.value = username;
          created_user_password.value = password;
          $('#addNewChild').modal('hide');
          setTimeout(() => {
               $('#accountCreated').modal('show');
          }, 1000);
          // student();
     }

     function getAge(dateString) {
          var today = new Date();
          var birthDate = new Date(dateString);
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
               if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
               }
          return age;
     }

     async function checkUserName() {
          const checkUserNameAvailability = new factory(getToken(), 'get-parent-child-by-username', form.value);
          await checkUserNameAvailability.getHttp().then((response) => {
               if (response.data.status) {
                    console.log("here");
                    console.log(response.data.data.student);
                    $('.not_found_message').removeClass('d-block').addClass('d-none');
                    searchFullName.value = response.data.data.full_name
                    searchSchoolName.value = response.data.data.student.student_class.school.name;
                    searchChildDOB.value = getAge(response.data.data.student.dob);
                    searchChildId.value = response.data.data.student.id;
                    // invitations.value = response.data.data.parent_students;
                    invitations.value = response.data.data.student.parent_invitations;
                    $('#alreadyAccount').modal('hide');
                    $('#foundChild').modal('show');
                    form.value.user_name = '';
                    // student();
               } 
               else {
                    $('.not_found_message').removeClass('d-none').addClass('d-block');
               }
          });
     };

     function searchChildInfo(full_name, school_name, dob, search_child_id, invitations) {
          console.log(full_name, school_name, dob, search_child_id, "inv ln",invitations.length);
          searchFullName.value = full_name;
          searchSchoolName.value = school_name;
          searchChildDOB.value = dob;
          searchChildId.value = search_child_id;
          child_invitations.value = 0;
          if(invitations.length>0) {
               child_invitations.value = invitations.length;
          }
          // console.log(child_invitations.value);
     }
     const copy = async() => {
     let user_name = document.getElementById('created_user_name').innerHTML;
     let user_password = document.getElementById('created_user_password').innerHTML;
          try {
               // await navigator.clipboard.writeText([user_name, user_password]);
               // console.log('Copied to clipboard');
               let message = "The account of your child has been \nsuccessfully activated. Please login\nusing the below username and\npassword. Here are the credentials\nof your child(s) account.\n\n" +
               "Username: " + user_name + "\n" +
               "Password: " + user_password + "\n\n" +
               "Contact our customer support in\ncase you need any assistance.\n\n" +
               "Alternates";
               credientials.value = await navigator.clipboard.writeText(message);
               $('.credientials').val(credientials.value);
               $(".custom-toaster").removeClass('d-none');
               setTimeout(() => {
                    $(".custom-toaster").addClass('d-none');
               }, 5000);
          } catch (err) {
               console.error('Failed to copy: ', err);
          }
     }

     function closeAccountCreatedModal ()
     {
          $('#accountCreated').modal('hide');
          emit('getChildren');
     }
</script>

<template>
     <toastr :text="'Copied To Clipboard'"></toastr>
   
<!--Add Child Modal -->
<!-- <div class="modal fade" id="addAChild" aria-labelledby="addAChildLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-centered modal-width-576">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="modal-header p-0">
               <h2 class="modal-title" id="addAChildLabel">Add a Child</h2>
               <button type="button" class="btn-close p-0 m-0 modal-button-close" @click="formInputReset()" data-bs-dismiss="modal"
                    aria-label="Close"></button>
               </div>
               <p class="student_modal_text mb-0">Please add them to your account and track their Progress.. </p>
               <button class="btn btn-primary child_already_have_account" data-bs-toggle="modal" data-bs-target="#alreadyAccount">My child already have account</button>
               <button class="btn btn-primary new_account_for_child" data-bs-toggle="modal" data-bs-target="#addNewChild">Create a new account for my child</button>
               <button class="btn btn-primary skip_for_now" data-bs-dismiss="modal">Skip for Now</button>
          </div>
     </div>
</div> -->

<!--Add Child Modal -->
<!-- <div class="modal fade" id="alreadyAccount" aria-labelledby="alreadyAccountLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="d-flex justify-content-between" >
                    <button class="btn back_btn" @click="openAddNewChildModal()">
                         <common-svgs type="back-arrow"></common-svgs>
                         Back
                    </button>
                    <div>
                         <button type="button" class="btn-close p-0 m-0 modal-button-close" @click="formInputReset()" data-bs-dismiss="modal"
                         aria-label="Close"></button>
                    </div>
               </div>    
               <div class="info">
                    <h2 class="enter_username">Enter username</h2>
                    <p class="enter_username_text">
                         Please enter the username that is associated with your child's account
                    </p>

                    <form @submit.prevent="checkUserName">
                         <text-input class="col-md-12 username" id="username" :noformgroup="true" v-model="form.user_name" label="User Name"  placeholder="User Name"></text-input>
                         <p class="not_found_message mb-0 d-none">
                              <common-svgs type="not-found"></common-svgs>
                              No child found against this username. Please check the spelling and try again.
                         </p>
                         <button class="btn btn-primary continue_btn w-100">Continue</button>
                    </form>
               </div>
          </div>
     </div>
</div> -->

<!--Add Child Modal -->
<!-- <div class="modal fade" id="foundChild" aria-labelledby="foundChildLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="d-flex justify-content-between" >
                    <button class="btn back_btn" data-bs-toggle="modal" data-bs-target="#alreadyAccount">
                         <common-svgs type="back-arrow"></common-svgs>
                         Back
                    </button>
                    <div>
                         <button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                         aria-label="Close"></button>
                    </div>
               </div>    
               <div class="info">
                    <h2 class="enter_username">Finally ! We’ve found your child 🎉</h2>
                    <p class="enter_username_text m-0">
                         Add Child to your child for account access.
                    </p>
                    <div>
                         <parent-students :bgColor="bgColor" :isModal = "isModal" :full_name="searchFullName" :school_name="searchSchoolName" :age="searchChildDOB" :id="searchChildId" :invitations="invitations"></parent-students>
                         <button class="btn btn-primary w-100 mt-40" data-bs-toggle="modal" data-bs-target="#foundChild">Continue</button>
                    </div>
               </div>
          </div>
     </div>
</div> -->

<!-- <div class="modal fade" id="addNewChild" aria-labelledby="addNewChildLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="d-flex justify-content-between" >
                    <button class="btn back_btn" data-bs-toggle="modal" data-bs-target="#addAChild">
                         <common-svgs type="back-arrow"></common-svgs>
                         Back
                    </button>
               </div>    
               <div class="info">
                    <h2 class="enter_username">Add a child </h2>
                    <p class="enter_username_text">
                         Let’s create an account for your child.
                    </p>
               </div>

               <new-child @UsernamePassword="getUserNameAndPassword"></new-child>
          </div>
     </div>
</div> -->

<!-- <div class="modal fade" id="accountCreated" aria-labelledby="accountCreatedLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="d-flex justify-content-between" >
                    <button class="btn back_btn" data-bs-toggle="modal" data-bs-target="#addNewChild">
                         <common-svgs type="back-arrow"></common-svgs>
                         Back
                    </button>
               </div>    
               <div class="info">
                    <h2 class="enter_username">Account Created ✨</h2>
                    <p class="enter_username_text">
                         Please use this user name and password to login to your child account, you can change it later in account setting.
                    </p>
               </div>
               <div class="account_created_div">
                    <div class="inner_div">
                         <label for="User Name" class="label">User Name</label>
                         <h4 class="content" id="created_user_name">{{ created_user_name }}</h4>
                         <hr>
                         <label for="User Name" class="label">Password</label>
                         <h4 class="content" id="created_user_password">{{ created_user_password }}</h4>
                    </div>
               </div>
               <div class="btn_div">
                    <button class="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#accountCreated">Continue</button>
               </div>
          </div>
     </div>
</div> -->
     
<!--Add Child Modal -->
<div class="modal fade " id="addAChild" aria-labelledby="addAChildLabel" aria-hidden="true">
     <div class="modal-dialog  modal-dialog-centered modal-width-576">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="modal-header p-0">
                    <h2 class="modal-title" id="addAChildLabel">Add a Child</h2>
                    <button type="button" class="btn-close p-0 m-0 modal-button-close" @click="formInputReset()" data-bs-dismiss="modal"
                         aria-label="Close">
                    </button>
                    <!-- <button type="button" class="btn custom-back-btn" :id="$attrs.id" @click="formInputReset()">
                    <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                    back
                    </button> -->
               </div>
               <p class="student_modal_text mb-0">Please add them to your account and track their Progress.. </p>
               <button class="btn btn-primary child_already_have_account" data-bs-toggle="modal" data-bs-target="#alreadyAccount">My child already have account</button>
               <button class="btn btn-primary new_account_for_child" data-bs-toggle="modal" data-bs-target="#addNewChild">Create a new account for my child</button>
               <button class="btn btn-primary skip_for_now" data-bs-dismiss="modal">Skip for Now</button>
          </div>
     </div>
</div>

<!--Add Child Modal -->
<div class="modal fade" id="alreadyAccount" aria-labelledby="alreadyAccountLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-centered modal-width-576">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="d-flex justify-content-between" >
                    <!-- <button class="btn back_btn" @click="openAddNewChildModal()">
                         <common-svgs type="back-arrow"></common-svgs>
                         Back
                    </button> -->
                    <button type="button" class="btn custom-back-btn"  @click="openAddNewChildModal()">
                    <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                    back
                    </button>
                    <div>
                         <button type="button" class="btn-close p-0 m-0 modal-button-close" @click="formInputReset()" data-bs-dismiss="modal"
                         aria-label="Close"></button>
                    </div>
               </div>    
               <div class="info">
                    <h2 class="enter_username">Enter username</h2>
                    <p class="enter_username_text">
                         Please enter the username that is associated with your child's account
                    </p>

                    <form @submit.prevent="checkUserName">
                         <text-input class="col-md-12 username" id="username" :noformgroup="true" v-model="form.user_name" label="Username"  placeholder="Username"></text-input>
                         <p class="not_found_message mb-0 d-none">
                              <common-svgs type="not-found"></common-svgs>
                              No child found against this username. Please check the spelling and try again.
                         </p>
                         <button class="btn continue-btn w-100 fs-18 mt-40">Continue</button>
                    </form>
               </div>
          </div>
     </div>
</div>

<!--Add Child Modal -->
<div class="modal fade" id="foundChild" aria-labelledby="foundChildLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-centered modal-width-576">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="d-flex justify-content-between" >
                    <!-- <button class="btn back_btn" data-bs-toggle="modal" data-bs-target="#alreadyAccount">
                         <common-svgs type="back-arrow"></common-svgs>
                         Back
                    </button> -->
                    <button type="button" class="btn custom-back-btn"  data-bs-toggle="modal" data-bs-target="#alreadyAccount">
                    <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                    back
                    </button>
                    <div>
                         <button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                         aria-label="Close"></button>
                    </div>
               </div>    
               <div class="info">
                    <h2 class="enter_username">Finally ! We’ve found your child 🎉</h2>
                    <p class="enter_username_text">
                         Add Child to your child for account access.
                    </p>
                    <div>
                         <!-- {{ bgColor }} -->
                         <parent-students :bgColor="bgColor" :isModal = "isModal" :full_name="searchFullName" :school_name="searchSchoolName" :age="searchChildDOB" :id="searchChildId" :invitations="invitations" @getStudents="fetchStudents"></parent-students>
                    </div>
               </div>
          </div>
     </div>
</div>

<div class="modal fade" id="addNewChild" aria-labelledby="addNewChildLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-centered modal-width-576">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="d-flex justify-content-between" >
                    <!-- <button class="btn back_btn" data-bs-toggle="modal" data-bs-target="#addAChild">
                         <common-svgs type="back-arrow"></common-svgs>
                         Back
                    </button> -->
                    <button type="button" class="btn custom-back-btn"  data-bs-toggle="modal" data-bs-target="#addAChild">
                    <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                         back
                    </button>
                    
               </div>    
               <div class="info">
                    <h2 class="enter_username">Add a child </h2>
                    <p style="margin-top: 12px !important; margin-bottom: 40px !important;">
                         Let’s create an account for your child.
                    </p>
               </div>

               <new-child @UsernamePassword="getUserNameAndPassword"></new-child>
          </div>
     </div>
</div>

<div class="modal fade" id="accountCreated" aria-labelledby="accountCreatedLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-centered modal-width-576">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="d-flex justify-content-between" >
                    <button type="button" class="btn custom-back-btn" data-bs-toggle="modal" data-bs-target="#addNewChild">
                    <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                         back
                    </button>
                    <div>
                         <button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                         aria-label="Close"></button>
                    </div>
                    <div class="d-none custom-toaster">
                         <span class="copied-to-clipboard-text">Copied To Clipboard</span>
                         <div onclick="$('.custom-toaster').addClass('d-none')">
                              <common-svgs type="cross-icon"></common-svgs>
                         </div>
                    </div>
               </div>    
               <div class="info">
                    <h2 class="enter_username">Account Created ✨</h2>
                    <p class="enter_username_text">
                         Please use this username and password to login to your child account, you can change it later in account setting.
                    </p>
               </div>
               <div class="account_created_div">
                    <div class="inner_div">
                         <label for="Username" class="label">Username</label>
                         <h4 class="content" id="created_user_name">{{ created_user_name }}</h4>
                         <hr>
                         <label for="Username" class="label">Password</label>
                         <h4 class="content" id="created_user_password">{{ created_user_password }}</h4>
                    </div>
               </div>
               <div class="row">
                    <div class="col-md-5">
                         <div class="copy-to-clipboard" @click="copy()">
                              <common-svgs style="margin: 0 auto;" type="copy-clipboard"></common-svgs>
                              <span class="copy-clipboard-text">Copy to Clipboard</span>
                         </div>
                    </div>
               </div>
               <div class="mt-40">
                    <button class="btn continue-btn w-100 fs-18" @click="closeAccountCreatedModal()">Continue</button>
               </div>
          </div>
     </div>
</div>
</template>

<style scoped>
.parent_data {
     margin-top: 24px !important;
}

.select_child_heading {
     color:#212121 !important;
     font-size: 20px !important;
     line-height: 28px !important;
}

.profile {
     color:#717171 !important;
     font-size:14px !important;
     margin-top: 8px !important;
}
.modal-header {
    border-bottom: none !important;
}

.modal-button-close {
    /* position: absolute;
    right: 20px;
    top: 20px; */
    padding: 10px !important;
    border-radius: 12px;
    border: 1px solid rgba(77, 77, 77, 0.2);
}
.student_modal_text {
    color: #717171;
    font-size: 16px;
}
#addAChildLabel {
     margin-bottom: 12px !important;
}
/* #addAChild .modal-content {
     width: 574px !important;
} */
.child_already_have_account {
     margin-top: 40px !important;
     background: #FAFAFA;
     /* background: rgba(36, 99, 235, 0.1); */
     color: #000000;
     border-radius: 12px;
     border-color: snow;
     font-size: 16px !important;
     padding: 16px 12px !important;
}

.child_already_have_account:hover, .new_account_for_child:hover, .skip_for_now:hover {
     background: rgba(36, 99, 235, 0.1);
}
.new_account_for_child {
     margin-top: 16px !important;
     color: #000000;
     font-size: 16px;
     background-color: #FAFAFA;
     border-color: snow;
     border-radius: 12px;
     padding: 16px 12px !important;
     margin-bottom: 8px !important;
}
.skip_for_now {
     border-radius: 12px;
     border-color: snow;
     background-color: #FFFFFF;
     color: #2463EB;
     padding: 16px 12px !important;
     margin-top: 8px !important;
}
.back_btn {
     color: #9CA3AF;
}
.info {
     margin-top: 40px !important;
}
.enter_username {
     color: #212121;
     font-size: 36px;
}
.enter_username_text {
     margin-top: 12px;
     color: #717171;
     font-size: 16px;
     margin-bottom: 32px !important;
}
.username {
     margin-top: 40px !important;
}
.continue_btn {
     margin-top: 40px;   
}
.account_created_div {
     background: linear-gradient(90deg, rgba(36, 99, 235, 0.08) 0%, rgba(255, 186, 0, 0.08) 100%);
     border-radius: 12px;
}
.inner_div {
     padding: 28px 16px !important;
}
.label {
     font-size: 14px !important;
     color: #89939E !important;
}
.content {
     font-size: 20px;
     color: #212121;
     margin-top: 8px !important;
}
.btn_div {
    margin-top: 56px !important;
}
.not_found_message {
     font-size: 11px;
     color: #E02B1D;
     margin-left: 12px !important;
     margin-right: 12px !important;
     margin-top: 10px !important;
}
.card-body {
     padding: 0px;
     background: #FAFAFA !important;
     display: flex;
     padding: 16px;
     align-items: center;
     margin-bottom: 12px;
}

.mt-40 {
     margin-top: 40px !important;
}
.modal-width-576 {
     max-width: 576px !important;
}
.modal-button-close {
    /* position: absolute;
    right: 20px;
    top: 20px; */
    padding: 10px !important;
    border-radius: 12px;
    border: 1px solid rgba(77, 77, 77, 0.2);
    margin-top: -80px !important;
    margin-right: -20px !important;
}
.continue-btn {
     background: #2463EB;
     color: #FFFFFF;
     padding: 16px 0px !important;
}
.continue-btn:hover {
     background: #1453DC;
}
.mt-32 {
     margin-top: 32px !important;
}
.copy-to-clipboard {
     padding: 8px 16px;
     margin-top: 24px !important;
     border-radius: 8px !important;
     background: rgba(36, 99, 235, 0.10) !important;
     cursor: pointer;
}
.copy-clipboard-text {
     font-size: 14px !important;
     color: #2463EB !important;
     font-weight: 400 !important;
     margin-left: 8px !important;
}

.custom-toaster {
          display: flex;
          padding: 8px 12px;
          background: #fff;
          border-radius: 8px;
          border: 1px solid #E5E7EB;
          position: absolute;
          right: -230px !important;
     }

     .copied-to-clipboard-text {
          font-size: 12px;
          margin-right: 48px !important;
          color: #6B7280;
          font-weight: 600;
          margin-top: 7px;
     }
</style>