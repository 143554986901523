<template>
  <div class="container" style="padding-top: 170px;">
    <div class="row mb-5">
      <div class="col-md-6 offset-md-3">
        <card-box v-if="step == 1" title="Join as a Student" backtitle="Back" @back-btn-clicked="stepOneBack"
          shortdesc="Build learning for today, tomorrow, and beyond. ">
           
          <date-input @on-change="validate" type="text dob" label="Date of Birth" class="col-md-12" :minDate="$moment($moment()).subtract(4, 'years').format('YYYY-MM-DD')" v-model="form.dob" placeholder="Date of Birth"></date-input>
          <text-input @change="validate" class="col-md-12 fullname" v-model="form.full_name" :error="error.full_name" label="Full Name"  placeholder="Full Name"></text-input>
           
          <conditional-input @focus="revalidate" @blur="checkUserName" :error="usernameerror"  :condition="feildCondition" :helpconditionalpretext="'Suggested : '" :helpconditionaltext="conditionalHelpText" class="col-md-12 user_name"
            v-model="form.username" label="Username" placeholder="Username"></conditional-input>
          
          
          <text-input class="col-md-12" @change="validate" type="phone" v-model="form.phone1" :error="error.phone1" maxLength="11" label="Parent’s Phone Number" placeholder="Parent’s Phone Number"></text-input>
          <text-input class="col-md-12" type="phone" v-model="form.phone2" maxLength="11" label="Personal Phone Number (Optional)" placeholder="Personal Phone Number (Optional)"></text-input>
          <text-input class="col-md-12" @change="validate();" v-model="form.email" :error="emailError" placeholder="Email Address (Optional)" label="Email Address (Optional)"></text-input>
          <password-input @change="validate();" :error="passwordError1"  @blur="checkPassword" v-model="form.password" class="col-md-12 passwordfeild"
            placeholder="Enter Password" label="Enter Password"
            helptext="Your password can be any combination of letters, numbers, and symbols."></password-input>

          <password-input :error="passwordError" @change="validate();"  @blur="checkPassword" v-model="form.password_confirmation" class="col-md-12 passwordfeild"
            placeholder="Confirm Password"  label="Confirm Password"></password-input>

          <check-box class="col-md-12" v-model="terms"  @checkbox-clicked="termselected">
            By creating an account, you agree to the <a  href="javascript:void(0);" onclick="openModalJs('#termscondition')"  class="a-link" style="font-size: 14px;">Terms of
              Service</a> and Honor Code and <a href="javascript:void(0);" onclick="openModalJs('#privacypolicy')"  class="a-link" style="font-size: 14px;">Privacy Policy</a>.
          </check-box>


          <div class="col-md-12 mb-3"> 
            <button class="btn btn-primary btn-block" :disabled="isValid" @click="nextStep">Continue</button>
          </div>
        </card-box>
        <card-box v-else  title="Enter your school details" backtitle="Back" @back-btn-clicked="stepTwoBack"
          shortdesc="Please provide your school details to view your customized courses.  ">

          <!-- <select-box class="col-md-12" v-model="form.province_id" placeholder="Province"
            @change="cityProvinceAutomation('province'); checkStepTwoValidation();">
            <option v-for="province in provinces" :value="province.id">{{ province.name }}</option>
          </select-box> -->
          <select-box class="col-md-12" v-model="form.city_id" placeholder="City"  label="City"
            @do-change="cityProvinceAutomation('city');citySelect(); checkStepTwoValidation();">
            <option v-for="city in fil_cities" :value="city.id">{{ city.name }}</option>
          </select-box>
          <select-box class="col-md-12" v-model="form.school_id" placeholder="School"  label="School"
            @do-change=" schoolSelect(); checkStepTwoValidation();">
            <option v-for="school in schools" :value="school.id">{{ school.name }}</option>
          </select-box>
          <select-box class="col-md-12" v-model="form.class_id" placeholder="Class" label="Class"
            @do-change=" checkStepTwoValidation();">
            <option v-for="cl in fil_classes" :value="cl.id">{{ cl.name }}</option>
          </select-box>

          <div class="col-md-12 mb-3">
            <button type="button" class="btn btn-primary btn-block" @click="signupStudent" :disabled="isInValidTwo">Sign up</button>
          </div>
        </card-box>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import factory from '@/common/factory.js';
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";
import {functions} from '@/common/functions.js';


import DateInput from "@/components/DateInput.vue";
import moment from "moment";
import ConditionalInput from "@/components/ConditionalInput.vue";
import TextInput from "@/components/TextInput.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import CardBox from "@/components/CardBox.vue";
import CheckBox from "@/components/CheckBox.vue";

const auth = useAuthStore(); 
const router = useRouter()
var isValid = ref(true);
var inValidUsername = ref(false);
var isInValidTwo = ref(true);
var iaccept = ref(false);
var feildCondition = ref('warning');
var conditionalHelpText = ref([]);
var emailError = ref('');
var passwordError = ref('');
var passwordError1 = ref('');
var usernameerror = ref('');
var form = ref({
  dob: '',
  full_name: '',
  phone1: '',
  phone2: '',
  email: '',
  password: '',
  password_confirmation: '',
  username: "",
  // school_name: "",
  // phone: "",
  province_id: '',
  city_id: '',
  school_id: '',
  class_id: '',
});
var error = ref({});
var terms = ref(false);
var step = ref(1);
function termselected(condition) {
  iaccept.value = condition;
  validate();
}
function checkPassword() {
  if (((form.value.password_confirmation) != '' && (form.value.password) != '') && form.value.password != form.value.password_confirmation) {
    passwordError.value = "Password & Confirm Password doesn't match."
  } else {
    passwordError.value = ""

  }
}
function revalidate() {
  feildCondition.value = 'none';
  inValidUsername.value = false;
  isValid.value = true;
}
function validate() {
  usernameerror.value = '';
  error.value = {}
  isValid.value = !iaccept.value;

  if(form.value.username == '' || !inValidUsername.value){
    isValid.value = true;
    if(form.value.username != '' && form.value.username.length <= 4){
      usernameerror.value = 'Username should be atleast 5 Characters.';
    }
    // $('.user_name').find('input').addClass('error-border');
  }else{
   
    // $('.user_name').find('input').removeClass('error-border');
  }
  if(form.value.dob == ''){
    isValid.value = true;
    // $('.dob').find('input').addClass('error-border');
  }else{
    // $('.dob').find('input').removeClass('error-border');
  }
  if(form.value.full_name == ''){
    isValid.value = true;
    // $('.fullname').find('input').addClass('error-border');
  }else{
    // $('.fullname').find('input').removeClass('error-border');
  }

  if ((form.value.email) != '') {
    if (!validateEmail(form.value.email)) {
      isValid.value = true;
      emailError.value = 'Invalid Email.'
    } else {
      emailError.value = ''

    }
  } else {
    emailError.value = ''

  }
   
  if ( (form.value.password_confirmation) == '' || form.value.password != form.value.password_confirmation) {
    isValid.value = true;
    // $('.passwordfeild').find('input').addClass('error-border');
  }  
  if (form.value.phone1 != '') {
    if (form.value.phone1.length < 11) {
      isValid.value = true;
      error.value.phone1 = 'Phone Number should be atleast 11 Characters.';
    }
  } else {
    isValid.value = true;
  }

  if(form.value.password != '' && form.value.password.length < 8){
    isValid.value = true;
    passwordError1.value = 'Password length should be atleast 8 characters.'
  }else{
    passwordError1.value = ''

  }
}

var provinces = ref([]);
var error = ref({});
var cities = ref([]);
var fil_cities = ref([]);

var schools = ref([]);

var classes = ref([]);
var fil_classes = ref([]);
 

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
}

var ProvincesCities = new factory(getToken(), 'provinces-cities');
ProvincesCities.getHttp().then((response) => {
  if (response.data.status) {
    provinces.value = provinces.value = response.data.data.provinces;
    fil_cities.value = cities.value = response.data.data.cities;
  }
});

 

async function signupStudent() {
  
  form.value.dob = moment(form.value.dob).format("YYYY-MM-DD")
  const SignUp = new factory(getToken(), 'student-signup', form.value);
  await SignUp.postForm().then((response) => {
    // console.log(response.data); 
    // console.log(response.data.status); 
    if (response.data.status) {
      auth.token = response.data.data.token;
      auth.userinfo = response.data.data;
      swal.fire('Done!', 'Sign up Successful.', 'success');
      setTimeout(() => {
        router.push('/login');
        
      }, 300);
    } else {
      if(response.data.message)
      {
        error.value = functions.errorResponse({error:response.data.message});

        // swal.fire('Error!',response.data.message,'error')
      }
      else
      {
        swal.fire('Error!','Something went wrong!','error')
      }
    }
  });
};
function cityProvinceAutomation(condition) {
  if (condition == 'city') {
    var selectedcity = cities.value.find((item) => (item.id == form.value.city_id));
    var selectedprovince = provinces.value.find((item) => (item.id == selectedcity.province_id));
    form.value.province_id = selectedprovince.id;
  } else {
    fil_cities.value = cities.value.filter((item) => (item.province_id == form.value.province_id));

  }
}
function citySelect(){ 
  var payload = {
    city_id: form.value.city_id
  }
  var schoolApi = new factory(getToken(), 'city-schools',payload);
  schoolApi.getHttp().then((response) => {
    fil_classes.value = [];
    if (response.data.status) {
      schools.value = response.data.data;
    }else{
      schools.value = []; 
    }
  });
}
function schoolSelect(){ 
  var payload = {
    school_id: form.value.school_id
  }
  var classesApi = new factory(getToken(), 'school-classes',payload);
  classesApi.getHttp().then((response) => {
    if (response.data.status) {
      fil_classes.value = response.data.data;
    }else{
      fil_classes.value = [];
    }
  });
}
// function schoolClassesFilter(condition) {
//   if (condition == 'classes') {
//     var selectedclass = classes.value.find((item) => (item.id == form.value.class_id));
//     var selectedschool = schools.value.find((item) => (item.id == selectedclass.school_id));
//     form.value.school_id = selectedschool.id;
//   } else {
//     fil_classes.value = classes.value.filter((item) => (item.school_id == form.value.school_id));

//   }
// }


function nextStep() {
  window.scrollTo(0, 0);
  if (!isValid.value) {
    step.value = 2;
  } else {
    step.value = 1;

  }
}
function stepOneBack() {
  router.push('/');
}
function stepTwoBack() {
  step.value = 1;
}

async function checkUserName() { 
  
  feildCondition.value = 'warning';

  if (form.value.username.trim() == '' || form.value.username.length <= 4) {
      feildCondition.value = 'warning';
      inValidUsername.value = false;
      validate();
      checkStepTwoValidation();
      return false;
  }
  var checkuser = {
    'full_name':form.value.full_name,
    'user_name':form.value.username
  }
  const SignUp = new factory(getToken(), 'check-username-availability', checkuser);
  await SignUp.getHttp().then((response) => {
    
    // if (response.data.data != null && response.data.data != "null") {
    if (response.data.status) {
      feildCondition.value = 'warning';
      inValidUsername.value = false;
      conditionalHelpText.value = response.data.data.suggested_user_names; 
    } else {
      if(response.data.error != null && response.data.error.length > 0){
        feildCondition.value = 'warning';
        inValidUsername.value = false;
        error.value = functions.errorResponse(response.data,false);
      }else{
        inValidUsername.value = true;
        feildCondition.value = 'success';
        conditionalHelpText.value =[];

      }
    }
    validate();
    checkStepTwoValidation();
  });
}

function checkStepTwoValidation() { 
  isInValidTwo.value = false;
  if (form.value.province_id == null || form.value.province_id == undefined || form.value.province_id == '') {
    isInValidTwo.value = true;
  }
  if (form.value.city_id == null || form.value.city_id == undefined || form.value.city_id == '') {
    isInValidTwo.value = true;
  }
  if (form.value.school_id == null || form.value.school_id == undefined || form.value.school_id == '') {
    isInValidTwo.value = true;
  }
  if (form.value.class_id == null || form.value.class_id == undefined || form.value.class_id == '') {
    isInValidTwo.value = true;
  }
  if (!inValidUsername.value) {
    isInValidTwo.value = true;
  }
}
</script>