<template>
  <div :class="$attrs.class ?? 'col-md-6'">
    <div :class="($attrs.noformgroup) ? '' : 'mb-3'">
      <label v-if="label && ChangeValueMod" class="form-label" :for="id">{{ label }}</label> 
      <vue-date-picker :view="'year'"  auto-apply 
      :max-date="minDate"
      :start-date="minDate"
      :id="id" v-model="ChangeValueMod" :enableTimePicker="false"  :class="((label && ChangeValueMod)?' label-input ':' ')"
        v-bind="{ ...$attrs, class: null }"></vue-date-picker>
      <small v-if="$attrs.helptext" class=" atext-1">{{ $attrs.helptext }}</small>
      <div v-if="error" class="form-error">{{ error }}</div>
    </div>
  </div>
</template> 

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import moment from 'moment';
import '@vuepic/vue-datepicker/dist/main.css';
import { v4 as uuid } from 'uuid';

export default {
  inheritAttrs: false,
  data() {
    return {
      ChangeValueMod: this.modelValue 
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${uuid()}`
      },
    },
    type: {
      type: String,
      default: 'date',
    },
    error: String,
    label: String,
    minDate:String,
    modelValue: [Object,String],
  },
  updated(){ 
    this.loadValue();
  },
  emits: ['update:modelValue','on-change'],
  watch: {
    ChangeValueMod(val) { 
      this.$emit('update:modelValue', moment(val).format("YYYY-MM-DD"));
      this.$emit('on-change');
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    loadValue(){ 
      this.ChangeValueMod =  moment(this.modelValue).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
    }, 

  },
}
</script>
<style scoped>
.form-control,
.dp__input_wrap {
  background: rgba(137, 147, 158, 0.05);
  border: 0.5px solid #ABBED1;
  border-radius: 8px;
  height: 48px;
  /* padding: 12px 20px; */
  padding: 12px 12px 12px 16px;
  font-family: var(--def-fontfamily);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #4D4D4D;
}

.atext-1 {
  padding-left: 20px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #89939E;
}

label {
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding-left: 18px;
  padding-top: 5px;
  color: #4D4D4D;
  z-index: 1;
}</style>
