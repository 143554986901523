<script setup>
import { defineEmits } from 'vue';
import { functions } from "@/common/functions";

var emit = defineEmits(['courseclicked']);
function courseclicked(){ 
  emit('courseclicked','')
}
</script>

<template>
  <div style="padding-left: 12px;padding-right: 12px;" @click="courseclicked" data-id="{{ $attrs.dataid??'' }}">
    <div class="card">
      <img :src="$attrs.img"  class="card-img-top img-css">
      <div class="card-body">
        <h4>{{ $attrs.title }}</h4>
        <p class="card-text">{{ $attrs.book_name }}</p>
      </div>
    </div>
  </div>
</template> 

<style scoped>
.card {
  border: none;
  margin-bottom: 45px;
  overflow: hidden;
  height: 288px;
}

.card-text {
  font-size: 13px;
  line-height: 20px;
  padding-top: 8px;
}

.img-css{
    /* max-height: 150px;
    min-height: 150px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; */
    max-height: 170px;
    min-height: 170px;
    object-fit: cover;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px
  }

  .card img {
    width: 100%;
    height: 300;
    transition: all 1s ease-in-out;
  }
  
  .card:hover {
    box-shadow: 0px 15px 30px 0px rgba(186, 186, 186, 0.15);
    
  }
  .card:hover img{
    transform: scale(1.1);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }
@media (max-width: 915px) {}
</style>
 
