<script setup>
import { defineEmits, defineProps, onMounted, onUpdated, ref } from 'vue';
import { appConsts } from "@/common/constants.js";
import factory from '@/common/factory.js';
import { useAuthStore } from '@/store/auth.js';

var auth = useAuthStore();
var emit = defineEmits(['status_complete']);

var fileUrl = ref(appConsts.fileBaseUrl);
var props = defineProps(['data', 'topicid', 'chapterid', 'subjectclass_id']);
var data = ref(props.data);
var topicid = ref(props.topicid);
var chapterid = ref(props.chapterid);
var subjectclass_id = ref(props.subjectclass_id);
var extension = ref('');

function getToken() {
    const token = {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.token,
        },
    };
    return token;
}


async function topicComplete(obj) { 
     window.URL = window.URL || window.webkitURL;
console.log(obj)
//      var xhr = new XMLHttpRequest(),
//           a = document.createElement('a'),
//           file;
// // alert($(obj).attr('href'));
// // alert($(obj).attr('download'));
//      xhr.open('GET', $(obj).attr('href'), true);
//      xhr.responseType = 'blob';
//      xhr.onload = function () {
//           file = new Blob([xhr.response], {
//                type: 'application/octet-stream'
//           });
//           a.href = window.URL.createObjectURL(file);
//           a.download = $(obj).attr('download'); // Set to whatever file name you want
//           // Now just click the link you created
//           // Note that you may have to append the a element to the body somewhere
//           // for this to work in Firefox
//           a.click();
//      };
//      xhr.send(); 
    

    if (data.value.status == null || data.value.status == 0) {
        var filter = {
            topicdetailid: data.value.id,
            topicid: topicid.value,
            chapterid: chapterid.value,
            subjectclass_id: subjectclass_id.value,
        }
        const clsStd = new factory(getToken(), 'topic-content-complete', filter);
        await clsStd.getHttp().then((response) => {
            swal.close();
            emit('status_complete');
        });
    }
}


onMounted(() => {
    data.value = props.data;
    var ex = data.value.file_name;
    var exArr = ex.split('.');

    extension.value = exArr[exArr.length - 1];
    if ((data.value.status == null || data.value.status == 0) && extension.value == 'pdf') {
        topicComplete();
    }
});
onUpdated(() => {
    data.value = props.data;
    topicid.value = props.topicid;
    chapterid.value = props.chapterid;
    subjectclass_id.value = props.subjectclass_id;
    var ex = data.value.file_name;
    var exArr = ex.split('.');

    extension.value = exArr[exArr.length - 1];

    if ((data.value.status == null || data.value.status == 0) && extension.value == 'pdf') {
        topicComplete();
    }
});


</script>
<template> 
    <div v-if="extension == 'pdf'" class="topic-content-pdf">
        <iframe :src="fileUrl + data.file_name" height="100%" width="100%" />
    </div>
    <div class="topic-description">
        <h4 v-if="data.description">Description </h4>
        <p v-if="data.description" class="topic-description-text">
            {{ data.description }}
        </p>
        <div v-if="extension != 'pdf'" class="text-right" style="float: right;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: end;">
            <button :href="fileUrl + data.file_name" @click="topicComplete($event.target)" :download="data.file_title"
                class="btn btn-primary downloadfile" style="border-radius: 25px;">Download</button>
        </div>
    </div>
</template>
<style scoped>
.topic-content-doc {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
}

.topic-content-pdf {
    padding: 56.25% 0 0 0;
    position: relative;
    width: 100%;
}

.topic-content-pdf iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.topic-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
}

.topic-description-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #212121;
}
</style>