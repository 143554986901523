<script setup>
import { useAuthStore } from "@/store/auth.js";
import { onMounted, ref, defineEmits } from "vue";
import factory from "@/common/factory.js";
import { functions } from "@/common/functions.js";
import TextInput from "@/components/TextInput.vue";
import SubHeader from "@/views/parent/SubHeader.vue";
import ParentStudents from "@/views/parent/ParentStudents.vue";
import CommonSvgs from '@/components/CommonSvgs.vue';
import NewChild from "../parent/NewChild.vue";
import { useRouter } from 'vue-router';

const emit = defineEmits("UsernamePassword");
const router = useRouter();

const auth = useAuthStore();
const studentCount = ref(0);
const phoneNo = ref('');
const parentChilds = ref('');
const isStudents = ref(true);
const isModal = ref(true);
const searchFullName = ref('');
const searchSchoolName = ref('');
const searchChildDOB = ref('');
const searchChildId = ref('');
var invitations = ref(1);
var currentpage = ref(1);
const bgColor = ref(true);
const accepted_students = ref([]);
let credientials = ref();

var form = ref({
     user_name: '',
     phone: auth.userinfo.phone1
});
let pending_students_count = ref(0);

const created_user_name = ref('');
const created_user_password = ref('');

phoneNo.value = auth.userinfo.phone1;
function getToken() {
     const token = {
          headers: {
               // 'Content-Type': 'application/json',
               'Content-Type': 'application/x-www-form-urlencoded',
               'Authorization': 'Bearer ' + auth.token,
          },
     };
     return token;
}

async function student() {
     // functions.initLoader();
     const students = new factory(getToken(), 'parent-students');
     await students.getHttp().then((response) => {
          if (response.status == 200) {
               if(response.data.data.students.length > 0) {
                    response.data.data.students.forEach(single_student=> {
                         if(single_student.parent_student_invitations.length == 0) {
                              pending_students_count.value+=1;
                         }
                         else {
                              if(single_student.parent_student_invitations[0].status == 'Pending') {
                                   pending_students_count.value+=1;
                              }

                         }
                    });
               }
               // console.log("students", response.data.data.students);
               studentCount.value = response.data.data.students.length;
               parentChilds.value = response.data.data.students;
               // console.log('std count', studentCount.value);
               if (studentCount.value > 0) {
                    isStudents.value = true;
               }
               else {
                    isStudents.value = false;
               }
               swal.close();
          } else {
               isStudents.value = false;
          }
     });
};

// function BackPage() {
//     currentpage.value = currentpage.value-1;
//     student();
// }
// function ForwardPage() { 
//     currentpage.value = currentpage.value+1;
//     student();
// }
// function pageNoClicked(pageno){
//     currentpage.value = pageno;
//     student();
// }
function getAge(dateString) {
     var today = new Date();
     var birthDate = new Date(dateString);
     var age = today.getFullYear() - birthDate.getFullYear();
     var m = today.getMonth() - birthDate.getMonth();
     if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
     }
     return age;
}


onMounted(() => {
     student();
});
student();

async function checkUserName() {
     const checkUserNameAvailability = new factory(getToken(), 'get-parent-child-by-username', form.value);
     await checkUserNameAvailability.getHttp().then((response) => {
          if (response.data.status) {
               $('.not_found_message').removeClass('d-block').addClass('d-none');
               searchFullName.value = response.data.data.student.full_name
               searchSchoolName.value = response.data.data.student.student_class.school.name;
               searchChildDOB.value = getAge(response.data.data.student.dob);
               searchChildId.value = response.data.data.student.id;
               // invitations.value = response.data.data.student.parent_invitations;
               invitations.value = response.data.data.student.parent_student_invitations;
               $('#alreadyAccount').modal('hide');
               $('#foundChild').modal('show');
               // student();
          }
          else {
               $('.not_found_message').removeClass('d-none').addClass('d-block');
          }
     });
};


function getUserNameAndPassword(username, password) {
     created_user_name.value = username;
     created_user_password.value = password;
     $('#addNewChild').modal('hide');
     setTimeout(() => {
          $('#accountCreated').modal('show');
     }, 1000);
     student();
}

function openAddNewChildModal() {
     formInputReset();
     $('.not_found_message').removeClass('d-block').addClass('d-none');
     $('#alreadyAccount').modal('hide');
     $('#addAChild').modal('show');
}

function fetchStudents() {
     student();
     formInputReset();
}

function formInputReset() {
     form.value.user_name = '';
}

const copy = async () => {
     let user_name = document.getElementById('created_user_name').innerHTML;
     let user_password = document.getElementById('created_user_password').innerHTML;
     try {
          let message = "The account of your child has been \nsuccessfully activated. Please login\nusing the below username and\npassword. Here are the credentials\nof your child(s) account.\n\n" +
               "Username: " + user_name + "\n" +
               "Password: " + user_password + "\n\n" +
               "Contact our customer support in\ncase you need any assistance.\n\n" +
               "Alternates";
          credientials.value = await navigator.clipboard.writeText(message);
          $('.credientials').val(credientials.value);
          $(".custom-toaster").removeClass('d-none');
          setTimeout(() => {
               $(".custom-toaster").addClass('d-none');
          }, 5000);

     } catch (err) {
          console.error('Failed to copy: ', err);
     }
}

async function acceptStatusStudent() {
     const students = new factory(getToken(), 'accepted-status-students');
     await students.getHttp().then((response) => {
          swal.close();
          if (response.data.status && response.data.data.length > 0) {
               accepted_students.value = response.data.data;
          }
          else {
               accepted_students.value = [];
          }
     });
};
acceptStatusStudent();
</script>

<template>
     <div class="row m-0">
          <div :class="accepted_students == [] ? 'offset-9' : ''" class="col-md-3 p-0 display-xs"
               style="text-align: end;display:none;">
               <button class="btn btn-primary" style="padding: 8px 24px !important;
                    margin-bottom: 24px;" data-bs-toggle="modal" data-bs-target="#addAChild">
                    <common-svgs type="plus-icon"></common-svgs>
                    Add Child
               </button>
          </div>
          <div v-if="auth.userinfo.type == 'Parent' && parentChilds.length > 0 || accepted_students.length > 0"
               class="col-md-9 p-0">
               <sub-header :class="'parent-students-list'" :parent_child="true"
                    :accepted_students="accepted_students"></sub-header>
          </div>
          <div :class="(parentChilds.length == 0 && accepted_students.length == 0) ? 'offset-md-9' : ''" class="col-md-3 p-0 hidden-xs"
               style="text-align: end;">
               <button class="btn continue-btn" style="padding: 14px 32px !important;" data-bs-toggle="modal"
                    data-bs-target="#addAChild">
                    <common-svgs type="plus-icon"></common-svgs>
                    Add Child
               </button>
          </div>
     </div>
     <!-- <div class="parent_data" v-if="parentChilds.length > 0"> -->
     <div class="parent_data" v-if="pending_students_count > 0">
          <h4 class="select_child_heading">Select Your Child</h4>
          <p class="profile">Sent an invite request to your child's account.</p>
          <!-- <p  class="profile">Found <span v-if="isStudents">{{ studentCount }}</span> <span v-else>0</span> profiles registered using this number {{ phoneNo }}</p> -->
     </div>
     <div v-else>
          <div class="d-flex justify-content-center align-items-center" style="padding-top: 140px !important;">
               <img src="../../../public/files/assets/img/no-children-found.png" alt="">
          </div>
     </div>
     <div v-for="parentChild in parentChilds" :key="parentChild.key">
          <div v-if="parentChild.parent_student_invitations.length > 0">
               <!-- <div v-if="parentChild.parent_invitations[0].pivot.status != 'Accepted' && parentChild.users != null"> -->
               <div v-if="(parentChild.parent_student_invitations[0].pivot.status != 'Accepted'  && parentChild.parent_student_invitations[0].pivot.status != 'Rejected') && parentChild.users != null">
                    <!-- <parent-students :isModal = "!isModal" :full_name="parentChild.full_name" :school_name="parentChild.student_class.school.name" :age="getAge(parentChild.dob)" :id="parentChild.id" :invitations="parentChild.parent_invitations.length" @getStudents="fetchStudents"></parent-students> -->
                    <parent-students :isModal="!isModal" :full_name="parentChild.full_name"
                         :school_name="parentChild.student_class.school.name" :age="getAge(parentChild.dob)"
                         :id="parentChild.id" :invitations="parentChild.parent_student_invitations.length"
                         @getStudents="fetchStudents"></parent-students>
               </div>
          </div>
          <div v-else>
               <div v-if="parentChild.users != null">
                    <!-- <parent-students :isModal = "!isModal" :full_name="parentChild.full_name" :school_name="parentChild.student_class.school.name" :age="getAge(parentChild.dob)" :id="parentChild.id" :invitations="parentChild.parent_invitations.length" @getStudents="fetchStudents"></parent-students> -->
                    <parent-students :isModal="!isModal" :full_name="parentChild.full_name"
                         :school_name="parentChild.student_class.school.name" :age="getAge(parentChild.dob)"
                         :id="parentChild.id" :invitations="parentChild.parent_student_invitations.length"
                         @getStudents="fetchStudents"></parent-students>
               </div>
          </div>
     </div>

     <!-- <div class="col-md-12" v-if="studentCount>0">
          <div>  
               <Paginate @back-btn="BackPage" @forward-btn="ForwardPage" :total="totalRecords" :perpage="5" :currentpage="currentpage"
               @page-btn-clicked="pageNoClicked"></Paginate>
          </div>
     </div> -->


     <!--Add Child Modal -->
     <div class="modal fade " id="addAChild" aria-labelledby="addAChildLabel" aria-hidden="true">
          <div class="modal-dialog  modal-dialog-centered modal-width-576">
               <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
                    <div class="modal-header p-0">
                         <h2 class="modal-title" id="addAChildLabel">Add a Child</h2>
                         <button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                              aria-label="Close">
                         </button>
                         <!-- <button type="button" class="btn custom-back-btn" :id="$attrs.id" @click="formInputReset()">
                    <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                    back
                    </button> -->
                    </div>
                    <p class="student_modal_text mb-0">Please add them to your account and track their Progress.. </p>
                    <button class="btn btn-primary child_already_have_account" data-bs-toggle="modal"
                         data-bs-target="#alreadyAccount">My child already have account</button>
                    <button class="btn btn-primary new_account_for_child" data-bs-toggle="modal"
                         data-bs-target="#addNewChild">Create a new account for my child</button>
                    <button class="btn btn-primary skip_for_now" data-bs-dismiss="modal">Skip for Now</button>
               </div>
          </div>
     </div>

     <!--Add Child Modal -->
     <div class="modal fade" id="alreadyAccount" aria-labelledby="alreadyAccountLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-width-576">
               <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
                    <div class="d-flex justify-content-between">
                         <!-- <button class="btn back_btn" @click="openAddNewChildModal()">
                         <common-svgs type="back-arrow"></common-svgs>
                         Back
                    </button> -->
                         <button type="button" class="btn custom-back-btn" @click="openAddNewChildModal()">
                              <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                              Back
                         </button>
                         <div>
                              <button type="button" class="btn-close p-0 m-0 modal-button-close" @click="formInputReset()"
                                   data-bs-dismiss="modal" aria-label="Close"></button>
                         </div>
                    </div>
                    <div class="info">
                         <h2 class="enter_username">Enter username</h2>
                         <p class="enter_username_text">
                              Please enter the username that is associated with your child's account
                         </p>

                         <form @submit.prevent="checkUserName">
                              <text-input class="col-md-12 username" id="username" :noformgroup="true"
                                   v-model="form.user_name" label="Username" placeholder="Username"></text-input>
                              <p class="not_found_message mb-0 d-none">
                                   <common-svgs type="not-found"></common-svgs>
                                   No child found against this username. Please check the spelling and try again.
                              </p>
                              <button class="btn continue-btn w-100 fs-18 mt-40">Continue</button>
                         </form>
                    </div>
               </div>
          </div>
     </div>

     <!--Add Child Modal -->
     <div class="modal fade" id="foundChild" aria-labelledby="foundChildLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-width-576 found-child-dialog">
               <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
                    <div class="d-flex justify-content-between">
                         <!-- <button class="btn back_btn" data-bs-toggle="modal" data-bs-target="#alreadyAccount">
                         <common-svgs type="back-arrow"></common-svgs>
                         Back
                    </button> -->
                         <button type="button" class="btn custom-back-btn" data-bs-toggle="modal"
                              data-bs-target="#alreadyAccount">
                              <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                              Back
                         </button>
                         <div>
                              <button type="button" @click="formInputReset()" class="btn-close p-0 m-0 modal-button-close"
                                   data-bs-dismiss="modal" aria-label="Close"></button>
                         </div>
                    </div>
                    <div class="info">
                         <h2 class="enter_username">Finally ! We’ve found your child 🎉</h2>
                         <p class="enter_username_text">
                              Add child to monitor his/her progress.
                         </p>
                         <div>
                              <parent-students :bgColor="bgColor" :isModal="isModal" :full_name="searchFullName"
                                   :school_name="searchSchoolName" :age="searchChildDOB" :id="searchChildId"
                                   :invitations="invitations" @getStudents="fetchStudents"></parent-students>
                         </div>
                         <button class="btn continue-btn w-100 fs-18 mt-40" data-bs-toggle="modal"
                              data-bs-target="#foundChild">Continue</button>

                    </div>
               </div>
          </div>
     </div>

     <div class="modal fade" id="addNewChild" aria-labelledby="addNewChildLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-width-576">
               <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
                    <div class="d-flex justify-content-between">
                         <!-- <button class="btn back_btn" data-bs-toggle="modal" data-bs-target="#addAChild">
                         <common-svgs type="back-arrow"></common-svgs>
                         Back
                    </button> -->
                         <button type="button" class="btn custom-back-btn" data-bs-toggle="modal"
                              data-bs-target="#addAChild">
                              <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                              Back
                         </button>
                    </div>
                    <div class="info">
                         <h2 class="enter_username">Add a child </h2>
                         <p style="margin-top: 12px !important; margin-bottom: 40px !important;">
                              Let’s create an account for your child.
                         </p>
                    </div>

                    <new-child @UsernamePassword="getUserNameAndPassword"></new-child>
               </div>
          </div>
     </div>

     <div class="modal fade" id="accountCreated" aria-labelledby="accountCreatedLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-width-576">
               <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
                    <div class="d-flex justify-content-between">
                         <button type="button" class="btn custom-back-btn" data-bs-toggle="modal"
                              data-bs-target="#addNewChild">
                              <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                              Back
                         </button>
                         <div class="d-none custom-toaster">
                              <span class="copied-to-clipboard-text">Copied To Clipboard</span>
                              <div onclick="$('.custom-toaster').addClass('d-none')">
                                   <common-svgs type="cross-icon"></common-svgs>
                              </div>
                         </div>
                         <!-- <div class="d-none custom-toaster">
                         <span class="copied-to-clipboard-text">Copied To clipboard</span>
                         <common-svgs type="cross-icon" @click="$('.custom-toaster').addClass('d-none')"></common-svgs>
                    </div> -->
                         <div>
                              <button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                                   aria-label="Close"></button>
                         </div>
                    </div>
                    <div class="info">
                         <h2 class="enter_username">Account Created ✨</h2>
                         <p class="enter_username_text">
                              Please use this username and password to login to your child account, you can change it later
                              in account setting.
                         </p>
                    </div>
                    <div class="account_created_div">
                         <div class="inner_div">

                              <input type="hidden" name="credientials" class="credientials" />
                              <label for="Username" class="label">Username</label>
                              <h4 class="content" id="created_user_name">{{ created_user_name }}</h4>
                              <hr>
                              <label for="Username" class="label">Password</label>
                              <h4 class="content" id="created_user_password">{{ created_user_password }}</h4>
                         </div>
                    </div>
                    <div class="row">
                         <div class="col-md-5">
                              <div class="copy-to-clipboard" @click="copy()">
                                   <common-svgs style="margin: 0 auto;" type="copy-clipboard"></common-svgs>
                                   <span class="copy-clipboard-text">Copy to Clipboard</span>
                              </div>
                         </div>
                    </div>
                    <div class="mt-40">
                         <button class="btn continue-btn w-100 fs-18" data-bs-toggle="modal" data-bs-target="#accountCreated"
                              @click="acceptStatusStudent()">Continue</button>
                    </div>
               </div>
          </div>
     </div>
</template>

<style scoped>
.parent_data {
     margin-top: 24px !important;
}

.select_child_heading {
     color: #212121 !important;
     font-size: 20px !important;
     line-height: 28px !important;
}

.profile {
     color: #717171 !important;
     font-size: 14px !important;
     margin-top: 8px !important;
}

.modal-header {
     border-bottom: none !important;
}

.modal-button-close {
     /* position: absolute;
    right: 20px;
    top: 20px; */
     padding: 10px !important;
     border-radius: 12px;
     border: 1px solid rgba(77, 77, 77, 0.2);
     margin-top: -80px !important;
     margin-right: -20px !important;
}

.modal-button-close:hover svg,
.modal-button-close:hover path {
     stroke: var(--primary) !important;
}

/* .custom-back-btn {
  border-radius: 8px;
  padding: 6px 12px 6px 10px;
  gap: 8px;
  color: #9CA3AF;
} */
.student_modal_text {
     color: #717171;
     font-size: 16px;
}

#addAChildLabel {
     margin-bottom: 12px !important;
}

/* #addAChild .modal-content {
     width: 574px !important;
} */
.child_already_have_account {
     margin-top: 40px !important;
     background: #FAFAFA !important;
     /* background: rgba(36, 99, 235, 0.1); */
     color: #000000 !important;
     border-radius: 12px !important;
     border-color: snow;
     font-size: 16px !important;
     padding: 16px 12px !important;
}

.child_already_have_account:hover,
.new_account_for_child:hover,
.skip_for_now:hover {
     background: rgba(36, 99, 235, 0.1) !important;
}

.new_account_for_child {
     margin-top: 16px !important;
     color: #000000;
     font-size: 16px;
     background-color: #FAFAFA;
     border-color: snow;
     border-radius: 12px;
     padding: 16px 12px !important;
     margin-bottom: 8px !important;
}

.skip_for_now {
     border-radius: 12px;
     border-color: snow;
     background-color: #FFFFFF;
     color: #2463EB;
     padding: 16px 12px !important;
     margin-top: 8px !important;
}

.back_btn {
     color: #9CA3AF;
}

.info {
     margin-top: 40px !important;
}

.enter_username {
     color: #212121;
     font-size: 36px;
}

.enter_username_text {
     margin-top: 12px;
     color: #717171;
     font-size: 16px;
     margin-bottom: 32px !important;
}

.mt-32 {
     margin-top: 32px !important;
}

.username {
     margin-top: 24px !important;
}

.continue_btn {
     margin-top: 40px;
}

.account_created_div {
     background: linear-gradient(90deg, rgba(36, 99, 235, 0.08) 0%, rgba(255, 186, 0, 0.08) 100%);
     border-radius: 12px;
}

.inner_div {
     padding: 28px 16px !important;
}

.label {
     font-size: 14px !important;
     color: #89939E !important;
}

.content {
     font-size: 20px;
     color: #212121;
     margin-top: 8px !important;
}

.not_found_message {
     font-size: 11px;
     color: #E02B1D;
     margin-left: 12px !important;
     margin-right: 12px !important;
     margin-top: 10px !important;
}

.paginate-btn {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     padding: 0px;
     width: 24px;
     height: 24px;
     color: black;
     margin-left: 6px;
}

.paginate-btn:hover {
     color: black;
}

.main-paginate-div {
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: end;
     padding: 0px;
     gap: 20px;
     margin-top: 24px;
}

.left-arrow-btn {
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     padding: 8px;
     gap: 10px;
}

.paginate_btns {
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     padding: 0px;
     gap: 6px;
}

.modal-width-576 {
     max-width: 576px !important;
}

.continue-btn {
     background: #2463EB;
     color: #FFFFFF;
     padding: 16px 0px !important;
}

.continue-btn:hover {
     background: #1453DC;
}

.mt-40 {
     margin-top: 40px !important;
}

.fs-18 {
     font-size: 18px !important
}

.copy-to-clipboard {
     padding: 8px 16px;
     margin-top: 24px !important;
     border-radius: 8px !important;
     background: rgba(36, 99, 235, 0.10) !important;
     cursor: pointer;
}

.copy-clipboard-text {
     font-size: 14px !important;
     color: #2463EB !important;
     font-weight: 400 !important;
     margin-left: 8px !important;
}

/* Table Styling Start */
.cube {
     border-radius: 12px;
     padding: 20px !important;
     display: flex;
     gap: 16px;
}

.sub-cube1 {
     background: rgba(255, 186, 0, 0.07)
}

.sub-cube2 {
     background: rgba(0, 146, 98, 0.07);
}

.sub-cube3 {
     background: rgba(224, 43, 29, 0.07);
}

.icon-container {
     width: 40px;
     height: 40px;
     padding: 8px;
     background: white;
     border-radius: 8px;
}

.container-data {
     font-size: 16px;
     color: #212121;
}

table td,
table th {
     padding: 14px 16px !important;
     font-family: 'Inter';
     font-weight: 400;
     font-size: 14px;
     line-height: 17px;
     color: #6B7280;

}

table thead {
     background: #F5F7FA !important;
}

.table-text-color {
     color: #212121;
     font-size: 14px;
}

.first-cell-data {
     width: 60% !important;
}

.logo-text {
     display: flex;
     gap: 12px;
}

.tbody,
tfoot,
th,
thead,
tr {
     border-style: none !important;
}

.progress {
     height: 8px !important;
     margin-top: 4px !important;
}

.custom-toaster {
     display: flex;
     padding: 8px 12px;
     background: #fff;
     border-radius: 8px;
     border: 1px solid #E5E7EB;
     position: absolute;
     right: -270px !important;
}

.copied-to-clipboard-text {
     font-size: 12px;
     margin-right: 48px !important;
     color: #6B7280;
     font-weight: 600;
     margin-top: 7px;
}

.found-child-dialog {
     max-width: 604px !important;
}

.enter_username {
     line-height: 44px;
}

/* ===**************** Media Queries ****************=== */
@media (max-width: 915px) {
     .coursestbl {
          display: block;
          overflow: auto;
          padding: 0px !important;
     }

     .logo-text {
          width: 280px;
     }

     .datecolumn {
          display: block;
     }

     .cube {
          margin-bottom: 8px;
     }
     .hidden-xs{
          display:none;
     }
     .display-xs{
          
          display:block !important;
     }
}
/* Table Styling End */</style>