<template>
  <div class="card">
    <div class="card-body p-0">
      <div class="row m-0">
        <div class="col-md-12" :class="($attrs.avatarImg) ? 'withimg' : 'withoutimg'"
          style="position: relative;overflow: hidden;">
          <div class="row">
            <div class="col-md-1 col-3" v-if="$attrs.avatarImg">

              <img v-if="$attrs.avatarImg" :src="fileUrl + $attrs.avatarImg"
                :onerror="`this.src = '${assetUrl}files/assets/img/placeholder.svg'`" class="img-fluid"
                style="width: 50px;height: 50px;margin-right:20px;" />
            </div>
            <div class="col-md-7 text-left col-9">
              <span v-if="$attrs.pretitle" class="display-3" style="vertical-align: middle;">{{ $attrs.pretitle }}</span>
              <span class="display-3" style="font-weight: 400;vertical-align: middle;">{{ $attrs.title }}</span>

            </div>
            <div class="col-md-4 col-2">
              <img :src="$attrs.bgimg" class="bgimg" />
            </div>
          </div>
          <div class="row" v-if="$attrs.short_desc">
            <div class="col-md-10" style="margin-top: 12px !important;">
              <p style="font-size: 14px !important;margin-bottom: 0px !important" :short_desc="$attrs.short_desc">{{
                $attrs.short_desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, ref, onMounted } from "vue";
import { appConsts } from "@/common/constants.js";


var fileUrl = ref(appConsts.fileBaseUrl);
var assetUrl = ref(appConsts.assetBaseUrl);

var emits = defineEmits(['back-btn-clicked']);

function backBtnClicked() {
  emits('back-btn-clicked');
}

</script>
<style scoped>
/* Form Card CSS Start */
.withoutimg {
  padding: 24px 32px;
}

.withimg {
  padding: 17px 24px;
}

.card {
  background: #FFFFFF;

  box-shadow: 0px 15px 30px rgba(186, 186, 186, 0.1);
  border-radius: 12px;
  ;
}

.custom-back-btn:hover {
  background: #D0DEFB;
  color: var(--primary);
}

.custom-back-btn {
  border-radius: 8px;
  padding: 6px 12px 6px 10px;
  gap: 8px;
  color: #9CA3AF;
}

.card-body .bgimg {
  position: absolute;
  right: 0px;
  top: 0px;
  min-height: 100%;
}

/* Form Card CSS End */
/* ===**************** Media Queries ****************=== */
@media (max-width: 915px) {
  .card-body .bgimg {
    opacity: 0.1;
  }
}

/* bgimg */
</style>
