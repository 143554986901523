<template>
  <div class="container" style="padding-top: 170px;">

  </div>
</template>

<script setup>
import { ref } from 'vue';
import factory from '@/common/factory.js';
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";


const auth = useAuthStore();

window.localStorage.clear();
auth.token = null;
auth.userinfo = {};
    window.location.href = auth.baseUrl + "dashboard";

</script>