<script setup>

import { ref, reactive } from 'vue';
import factory from '@/common/factory.js';
import { useRoute, useRouter } from 'vue-router';
import { v4 as uuid } from 'uuid';
import CardBox from "@/components/CardBox.vue";

const route = useRoute();
const router = useRouter();



function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
}


</script>

<template>
     <card-box :noPadding="true" class="p-0" :minimalpadding="true" style="margin-top: 24px !important">
          <center style="margin-bottom: 20px !important;"><h3>Student Bulk Import</h3></center>
          <div class="row m-0 d-flex justify-content-between">
               <div class="col-md-6">
                    <input type="file" class="form-control excel_file" @change="uploadFile" required>
               </div>
               <div class="col-md-2">
                    <button class="btn btn-primary" @click="bulkImportStudents()">Import</button>
               </div>
          </div>          
     </card-box>
</template>
<style>

     .hidden{
          display: none;
     }
   .description {
     display: flex;
     flex-direction: row;
     align-items: center;
     padding: 0px;
     column-gap: 24px;
     color: #8E8E8E;
     font-weight: 400;
     font-size: 14px;
     line-height: 160%;
   }
   
   .filefeild {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     padding: 40px 16px;
     border: 1px dashed #8E8E8E;
     max-height: 70px;
   }
   .filefeild[data-fileuploaded=true]{
     border: 1px dashed var(--success);
   
   }
   
   .helpertext {
     color: #8E8E8E;
     letter-spacing: 0.5px;
     font-size: 10px;
   }
   </style>