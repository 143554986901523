<template>
  <div class="container-fluid">
    <div class="text-portion">
      <h1>How can we help?</h1>
      <p>Welcome to our Help Center! Search for topics here or contact us.</p>
    </div>

    <div>
      <CommonSvgs style="margin:77px 10px;position:absolute" type="search-icon"></CommonSvgs>
      <input :value="input" class="search-bar input w-100" @input="onInputChange" @focus="onInputFocus"
        @blur="onInputBlur" @keyup.enter="onEnterKey" placeholder="Search Topic">
    </div>



    <div  v-if="showDefaultOptions && filteredData && filteredData.length > 0 " class="search-default-options">
      <div v-for="(data, index) in filteredData" :key="index" >
          
            <router-link :to="data.path+'?id='+data.id" >
           <div  class="mt-3 mb-3" :class="{ 'border-bottom': index !== filteredData.length - 1 }">
            <p class="s-d-o-h5" v-html="data.question.replace(new RegExp(input, 'gi'), '<b style=\'font-weight:600\'>' + input + '</b>')"></p>


           <p class="s-d-o-p">{{data["path-title"]}}</p>
           </div>
        
      </router-link>
           
    </div>
    </div>


  </div>
</template>

<script >

import CommonSvgs from "@/components/CommonSvgs.vue";
var showDefaultOptions = false;
import jsonData from "@/static/help-content.json";

export default {
  data() {
    return {
      jsonData: jsonData,
      input: this.$route.query.keyword,
      showDefaultOptions: false,
      filteredData: [],

    };

  },
  props: {
  },
  components: {
    CommonSvgs,
  },
  methods: {
    onEnterKey(input) {
      this.input = input.target.value;
      this.showDefaultOptions = false;
      if (this.input) {
        this.$router.push({ path: '/account-help-s/search', query: { keyword: this.input } });
      } else {
        this.$router.push({ path: '/account-help' });
      }
    },
    onInputChange(event) {
      event.preventDefault();
      this.input = event.target.value;
      if (event.target.value != "") {
        this.showDefaultOptions = true;
        this.search_(this.jsonData,this.input)
      }
      if (event.target.value == "") {
        this.showDefaultOptions = false;

        this.filteredData = []
      }

      
    },
    onInputFocus(event) {
    this.showDefaultOptions = true;
      event.preventDefault();
      if (this.input === "" || this.input == undefined) {
        this.showDefaultOptions = true;
      }
   },
    onInputBlur(event) {
      event.preventDefault();
      setTimeout(() => {
      this.showDefaultOptions = false;
      }, 200);
         
  },
    search_(jsonData, search) {
      const categories = jsonData.student.concat(
        jsonData.parent,
        jsonData.school
      );
      const filteredData = [];

      for (const category of categories) {
        for (const dataItem of category.data) {
          
          if (
            dataItem?.question?.toLowerCase().includes(search?.toLowerCase())
          ) {
            dataItem["path"] = category["path"];
            dataItem["path-title"] = category["path-title"];
            filteredData.push(dataItem);
          }
        }
      }

      this.filteredData = filteredData.slice(0, 4);
     
   
    },
  }
};
</script>

<style scoped>
.text-portion{

}

.border-bottom {
  border-bottom: 0.6px solid #abbed1;
}

hr {
  width: 90%;
  color: #ABBED166;
}

.search-default-options {
  width: 519px !important;
  height: auto;
  z-index: 99999;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
  padding: 18px 12px 18px 36px;
  border-radius: 8px;
  border: 0.5px;
  gap: 8px;
  background: white;
  box-shadow: none

}



.s-d-o-h5 {
  font-family: Apercu;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  font-family: Apercu;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

}

.s-d-o-p {
  font-family: Apercu;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #89939E;

}

input:focus {
  outline: none;
}

.container-fluid {
  width: 100%;
  min-height: 450px;
  height: 100%;
  align-items: center;
  margin-top: 7px;
  padding-top: 130px;
  padding-bottom: 100px;
  background-image: url('../../../public/img/banner.svg');
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}



h1 {
  font-family: Apercu;
  font-size: 48px;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;

}

.search-bar {
  width: 519px !important;
  height: 48px;
  margin-top: 60px;
  padding: 18px 12px 18px 36px;
  border-radius: 8px;
  border: 0.5px;
  gap: 8px;

}

::placeholder {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #9CA3AF;


}

p {

  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: #4D4D4D;
  margin-top: 10px;
  margin-bottom: 10px;



}
</style>
