<script setup>
import SearchInput from '@/components/SearchInput.vue';
import HeadingCard from "@/components/HeadingCard.vue";

import CardBox from '@/components/CardBox.vue';
import Paginate from '@/components/Paginate.vue';
import CommonSvgs from '@/components/CommonSvgs.vue';
import { useAuthStore } from "@/store/auth.js";
import { ref, onMounted } from 'vue';
import { functions } from '@/common/functions';
import SingleStudent from '../student/SingleStudent.vue';
import factory from "@/common/factory.js";

const auth = useAuthStore();

var dropdownopen = ref(false);

var filterData = ref('');
var currentpage = ref(1);

function getToken() {
    const token = {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.token,
        },
    };
    return token;
}
function toggleSvgs() {
    if (dropdownopen.value) {
        dropdownopen.value = false;
    } else {
        dropdownopen.value = true;
    }
}

function openModal() {
    $('#singleStudentModal').modal('show');
}

function openImportModal() {
    $('#import_students').modal('show');
    let supported_file_desc = document.getElementsByClassName('uploadportion-desc')[0];
    supported_file_desc.innerHTML = "Supported formats: csv";

}

var importbtn = ref(true);

function importFileSelected() {
    importbtn.value = false;
    let file_name = $('#realinputfile').val();
    checkFileFormat(file_name);
    let split_file_name = file_name.split("\\");
    let supported_file_desc = document.getElementsByClassName('uploadportion-desc')[0];
    file_name != '' ? supported_file_desc.innerHTML = split_file_name[2] : supported_file_desc.innerHTML = "Supported formats: csv";
}

function checkFileFormat(file_name) {
    let format = file_name.split('.')[1];
    let wrong_format = document.getElementsByClassName('wrong-format')[0];
    if (format != 'csv' && format != 'xlsx') {
        importbtn.value = true;
        // console.log("no csv and xlsx");
        wrong_format.style.display = 'block';
        return false;
    }
    else {
        wrong_format.style.display = 'none';
        importbtn.value = false;
    }
}

async function importData() {
    var file = $('.uploadfilefield')[0].files[0];
    var formData = new FormData();
    formData.append('name', file);

    const apiCall = new factory(getToken(), 'student-bulk-import', formData);
    await apiCall.postForm().then((response) => {
        $('#import_students').modal('hide');
        $('#dropdownMenuButton1').click();
        importbtn.value = true;
        $('#realinputfile').val('');
        // swal.close();
        // console.log("res",response);
        // console.log('status',response);
        if (response.data.status) {
            getImportInfo();
            schoolStudents();
        }
    });
}

var importinfo = ref([]);
async function getImportInfo() {
    const apiCall = new factory(getToken(), 'import-info');
    await apiCall.getHttp().then((response) => {
        swal.close();
        if (response.data.status) {
            // console.log("import infoo",response.data);
            importinfo.value = response.data.data;
        }
    });
}

function filterClass() {
    var searchvalue = $('.filinput').find('input').val();
    filterData.value = searchvalue;
    currentpage.value = 1;
    schoolStudents();
}

var isStudents = ref(true);
var students = ref('');
var totalRecords = ref(0);
var currentpage = ref(1);
var filterData = ref('');

async function schoolStudents() {
    var filters = {
        page: currentpage.value,
        filter: filterData.value,
    };
    const schoolStd = new factory(getToken(), 'school-students', filters);
    await schoolStd.getHttp().then((response) => {
        swal.close();
        //   console.log(response)
        if (response.data.data.total > 0) {
            isStudents.value = true;
            totalRecords.value = response.data.data.total;
            students.value = response.data.data.data;
        }
        else {
            isStudents.value = false;
        }
    });
}

function returnProgress(completed, total) {
    if (completed > 0 && total > 0) {
        return parseInt((completed / total) * 100);
    }
    return 0;
}
onMounted(() => {
    schoolStudents();
});

function BackPage() {
    currentpage.value = currentpage.value - 1;
    schoolStudents();
}

function ForwardPage() {
    currentpage.value = currentpage.value + 1;
    schoolStudents();
}
function pageNoClicked(pageno) {
    currentpage.value = pageno;
    schoolStudents();
}
function hideModal() {
    schoolStudents();
    $('#singleStudentModal').modal('hide');
}

var ups_details = ref(false);
function dispHideUpsDetail() {
    getImportInfo();
    if (ups_details.value) {
        ups_details.value = false;
    } else {
        ups_details.value = true;
    }
}

async function hideImport(id) {
    var filter = {
        id: id
    }
    const apiCall = new factory(getToken(), 'hide-import-info', filter);
    await apiCall.getHttp().then((response) => {
        swal.close();
        if (response) {
            getImportInfo();
        }
    });
}
</script>

<template>
    <div class="modal fade" id="singleStudentModal" aria-labelledby="singleStudentModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
                <div class="modal-header p-0">
                    <h2 class="modal-title" id="singleStudentModalLabel">Add a Student</h2>
                    <button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <p class="student_modal_text">Build learning for today, tomorrow, and beyond. </p>
                <single-student @form-submit="hideModal()"></single-student>
            </div>
        </div>
    </div>
    <div class="modal fade" id="import_students" aria-labelledby="singleStudentModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md" style="min-width: 570px;">
            <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
                <div class="modal-header p-0">
                    <h2 class="modal-title" id="singleStudentModalLabel">Add Students</h2>
                    <button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <p class="student_modal_text" style="margin-bottom: 40px;">Build learning for today, tomorrow, and beyond.
                </p>
                <div class="modal-body p-0" style="display:flex;gap:40px;flex-direction: column;">
                    <div class="downloadtemplate">
                        <div class="downloadtemplate-text">
                            <h4>Download Template</h4>
                            <span class="btext-3">Create list of students in spreadsheet and use existing class
                                names.</span>
                        </div>
                        <a href="SampleFile.xlsx" download>
                            <button class="btn btn-clean btn-clean-primary col-md-6" style="border-radius: 4px;">Download
                                Template</button>
                        </a>
                    </div>
                    <div class="uploadportion" id="file-drop-area">
                        <div class="uploadportion-text">Drag and drop, or <span class="text-primary clicknearfile"
                                style="cursor: pointer;">click to browse</span></div>
                        <div class="uploadportion-desc">Supported formats: csv</div>
                        <p class="text-danger mb-0 wrong-format" style="font-size: 12px; display: none">
                            <common-svgs type="not-found"></common-svgs>
                            Invalid Format
                        </p>
                        <input name="file" type="file" class="realinputfile uploadfilefield" id="realinputfile"
                            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="importFileSelected" style="display: none;" />
                    </div>

                    <button class="btn btn-primary" @click="importData" :disabled="importbtn"
                        style="padding: 16px 32px;">Continue</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12" style="margin-bottom: 24px !important;">
        <heading-card :avatarImg="auth.userinfo.file_name" pretitle="Welcome " :title="auth.userinfo.full_name"
            :bgimg="auth.baseUrl + 'files/assets/img/cardbg.webp'"></heading-card>
    </div>
    <div class="row">
        <div class="col-md-6">
            <h4>All Students({{ totalRecords }})</h4>
        </div>
        <div class="col-md-6 text-right">
            <div class="dropdown">
                <button class="btn btn-primary dropdown-toggle avoidclosing dropdownbtn" @click="toggleSvgs(event)"
                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <common-svgs v-if="dropdownopen" type="minus-icon" class="minus-icon"></common-svgs>
                    <common-svgs v-else type="plus-icon" class="plus-icon"></common-svgs>
                    Add Student
                </button>
                <ul class="dropdown-menu dropdownbtndata" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="#" @click="openModal">Single Student</a></li>
                    <li><a class="dropdown-item" href="#" @click="openImportModal">Multiple Students</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-md-12 mt-3">
        <div class="uploadprogress" v-for="importdata in importinfo" style="margin-bottom: 10px;">
            <div class="uploadprogresssection">
                <div class="ups_fileinfo">
                    <common-svgs v-if="importdata.job_status != 1" :type="'clouudpload'"></common-svgs>
                    <common-svgs v-else :type="'import-complete'"></common-svgs>
                    <div class="ups_fileinfotext">
                        <span class="ups_fileinfo_file" style="width:100%;">{{ importdata.file_name }}</span>
                        <span v-if="importdata.job_status != 1" class="ups_fileinfo_status" style="width:100%;">In
                            Progress</span>
                        <span v-else-if="importdata.job_status == 1" class="ups_fileinfo_status text-success"
                            style="width:100%;">Completed</span>
                    </div>
                </div>
                <div v-if="importdata.job_status != 1" class="ups_recordinfo" @click="dispHideUpsDetail">
                    <span class="ups_recordinfo_text">
                        Total Students: {{ importdata.total_records }}
                    </span>
                    <span class="ups_recordinfo_icon">
                        <common-svgs v-if="ups_details" :type="'arrow-up'" :width="22" :height="22"></common-svgs>
                        <common-svgs v-if="!ups_details" :type="'arrow-down'" :width="22" :height="22"></common-svgs>
                    </span>
                </div>
                <div v-else class="ups_recordinfo">
                    <span class="ups_recordinfo_completetext">
                        <span class="ups_recordinfo_text">
                            Uploaded: {{ importdata.successfull }}/{{ importdata.total_records }}
                        </span>
                        <span class="ups_recordinfo_text text-danger">
                            Failed: {{ importdata.failed }}/{{ importdata.total_records }}
                        </span>
                    </span>
                    <span class="ups_recordinfo_icon">
                        <button type="button" class="btn ups_btn_remove" @click="hideImport(importdata.id)">
                            <common-svgs :type="'cross'"></common-svgs>
                        </button>

                    </span>
                </div>

            </div>
            <div v-if="ups_details && importdata.job_status != 1" class="ups_details">
                <ul style="display: -webkit-inline-box;
            gap: 30px;">
                    <li style="list-style-type: none;">Uploaded {{ importdata.successfull }} / {{ importdata.total_records
                    }}</li>
                    <li>Pending {{ ((importdata.failed + importdata.successfull) - importdata.total_records) }} / {{
                        importdata.total_records }}</li>
                    <li class="text-danger">Failed {{ importdata.failed }} / {{ importdata.total_records }}</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-md-12 mt-3">
        <!-- {{filterData}} -->
        <card-box :noPadding="true" class="p-0" :minimalpadding="true">
            <search-input noformgroup="true" class="col-md-6 filinput" placeholder="Search by username or phone number"
                @keyup.enter="filterClass($event)"></search-input>
            <div class="col-md-3">
                <button class="btn bg-fields-color btn-border" @click="filterClass($event)">
                    <common-svgs type="filter-icon"></common-svgs>
                    Filter
                </button>
            </div>

            <div class="col-md-12 pt-24 p-0">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 200px;">Student Name</th>
                            <th>Username</th>
                            <th>Phone</th>
                            <th style="width: 200px;">Overall Progress</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="class-box" v-if="isStudents" v-for="student in students" :key="student.id">
                            <td class="class-heading">{{ student.full_name ?? '--' }}</td>
                            <td>{{ student.username ?? '--' }}</td>
                            <td>{{ student.phone1 ?? '--' }}</td>
                            <td>
                                <div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar"
                                            :style="{ width: returnProgress(student.completedTopics, student.totalTopics) + '%' }"
                                            :aria-valuenow="returnProgress(student.completedTopics, student.totalTopics)"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div style="text-align: start;margin-top: 8px !important;">
                                        <span>{{ returnProgress(student.completedTopics, student.totalTopics) }}%
                                            Complete</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <router-link v-if="isStudents" class="col-md-3"
                                    :to="{ name: 'student-details', params: { id: student.student_id } }">
                                    <button type="button" class="btn view-btn" @click="goBack">
                                        View
                                    </button>
                                </router-link>

                            </td>
                        </tr>
                        <tr v-else>
                            <td colspan="4">
                                <div class="no-search-student-margin">
                                    <center>
                                        <common-svgs type="no-search-student-found-icon"></common-svgs>
                                        <p class="no-search-student-found">Please search for a student to appear here</p>
                                    </center>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="col-md-12"> -->

            <!-- <Paginate @back-btn="BackPage" @forward-btn="ForwardPage" :total="totalRecords" :perpage="8"
                         :currentpage="currentpage" @page-btn-clicked="pageNoClicked"></Paginate> -->
            <!-- </div> -->

            <!-- <div class="no-search-student-margin">
                    <center>
                         <common-svgs type="no-search-student-found-icon"></common-svgs>
                         <p class="no-search-student-found">Please search for a student to appear here</p>
                    </center>
               </div> -->

        </card-box>
    </div>
    <div class="col-md-12">
        <Paginate @back-btn="BackPage" @forward-btn="ForwardPage" :total="totalRecords" :perpage="8" :currentpage="currentpage"
            @page-btn-clicked="pageNoClicked"></Paginate>
    </div>
</template>

<style scoped>
.no-search-student-margin {
    margin: 85px 10px;
}

.btn-border {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    color: #717171;
}

.bg-fields-color {
    background-color: #F9FAFB;
}

.forword-backword-btn {
    border: none;
    background-color: ghostwhite;
}

.bg-fields-color {
    background-color: #F9FAFB;
}

.bg-button-color {
    background-color: #E5E7EB;
}

.btn-border {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    color: #717171;
}

.paginate-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 24px;
    height: 24px;
    color: black;
    margin-left: 6px;
}

.paginate-btn:hover {
    color: black;
}

.main-paginate-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 0px;
    gap: 20px;
    margin-top: 24px;
}

.left-arrow-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
}

.paginate_btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 6px;
}

.circle_content {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #FFFFFF;
    width: 40px;
    height: 40px;
    border-radius: 37px;
    display: flex;
    justify-content: center;
}

.gray {
    color: #717171;
    font-size: 14px;
    margin-bottom: 0px !important;
}

.circle {
    display: flex;
    justify-content: center;
}

.pb-24 {
    padding-bottom: 24px !important;
}

.pt-24 {
    padding-top: 24px !important;
}

.custom-back-btn:hover {
    background: #D0DEFB;
    color: var(--primary);
}

.custom-back-btn {
    border-radius: 8px;
    padding: 6px 12px 6px 10px;
    column-gap: 8px;
    color: #9CA3AF;
}

.bt-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
}

.btn-border {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    color: #717171;
}

.bg-fields-color {
    background-color: #F9FAFB;
}

/* 
table td,
table th {
    padding: 18px 0px !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

}
table thead {
      border-width: 0.5px 0px;
      border-style: solid;
      border-color: rgba(171, 190, 209, 0.6);
      background: #F5F7FA !important;
} */


table td,
table th {
    padding: 17px 40px !important;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #212121;

}

table td {
    color: #4D4D4D !important;
}

table thead {
    background: #F5F7FA !important;
}

.table-text-color {
    color: #212121;
    font-size: 14px;
}

.first-cell-data {
    width: 60% !important;
}

.logo-text {
    display: flex;
    gap: 12px;
}

.tbody,
tfoot,
th,
thead,
tr {
    border-style: none !important;
}

.view-btn {
    color: #2463EB;
    background: rgba(36, 99, 235, 0.05);
    border-radius: 8px;
}

.progress {
    height: 8px !important;
}

.modal-header {
    border-bottom: none !important;
    /* padding: 1rem !important; */
    /* padding-bottom: 12px !important; */
    /* padding-left: 48px !important; */
}

.student_modal_text {
    color: #717171;
    font-size: 16px;
}

.modal-button-close {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 14px !important;
    border-radius: 12px;
    border: 1px solid rgba(77, 77, 77, 0.2);
}</style>