<template>
  <div :class="$attrs.class">
    <div class="mb-3" :class="((label && modelValue) ? ' select-label-input ' : ' ')" style="position:relative;">
      <label v-if="label && modelValue" class="form-label" :for="id">{{ label }}</label>
      <select :data-placeholder="$attrs.placeholder" :id="id" :value="modelValue" v-bind="{ ...$attrs, class: null }"
        class="form-select select2" @input="$emit('update:modelValue', $event.target.value)">
        <option v-if="$attrs.placeholder" value="" selected disabled hidden>{{ $attrs.placeholder }}</option>
        <slot />
      </select>
      <div v-if="(typeof error == 'object' && error)" class="form-error"><span class="error-icon"></span> {{ error[0] }}</div>
      <div v-if="(typeof error != 'object' && error)" class="form-error"><span class="error-icon"></span> {{ error }}</div>
    </div>
  </div>
</template>
 
<script>
import { v4 as uuid } from 'uuid';
import { select2 } from 'select2';
import { Alert } from 'bootstrap';

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${uuid()}`
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    error: String,
    label: String,
    isModel: Boolean,
    nosearch: {
      Boolean, default() {
        return false
      },
    },

    modelValue: [String, Number],
  },
  emits: ['update:modelValue', 'do-change'],
  mounted() {
    var vm = this;
    let select = $('#' + this.id)
    // if (this.prop.isModel) {
    //   $('#' + this.id).select2().on('change', function () {
    //     vm.$emit('update:modelValue', select.val());
    //     vm.$emit('do-change');
    //   });
    // } else {
    if (this.nosearch) { // without search 
      $('#' + this.id).select2({
        minimumResultsForSearch: Infinity,
        dropdownParent: $('#' + this.id).parent(), matcher: function (params, data) {
          return matchStart(params, data);
        }
      }).on('change', function () {
        vm.$emit('update:modelValue', select.val());
        vm.$emit('do-change');
      });
    } else {
      $('#' + this.id).select2({
        dropdownParent: $('#' + this.id).parent(), matcher: function (params, data) {
          return matchStart(params, data);
        }
      }).on('change', function () {
        vm.$emit('update:modelValue', select.val());
        vm.$emit('do-change');
      });
    }
    // }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
  },
}
</script>
<style scoped>
.select2-container .select2-selection {
  background-color: rgba(137, 147, 158, 0.05) !important;
  border: 0.5px solid #ABBED1;
  border-radius: 12px;
  /* height: 48px; */
  padding: 12px 20px !important;
  font-family: var(--def-fontfamily) !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  color: #4D4D4D !important;
}

.label-input {
  padding: 22px 12px 14px 16px !important;
}

option {
  font-family: var(--def-fontfamily);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #4D4D4D;
}

label {
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* padding-left: 20px; */
  padding-left: 17px;
  padding-top: 8px;
  color: #4D4D4D;
  z-index: 1;
}
.form-error {
  padding-top: 9px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #E02B1D;
}

.error-icon {
  content: "";
  background-image: url('/public/files/assets/img/error.webp');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  padding-right: 20px;

}
</style>
