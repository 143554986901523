<script setup>
import { onMounted } from "vue"; 
import HeadingCard from "@/components/HeadingCard.vue";
import EmailVerifyWarning from "@/components/EmailVerifyWarning.vue";
import StudentDashboard from "@/views/student/StudentDashboard.vue"; 
import SchoolDashboard from "./school/SchoolDashboard.vue";
import ParentDashboard from "./parent/ParentDashboard.vue";
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";
// import { getMessaging, onMessage,firebaseApp } from "firebase/messaging";
// import { messaging, onMessage, getToken } from '@/common/firebase.js';
// import firebaseMessaging from '@/common/firebase'

const auth = useAuthStore();
const router = useRouter();
// onMounted(async () => {

//   await getToken(messaging).then((token) => {
//     console.log(token)
//   });

// });
// onMessage(messaging, (payload) => {
//   console.log("Message received. ", payload);
//   // ...
// }); 
// onMessage(getMessaging(), (payload) => {
//     console.log("Message received. ", payload);
    // notification.title = payload.notification.title
    // notification.body = payload.notification.body
  // });
  // this.$messaging.onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);

  // })



</script> 

<template>
  <div class="row">
    <div v-if="(auth.userinfo.type=='Student' || auth.userinfo.type=='Parent') & auth.userinfo.email_verification == 0" class="col-md-12" style="margin-bottom: 24px !important;">
      <email-verify-warning :type="(auth.userinfo.email)?'emailexsist':'noemail'" ></email-verify-warning>
    </div>
    <div class="col-md-12" style="margin-bottom: 24px !important;">
      <heading-card v-if="auth.userinfo.type=='Student'"  :avatarImg="auth.userinfo.school_logo"  :title="auth.userinfo.school_name" :bgimg="auth.baseUrl+'files/assets/img/home-headerbg.webp'"></heading-card>
      <heading-card v-else-if="auth.userinfo.type == 'School'" :avatarImg="auth.userinfo.file_name" pretitle="Welcome "  :title="auth.userinfo.full_name" :bgimg="auth.baseUrl+'files/assets/img/cardbg.webp'"></heading-card>
      <heading-card v-else-if="auth.userinfo.type == 'Parent'" pretitle="Welcome " :avatarImg="auth.userinfo.file_name" :short_desc = "'These are your children’s accounts. Click your child’s name to view their progress.'" :title="auth.userinfo.full_name" :bgimg="auth.baseUrl+'files/assets/img/parent_bg.jpg'"></heading-card>
    </div>
    <student-dashboard v-if="auth.userinfo.type == 'Student'"></student-dashboard>
    <school-dashboard v-if="auth.userinfo.type == 'School'"></school-dashboard>
    <parent-dashboard v-if="auth.userinfo.type == 'Parent'"></parent-dashboard>
  </div>
</template>
  
