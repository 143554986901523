<template>
  <div class="mb-3">
    <div class="filefeild mb-2" :data-fileuploaded="fileuploded" :class="id" @click="selectFile">
      <div class="description" v-if="!fileuploded || modelValue == 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
            stroke="#737B7D" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M17 8L12 3L7 8" stroke="#737B7D" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 3V15" stroke="#737B7D" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Attactment (optional)
      </div>
      <div class="description onupload" v-else>
        <i class="fa fa-check text-success" style="font-size: 24px;"></i>
        <span class="uploadedfilename text-success">{{ singleUploadedFileName }}</span>
      </div>
    </div>
    <small class="helpertext">Attach file. File size of your documents should not exceed 10MB</small>
    <input type="file" class="hidden" :class="'inputfile' + id" @change="uploadFile($event)" />
  </div>
</template>
<script >
import { v4 as uuid } from 'uuid';
import factory from '@/common/factory.js';
// filefeild

export default {
  inheritAttrs: false,
  data() {
    return {
      token: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
      fileuploded:false,
      singleUploadedFileName:''
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return `file-input-${uuid()}`
      },
    },
    modelValue: [String,Number],
  },
  methods: {
    selectFile() {
      $('.inputfile' + this.id).click();
    },
    async uploadFile(event) {
      // $(e.target)
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('attachment', file);

      const AttachmentUpload = new factory(this.token, 'attachment', formData);
      await AttachmentUpload.postForm().then((response) => {
        if (response.data.status) {
          swal.close();
          this.fileuploded = true;
          this.singleUploadedFileName = response.data.data.title;
           
          this.$emit('update:modelValue',response.data.data.id)
        } else {
          console.log(response.data.message);
        }
      })
    }
  },
}
</script>
<style scoped>
.hidden{
  display: none;
}
.description {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  column-gap: 24px;
  color: #8E8E8E;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
}

.filefeild {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 16px;
  border: 1px dashed #8E8E8E;
  max-height: 70px;
  cursor: pointer;
}
.filefeild[data-fileuploaded=true]{
  border: 1px dashed var(--success);

}

.helpertext {
  color: #8E8E8E;
  letter-spacing: 0.5px;
  font-size: 10px;
}
</style>
