<template>
  <div class="container" style="padding-top: 170px;">
    <div class="row mb-5">
      <div class="col-md-6 offset-md-3">
        <card-box class=" SubmitOnEnter" title="Forgot Password"
          shortdesc="Please enter your user_name you used when you signed up for Alternates account." backtitle="Back"
          @back-btn-clicked="stepOneBack">
          <text-input class="col-md-12" v-model="user_name" @change="validateBtn" @keyup="validateBtn"
            placeholder="Username"></text-input>
          <div class="col-md-12 mb-3">
            <button class="btn btn-primary btn-block submitItBtn" :disabled="isValid" @click="forgotPassword">Send
              Link</button>
          </div>
        </card-box>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router'

import factory from '@/common/factory.js';
import TextInput from "@/components/TextInput.vue";
import CardBox from "@/components/CardBox.vue";

const router = useRouter()
var isValid = ref(true);
var user_name = ref("");

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
}

async function forgotPassword() {
  swal.fire({
    title: "Loading....",
    text: "Please Wait, This may take a while...",
    iconHtml: `<div class="loader-block">
      <svg id="loader2" viewBox="0 0 100 100">
      <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
      </svg>
      </div>`,
    showConfirmButton: false,
  });
  isValid.value = true;
  var checkVer = {
    user_name: user_name.value,
  }
  const ForgotPassLink = new factory(getToken(), 'forgot-password', checkVer);
  ForgotPassLink.getHttp().then((response) => {
    swal.close()
    user_name.value = '';
    if (response.data.status) {
      if (response.data.message) {
        swal.fire('Done!', response.data.message, 'success');
      } else {

        swal.fire('Done!', 'Link sent to you number.', 'success')
      }
    } else {
      if (response.data.message) {
        swal.fire('Done!', response.data.message, 'error');
      } else {
        swal.fire('Error!', 'Something went wrong... retry again!', 'error')
      }
    }
  });
};
function validateBtn() {
  if (user_name.value) {
    isValid.value = false;
  } else {
    isValid.value = true;
  }
}

function stepOneBack() {
  router.push('/login');
}
</script>