<script setup>
import CardBox from '@/components/CardBox.vue';
import CommonSvgs from '@/components/CommonSvgs.vue';
import factory from "@/common/factory.js";
import { useAuthStore } from "@/store/auth.js";
import { ref } from 'vue';
import { functions } from '@/common/functions';
import { useRoute } from "vue-router";
import { useRouter } from 'vue-router';
import SearchInput from '@/components/SearchInput.vue';
import SingleStudent from '../student/SingleStudent.vue';
import Paginate from '@/components/Paginate.vue'



const auth = useAuthStore();
const route = useRoute();
const router = useRouter();

const id = ref(route.params.id);
const current_class_id = ref(0);
const students = ref(0);
const courses = ref(0);
const shortName = ref('')
const total_courses = ref(0);
let students_array = ref([]);
const isStudents = ref(true);

var totalRecords = ref(1);
var currentpage = ref(1);
var filterData = ref('');


function getToken() {
      const token = {
            headers: {
                  // 'Content-Type': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded',
                  'Authorization': 'Bearer ' + auth.token,
            },
      };
      return token;
}

async function classStudents() {
      var class_id = {
            id: id.value,
            // var paginate = {
            page: currentpage.value,
            filter: filterData.value,
            //}
      };
      const clsStd = new factory(getToken(), 'class-students', class_id);
      await clsStd.getHttp().then((response) => {
            swal.close();
            // console.log(response.data.data.students);
            shortName.value = response.data.data.class.name;
            total_courses.value = response.data.data.class.total_courses;
            // console.log(response.data.data);
            if (response.data.data.students.data.length > 0) {
                  students_array.value = response.data.data.students.data;
                  students.value = response.data.data.students.total;
                  // courses.value = response.data.data.students.total;//dummy
                  isStudents.value = true;
                  totalRecords.value = response.data.data.students.total;

            }
            else {
                  isStudents.value = false;
            }
      });
}
classStudents();

function goBack() {
      router.push('/dashboard');
}

function openModal() {
      current_class_id.value = id.value;
      console.log("Open Modal", current_class_id.value);
      $('#singleStudentModal').modal('show');
}

function hideModal() {
      $('#singleStudentModal').modal('hide');
      classStudents();
      current_class_id.value = id.value;
      console.log("Modal Closed", current_class_id.value);
}

function BackPage() {
      currentpage.value = currentpage.value - 1;
      classStudents();
}

function ForwardPage() {
      currentpage.value = currentpage.value + 1;
      classStudents();
}
function pageNoClicked(pageno) {
      currentpage.value = pageno;
      classStudents();
}


async function studentReport() {
      var student_id = {
            id: id.value
      };
      const StdReport = new factory(getToken(), 'single-student-report', student_id);
      await StdReport.getHttp().then((response) => {
            swal.close();
            if (response.data.status) {
                  progress.value = response.data.data ? response.data.data.progress : [];
                  lastlog.value = response.data.data ? response.data.data.lastLog : '';
                  courses.value = response.data.data ? response.data.data.courses : [];
            }
      });
}
function filterStudents() {
      var searchvalue = $('.filinput').find('input').val();
      // if (searchvalue == '') {
      //       $(document).find('.class-box').show();
      // }
      // searchvalue = searchvalue.toUpperCase();
      // $(document).find('.class-box').each(function () {
      //       if (($(this).find('.class-heading').text().toUpperCase().includes(searchvalue))) {
      //             $(this).show() //show that
      //       } else {
      //             $(this).hide() //show that
      //       }
      // });
      filterData.value = searchvalue;
      currentpage.value = 1;
      classStudents();
}
function returnProgress(completed, total) {
      if (completed > 0 && total > 0) {
            return parseInt((completed/total)*100);
            
      }
      return 0;
}

</script>
<template>
      <div class="modal fade" id="singleStudentModal" aria-labelledby="singleStudentModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                  <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
                        <div class="modal-header p-0">
                              <h2 class="modal-title" id="singleStudentModalLabel">Add a Student</h2>
                              <button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <p class="student_modal_text">Build learning for today, tomorrow, and beyond. </p>
                        <single-student :current_class_id="id" @form-submit="hideModal()"></single-student>
                  </div>
            </div>
      </div>

      <div class="pb-24">
            <button type="button" class="btn custom-back-btn" @click="goBack">
                  <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                  Back to Home
            </button>
      </div>
      <div class="row">
            <div class="col-md-12 p-0">
                  <card-box :noPadding="true" class="p-0" :minimalpadding="true">
                        <div class="col-md-1 p-0">
                              <div class="circle">
                                    <span class="circle_content" :class="'bg-primary'">{{
                                          functions.shortNameGenerator(shortName) }}</span>
                              </div>
                        </div>
                        <div class="col-md-2 p-0">
                              <h4 class="mb-0">{{ shortName }}</h4>
                              <p class="gray">{{ students }} Students</p>
                              <p class="gray"> {{ total_courses }} Courses</p>
                        </div>
                  </card-box>
            </div>
      </div>
      <div class="row bt-28">
            <div class="col-md-6 p-0">
                  <h4 class="">All Students ({{ students }}) </h4>
            </div>
            <div class="col-md-6 text-right p-0">
                  <button class="btn btn-primary" @click="openModal">
                        <common-svgs type="plus-icon"></common-svgs>
                        Add Student
                  </button>
            </div>
      </div>

      <div class="row">
            <div class="col-md-12">
                  <card-box :noPadding="true" class="p-0" :minimalpadding="true" :cardBodyStyle="'padding:0px'"
                        :cardBodyRow="'margin:0px'">
                        <template v-slot:cardHeader>
                              <div class="card-head" style="padding:24px 32px">
                                    <div class="row">
                                          <search-input noformgroup="true" class="col-md-6 filinput"
                                                placeholder="Find Student"></search-input>
                                          <div class="col-md-3 p-0">
                                                <button class="btn bg-fields-color btn-border"
                                                      @click="filterStudents($event)">
                                                      <common-svgs type="filter-icon"></common-svgs>
                                                      Filter
                                                </button>
                                          </div>
                                    </div>
                              </div>
                        </template>
                        <div class="col-md-12 pt-24 p-0">
                              <table class="table">
                                    <thead>
                                          <tr>
                                                <th style="width: 200px;">Student Name</th>
                                                <th>Username</th>
                                                <th>Phone</th>
                                                <th style="width: 200px;">Overall Progress</th>
                                                <th></th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          <tr class="class-box" v-if="isStudents" v-for="student_arr in students_array"
                                                :key="student_arr.id">
                                                <td class="class-heading">{{ student_arr.full_name ?? '--' }}</td>
                                                <td>{{ student_arr.users.username ?? '--' }}</td>
                                                <td>{{ student_arr.phone1 ?? '--' }}</td>
                                                <td>
                                                      <div> 
                                                            <div class="progress">
                                                                  <div class="progress-bar" role="progressbar"
                                                                        :style="{width:returnProgress(student_arr.completedTopics,student_arr.totalTopics)+'%'}" :aria-valuenow="returnProgress(student_arr.completedTopics,student_arr.totalTopics)" aria-valuemin="0"
                                                                        aria-valuemax="100"></div>
                                                            </div>
                                                            <div style="text-align: start;margin-top: 8px !important;">
                                                                  <span>{{returnProgress(student_arr.completedTopics,student_arr.totalTopics) }}% Complete</span>
                                                            </div>
                                                      </div>
                                                </td>
                                                <td>
                                                      <router-link class="col-md-3"
                                                            :to="{ name: 'student-details', params: { id: student_arr.id } }">
                                                            <button type="button" class="btn view-btn" @click="goBack">
                                                                  View
                                                            </button>
                                                      </router-link>

                                                </td>
                                          </tr>
                                          <tr v-else>
                                                <td colspan="6">
                                                      No Result Found
                                                </td>
                                          </tr>
                                    </tbody>
                              </table>
                        </div>

                  </card-box>

            </div>
            <div class="col-md-12">

                  <Paginate @back-btn="BackPage" @forward-btn="ForwardPage" :total="totalRecords" :perpage="8"
                        :currentpage="currentpage" @page-btn-clicked="pageNoClicked"></Paginate>
            </div>
      </div>
</template>

<style scoped>
.forword-backword-btn {
      border: none;
      background-color: ghostwhite;
}

.bg-fields-color {
      background-color: #F9FAFB;
}

.bg-button-color {
      background-color: #E5E7EB;
}

.btn-border {
      border: 1px solid #E5E7EB;
      border-radius: 8px;
      color: #717171;
}

.paginate-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 24px;
      height: 24px;
      color: black;
      margin-left: 6px;
}

.paginate-btn:hover {
      color: black;
}

.main-paginate-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      padding: 0px;
      gap: 20px;
      margin-top: 24px;
}

.left-arrow-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;
      gap: 10px;
}

.paginate_btns {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 6px;
}

.circle_content {
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #FFFFFF;
      width: 40px;
      height: 40px;
      border-radius: 37px;
      display: flex;
      justify-content: center;
}

.gray {
      color: #717171;
      font-size: 14px;
      margin-bottom: 0px !important;
}

.circle {
      display: flex;
      justify-content: center;
}

.pb-24 {
      padding-bottom: 24px !important;
}

.pt-24 {
      padding-top: 24px !important;
}

.custom-back-btn:hover {
      background: #D0DEFB;
      color: var(--primary);
}

.custom-back-btn {
      border-radius: 8px;
      padding: 6px 12px 6px 10px;
      column-gap: 8px;
      color: #9CA3AF;
}

.bt-28 {
      padding-top: 28px !important;
      padding-bottom: 28px !important;
}

.btn-border {
      border: 1px solid #E5E7EB;
      border-radius: 8px;
      color: #717171;
}

.bg-fields-color {
      background-color: #F9FAFB;
}

/* 
table td,
table th {
    padding: 18px 0px !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

}
table thead {
      border-width: 0.5px 0px;
      border-style: solid;
      border-color: rgba(171, 190, 209, 0.6);
      background: #F5F7FA !important;
} */


table td,
table th {
      padding: 17px 40px !important;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #212121;

}

table td {
      color: #4D4D4D !important;
}

table thead {
      background: #F5F7FA !important;
}

.table-text-color {
      color: #212121;
      font-size: 14px;
}

.first-cell-data {
      width: 60% !important;
}

.logo-text {
      display: flex;
      gap: 12px;
}

.tbody,
tfoot,
th,
thead,
tr {
      border-style: none !important;
}

.view-btn {
      color: #2463EB;
      background: rgba(36, 99, 235, 0.05);
      border-radius: 8px;
}

.progress {
      height: 8px !important;
}

.modal-header {
      border-bottom: none !important;
      /* padding: 1rem !important; */
      /* padding-bottom: 12px !important; */
      /* padding-left: 48px !important; */
}

.student_modal_text {
      color: #717171;
      font-size: 16px;
}

.modal-button-close {
      position: absolute;
      right: 20px;
      top: 20px;
      padding: 14px !important;
      border-radius: 12px;
      border: 1px solid rgba(77, 77, 77, 0.2);
}
</style>