<template>
    <footer v-if="auth.curentEndPoint != 'invite-childs' && auth.userinfo.type != 'Parent'" class="pt-4" style="
    z-index: 11;
    display: block;
    position: relative;">
        <div class="container">
            <div class="row">
                <!-- footer-logo -->
                <div class="col-xl-12 col-lg-12 col-md-12 footer-row" style="padding-bottom: 40px;">
                    <img :src="auth.baseUrl + 'files/assets/img/footer-logo.svg'">
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 footer-row" style="padding-top: 0px;">
                    <div style="display: flex;
    flex-direction: column;
    row-gap: 12px;">
                        <h4>Get started with Alternates for your child today!</h4>
                        <p class="footer-desc">Children from all over Pakistan are future-proofing their career as they
                            learn with Alternates.</p>
                    </div>
                </div>
                <div class="col-xl-2 offset-md-1  col-lg-2 col-md-6 footer-row" style="padding-top: 0px;">
                    <div class="">
                        <ul class="list-unstyled footer-menus ">
                            <li>
                                <b>Discover</b>
                            </li>
                            <li>
                                <router-link class="text-decoration-none" :to="{ name: 'courses' }">Courses</router-link>
                            </li>
                            <li>
                                <b>Support</b>
                            </li>
                            <li>
                                <router-link class="text-decoration-none" :to="{ name: 'contactus' }">Contact
                                    us</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 footer-row" style="padding-top: 0px;">
                    <div class="footer-menus">
                        <ul class="list-unstyled footer-menus ">
                            <li>
                                <b>Sign up</b>
                            </li>
                            <li>
                                <router-link class="text-decoration-none"
                                    :to="{ name: 'join-student' }">Student</router-link>
                            </li>
                            <li>
                                <router-link class="text-decoration-none"
                                    :to="{ name: 'school-signup' }">School</router-link>
                            </li>
                            <li>
                                <router-link class="text-decoration-none" :to="{ name: 'join-parent' }">Parent</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 footer-row" style="padding-top: 0px;">
                    <div>
                        <ul class="d-flex justify-content-end text- gap-3 list-unstyled social-icons  ">
                            <li>
                                <a target="_blank" href="https://www.facebook.com/alternates.tech/"><i
                                        class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/company/72099545"><i
                                        class="fa fa-linkedin"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/alternates.tech/"><i
                                        class="fa fa-instagram"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://twitter.com/alternates_tech"><i
                                        class="fa fa-twitter"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row text-center">
                <div class="col-md-12">
                    <hr>

                    <div class="copyright">
                        <span class="d-none d-sm-block">Copyright © 2023 Alternates. All Rights Reserved.</span>
                        <a href="javascript:void(0);" onclick="openModalJs('#termscondition')" class="copyright-items">Terms & Conditions</a>
                        <a href="javascript:void(0);" onclick="openModalJs('#privacypolicy')"  class="copyright-items">Privacy
                            Policy</a>
                        <span class="d-sm-none copyright-items">Copyright © 2023 Alternates. All Rights Reserved.</span>

                    </div>
                </div>
            </div>
        </div>

       
    </footer>
</template>
<script setup>
import { useAuthStore } from '@/store/auth.js';

const auth = useAuthStore();
</script>
<style scoped>
.copyright {
    display: flex;
    justify-content: center;
    column-gap: 32px;
    margin-bottom: 48px;
    font-size: 14px;
}

.footer-desc {
    font-family: var(--def-fontfamily);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

footer {
    background-color: #FFFFFF;
}

footer a {
    font-size: 14px !important;
}

footer .social-icons i {
    font-size: 16px;
}

.footer-menus .bolder {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;
}

.footer-menus {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
}

.footer {
    flex-direction: row;
    align-items: center;
}

.footer-row {
    padding-top: 30px;
    padding-bottom: 30px;
}

@media (max-width: 767.98px) {
    .container {
        padding-top: 33px;
        padding-bottom: 32px;
    }

    .footer-row {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .social-icons {
        justify-content: start !important;
        padding-left: 5px;
    }

    .copyright {
        display: block;
        text-align: left;

        /* font-size: 14px; */
    }

    .copyright-items {
        padding-bottom: 24px;

    }

    .copyright span,
    .copyright a {
        width: 100%;
        display: block;
    }
}</style>