<script setup>
import { onMounted, ref,watch,onUpdated } from "vue";
import { useAuthStore } from "@/store/auth.js";
import CourseBox from "@/components/course/CourseBox.vue";
import SubHeader from "@/views/student/SubHeader.vue";
import factory from '@/common/factory.js';
import { useRouter } from 'vue-router';
import Paginate from '@/components/Paginate.vue';
import { functions } from "@/common/functions.js";
import CommonSvgs from '@/components/CommonSvgs.vue';



const router = useRouter();
var courses = ref([]);
var is_courses = ref(true);
var totalRecords = ref(1);
var currentpage = ref(1);
var currentcondition = ref('add');
const auth = useAuthStore();
onMounted(() => {
  studentCourses();
});  

function goCourse(obj) {
  router.push('/course/' + obj.subject_course_id);
}
function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + auth.token,
    },
  };
  return token;
} 


async function studentCourses(condition = 'all') {
  currentcondition.value = condition;
  functions.initLoader();
  var filter = {
    condition: condition,
    page: currentpage.value,
  };
  const clsStd = new factory(getToken(), 'student-courses',filter);
  await clsStd.getHttp().then((response) => {
    swal.close();
    // console.log(response.data.data.courses.data);
    if (response.data.data.courses.data.length>0 && response.data.data.courses.data != []) {
      is_courses.value = true;
      courses.value = response.data.data.courses.data;
      totalRecords.value = response.data.data.courses.total;
      
      currentpage.value = response.data.data.courses.current_page;
    }
    else {
      is_courses.value = false;
      totalRecords.value = response.data.data.courses.total;
      currentpage.value = response.data.data.courses.current_page;
      
    }
  });
}
function menuClicked(condition) {
    currentpage.value = 1;
    studentCourses(condition);
}


function BackPage() {
    currentpage.value = currentpage.value - 1;
    studentCourses(currentcondition.value);

}
function ForwardPage() {
    currentpage.value = currentpage.value + 1;
    studentCourses(currentcondition.value);
}
function pageNoClicked(pageno) {
    currentpage.value = pageno;
    studentCourses(currentcondition.value);
}
</script>
<template>
  <div v-if="auth.userinfo.type == 'Student'" class="col-md-12 " style="margin-bottom: 32px !important;">
    <sub-header @menuclicked="menuClicked"></sub-header>
  </div>  
  <course-box v-if="is_courses" v-for="course in courses" @courseclicked="goCourse"
  :progress="(course.completedtopics > 0 && course.totaltopics > 0)? parseInt((course.completedtopics / course.totaltopics) * 100) :0" :dataid="course" :startdate="course.startdate"
    :img="functions.displayImg(course.file_name)" :title="course.name" :secondtitle="course.book_name" :desc="course.subjectname"
    class="col-lg-4 col-md-6">
  </course-box>
  <h3 v-else class="no-course-found-div">
    <div>
      <common-svgs type="no-course"></common-svgs>
      <p class="no-course-found">No Course Found</p>
    </div>
  </h3> 
  <div class="col-md-12" v-if="totalRecords > 0">
    <div>
      <Paginate @back-btn="BackPage" @forward-btn="ForwardPage" :total="totalRecords" :perpage="9"
        :currentpage="currentpage" @page-btn-clicked="pageNoClicked"></Paginate>
    </div>
  </div>
</template>

<style scoped>
#notification-card {
  border: none;
}

.mt-36 {
  margin-top: 36px !important;
}

.parent-invitation-label {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 4px !important;
}

.invitation-text {
  font-size: 14px;
  color: #212121;
  font-weight: 400;
}

.ml-12 {
  margin-left: 12px !important;
}

.no-course-found-div {
  margin-top: 120px !important;
  display: flex;
  justify-content: center;
}

.no-course-found{
  margin-top: 16px;
  font-size: 18px;
  font-weight: 400;
  color: #717171;
}
</style>