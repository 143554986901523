
<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue';
import factory from '@/common/factory.js';
import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/auth.js";
import {functions} from '@/common/functions.js';

import DateInput from "@/components/DateInput.vue";
import moment from "moment";
import ConditionalInput from "@/components/ConditionalInput.vue";
import TextInput from "@/components/TextInput.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import SelectBox from "@/components/SelectBox.vue";

const route = useRoute();

let props = defineProps(["classStudents", "current_class_id"]);
var class_id = ref(route.params.id);
const auth = useAuthStore(); 
var isInValid = ref(true);
var inValidUsername = ref(false);
var feildCondition = ref('warning');
var conditionalHelpText = ref([]);
// console.log("currnt class id ", props.current_class_id);

const id = ref(route.params.id);

var emailError = ref('');

var form = ref({
  dob: '',
  full_name: '',
  phone1: '',
  email: '',
  password: '',
  username: "",
  class_id: id.value,
  postal_address: ''
});
const emit = defineEmits(['formSubmit']);
function validate() {
      // debugger;
      error.value.username = '';
  isInValid.value = false; 
  if(form.value.username == '' || !inValidUsername.value){
    isInValid.value = true;
    // error.value.username = "Username field is required";
    if(form.value.username != '' && form.value.username.length <= 4){
      error.value.username = 'Username should be atleast 5 Characters.';
    }
  }
  // console.log("dob",form.value.dob);
  // error.value = '';
  error.value.dob = '';
  if(form.value.dob == '' ){
    isInValid.value = true; 
    // error.value.dob = "DOB field is Required";
  }
    error.value.full_name = "";
  if(form.value.full_name == ''){
    isInValid.value = true;
    // error.value.full_name = "Full Name field is required";
  }
  error.value.phone1 = "";
  if(form.value.phone1 == ''){
    isInValid.value = true;
    // error.value.phone1 = "Phone No field is required";
  }
  error.value.class_id = '';
  if(form.value.class_id == '' || form.value.class_id==undefined){
    isInValid.value = true;
    // error.value.class_id = 'Class field is requried';
  }
  // console.log(form.value.password.length);
  // if (form.value.password == '') {
    // if(form.value.password.length < 8) {
      // error.value.password = 'Password field length should not be less than 8 characters';
    // }
    // else {
      // isInValid.value = true;
    // }
    // }
    
    error.value.password = '';
  if(form.value.password == ''){
    isInValid.value = true;
  }else{
    if(form.value.password.length < 8){
      isInValid.value = true;
      error.value.password = 'Password length should be atleast 8 characters.';
    }else{

      error.value.password = '';
    }

  }
  if ((form.value.email) != '') {
    if (!validateEmail(form.value.email)) {
      // isInValid.value = false;
      isInValid.value = true;
      emailError.value = 'Invalid Email.';
    } else {
      emailError.value = '';

    }
  } else {
    emailError.value = '';

  }
  
}

var provinces = ref([]);
var cities = ref([]);
var fil_cities = ref([]);
var fil_classes = ref([]);
var error = ref({}); 

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + auth.token,
    },
  };
  return token;
}

var ProvincesCities = new factory(getToken(), 'provinces-cities');
ProvincesCities.getHttp().then((response) => {
  if (response.data.status) {
    provinces.value = provinces.value = response.data.data.provinces;
    fil_cities.value = cities.value = response.data.data.cities;
  }
});


async function signupStudent() {
  form.value.dob = moment(form.value.dob).format("YYYY-MM-DD")
  const SignUp = new factory(getToken(), 'single-student-signup', form.value);
  await SignUp.postForm().then((response) => {
    if (response.data.status) {
      swal.fire('Done!', 'Student Added Successfully.', 'success');
      $('#single-student-form')[0].reset();
      $('.conditional-field-icon-success').addClass('d-none');
      form.value.class_id = id.value
      $('.my_class').find('select').change();
      emit('formSubmit');
    } else {
      if(response.data.message){
        swal.fire('Error!',response.data.message,'error')
      }else{
        swal.fire('Error!','Something went wrong!','error')
      }
    }
  });
};

function schoolSelect(){ 
  var classesApi = new factory(getToken(), 'loggedInSchoolClasses');
  // console.log("API", classesApi);
  classesApi.getHttp().then((response) => {
    if (response.data.status) {
      fil_classes.value = response.data.data;
      
    }
  });
}
schoolSelect();

async function checkUserName() { 
  feildCondition.value = 'warning';
  if (form.value.username.trim() == '' || form.value.username.length <= 4) {
      feildCondition.value = 'warning';
      inValidUsername.value = false;
      validate();
      // checkStepTwoValidation();
      return false;
  }
  var checkuser = {
    'full_name':form.value.full_name,
    'user_name':form.value.username
  }
  const SignUp = new factory(getToken(), 'check-username-availability', checkuser);
  await SignUp.getHttp().then((response) => {
    
    // if (response.data.data != null && response.data.data != "null") {
    if (response.data.status) {
      feildCondition.value = 'warning';
      inValidUsername.value = false;
      conditionalHelpText.value = response.data.data.suggested_user_names; 
    } else {
      if(response.data.error != null && response.data.error.length > 0){
        feildCondition.value = 'warning';
        inValidUsername.value = false;
        error.value = functions.errorResponse(response.data,false);
      }else{
        inValidUsername.value = true;
        feildCondition.value = 'success';
        conditionalHelpText.value =[];
      }
    }
    validate();
  });
}
function revalidate() {
  feildCondition.value = 'none';
  inValidUsername.value = false;
  isInValid.value = true; 
}

onMounted(() => {
  form.value.class_id = props.current_class_id;
});
 


</script>

<template>
  <div class="row">
    <form @submit.prevent="signupStudent()" id="single-student-form">
      <text-input @change="validate" class="col-md-12 fullname" v-model="form.full_name" :error="error.full_name" label="Full Name"  placeholder="Full Name"></text-input>
      <conditional-input @focus="revalidate" @change="checkUserName" :condition="feildCondition" :error="error.username" :helpconditionalpretext="'Suggested : '" :helpconditionaltext="conditionalHelpText" class="col-md-12 user_name disableSpaces"
        v-model="form.username" label="Username" placeholder="Username"></conditional-input>
      <date-input type="text dob"  @on-change="validate" label="Date of Birth" class="col-md-12 dob"  :minDate="$moment($moment()).subtract(4, 'years').format('YYYY-MM-DD')" v-model="form.dob" placeholder="Date of Birth"></date-input>
      <text-input class="col-md-12 phone" @change="validate();" type="phone" v-model="form.phone1"  :error="error.phone1"  maxLength="11" label="Parent’s Phone Number" placeholder="Parent’s Phone Number"></text-input>
        
      
      <text-input class="col-md-12"  @change="validate();" :error="emailError"  v-model="form.email" placeholder="Email Address (Optional)" label="Email Address (Optional)"></text-input>
      <text-input class="col-md-12 postal_address" type="text" v-model="form.postal_address" label="Postal Address" placeholder="Postal Address (Optional)"></text-input>
      <select-box class="col-md-12 my_class"  :error="error.class_id"  v-model="form.class_id" placeholder="Class" label="Class"
        @do-change="schoolSelect();validate();" >
        <option v-for="cl in fil_classes" :value="cl.id">{{ cl.name }}</option>
      </select-box>
      <password-input @change="validate();" v-model="form.password" :error="error.password" class="col-md-12 passwordfeild"
        placeholder="Enter Password" label="Enter Password"
        helptext="Your password can be any combination of letters, numbers, and symbols."></password-input>
      <div class="col-md-12"> 
        <button class="btn btn-primary btn-block" :disabled="isInValid">Continue</button>
      </div>
    </form>     
  </div>
</template>

<style scoped></style>