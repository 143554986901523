<script setup>
import { defineProps, ref, onUpdated, defineEmits } from "vue";
import CommonSvgs from "@/components/CommonSvgs.vue";

var prop = defineProps(['title', 'status','completedTopics','lastTopicContent']);
var emits = defineEmits(['startClicked','completeClicked','resumeClicked','topicClicked']);
var title = ref(prop.title);
var status = ref(prop.status);
var completedTopics = ref(prop.completedTopics);
var lastTopicContent = ref(prop.lastTopicContent);
onUpdated(() => {
  title.value = prop.title;
  status.value = prop.status;
  completedTopics.value = prop.completedTopics;
  lastTopicContent.value = prop.lastTopicContent;
});

function startClicked(){
    emits('startClicked');
}

function completeClicked(){
    emits('completeClicked');
}
function resumeClicked(){
    emits('resumeClicked');
}
function topicClicked(){
    emits('topicClicked');
}


</script>
<template>
    <div class="accordian-content" @click="topicClicked">

        <div class="accordian-content-right">
            <div class="accordian-content-left" :class="((status == 0)?'bg-default':'bg-success-custom')"> 
                <common-svgs v-if="status == 0" style="margin: 0 auto;" :type="'course-video'"></common-svgs>
                <common-svgs v-else style="margin: 0 auto;" :type="'course-complete'"></common-svgs>
            </div>
            <div class="accordian-content-title">
                <span class="btext-1">{{ title }}</span>
            </div>
        </div> 
        <div class="accordian-content-action"> 
            <button  v-if="lastTopicContent == null && status == 0" type="button" class="btn btn-clean-primary "
                style="padding: 8px 24px;border-radius: 4px;" @click="startClicked">Start</button>
            <button v-else-if="lastTopicContent != null && status == 0" type="button" class="btn btn-clean-primary"
                style="padding: 8px 24px;border-radius: 4px;" @click="resumeClicked">Resume</button>
            <button v-else type="button" class="btn btn-clean-success"
                style="padding: 8px 24px;border-radius: 4px;" @click="completeClicked">Completed</button>
        </div>
    </div>
</template> 
<style scoped>
.btn-clean-primary:hover{
    background-color: rgba(36, 99, 235, 0.1);;

}
.btn-clean-primary{
    background-color: white;
}
.bg-success-custom{
    background: #E5F4EF;
}
.bg-default{
    background: #F2F2F2;
}
.accordian-content-left {
    display: flex;
    width: 60px;
    height: 64px;  
    border-radius: 12px 0px 0px 12px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.accordian-content-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 18px;
}

.accordian-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 32px 0px 0px;
    gap: 18px;
    background: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    justify-content: space-between;
}
</style>