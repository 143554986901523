import { ref, reactive } from "vue";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: ref(null),
    notification_counter: ref(0),
    userinfo:ref({}), 
    curentEndPoint:ref(null),
    isWebsite:ref(true),
    noFooter:ref(false),
    noHeaderMenu:ref(false),
    baseUrl:ref(process.env.BASE_URL), 
    fcmToken:ref(null), 
    data:reactive({})
  }),

  persist: {
    storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
  },
});

