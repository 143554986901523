<template>
  <div class="card">
    <slot name="cardHeader"></slot>
    <div class="" :class="($attrs.minimalpadding)?'card-body-2':'card-body'" :style="$attrs.cardBodyStyle" >
      <div class="row" :style="$attrs.cardBodyRow">
        <div class="col-md-12 mb-3" :style="$attrs.cardBodyCol" v-if="$attrs.backtitle">
          <button type="button" class="btn custom-back-btn" :id="$attrs.id" @click="backBtnClicked">
            <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
            {{ $attrs.backtitle }}
          </button> 
        </div>
        <div class="col-md-12 mb-3" v-if="$attrs.title">
          <h4 v-if="$attrs.htype == 'h4'">{{ $attrs.title }}</h4>
          <span v-else-if="$attrs.htype == 'display-3'" class="display-3" style="font-weight: 400;">{{ $attrs.title
          }}</span>
          <h2 v-else>{{ $attrs.title }}</h2>
        </div>
        <div class="col-md-12 mb-3" v-if="$attrs.shortdesc">
          <p class="btext-2">{{ $attrs.shortdesc }}</p>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script setup>
import defineEmits from "vue";
import CommonSvgs from "@/components/CommonSvgs.vue";

var emits = defineEmits(['back-btn-clicked']);

function backBtnClicked() {
  emits('back-btn-clicked');
}
</script>
<style scoped>
/* Form Card CSS Start */
.card {
  border-radius: 12px;
}

.card-body {
  padding: 48px !important;
}
.card-body-2{
  padding: 24px 32px;
}

.custom-back-btn:hover {
  background: #D0DEFB;
  color: var(--primary);
}

.custom-back-btn {
  border-radius: 8px;
  padding: 6px 12px 6px 10px;
  gap: 8px;
  color: #9CA3AF;
}

@media (max-width: 915px) {
  .card-body,.card-body-2 {
    padding: 16px !important;
  }
}

/* Form Card CSS End */</style>
