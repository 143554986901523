<template> 
    <span style="display: none;">{{ vimeoPlayerRef }}</span>
    <div ref="vimeoPlayerRef"></div>
    <!-- <div :data-vimeo-url="videoUrl" ref="vimeoPlayerRef"></div> -->
</template>
  
<script setup>
import { ref, onMounted, defineProps, defineEmits, onUpdated } from 'vue';
import VimeoPlayer from '@vimeo/player';
var emit = defineEmits([
    'handlePlay',
    'handlePause',
    'handleEnd',
    'handleTimeUpdate',
    'videoDuration'
]);
var props = defineProps(['videoId', 'height', 'videoUrl', 'watchedduration']);
var videoUrl = ref(props.videoUrl);
var watchedduration = ref(props.watchedduration);
const vimeoPlayerRef = ref(null);
var percentageWatched = ref(0);
var minutesWatched = ref(0);
var realWatchedTime = ref(0);
var realPercentageWatched = ref(0);
let vimeoPlayerInstance = null;
var videoDuration = ref(0);
var displayit = ref(false);
let startTime = null;
let timer = null;

onUpdated(() => {
    videoUrl.value = props.videoUrl;
    watchedduration.value = props.watchedduration;
    displayit.value = true;
    if (vimeoPlayerInstance == null) {
        initVideoPlayer(props.videoUrl);
    }

});

function initVideoPlayer(url) {
    vimeoPlayerInstance = new VimeoPlayer(vimeoPlayerRef.value, {
        url: url,
        width: $('.topic-content-view').width(), 
        height: '500px', 
    });
    // console.log(vimeoPlayerInstance);
    // Play event
    vimeoPlayerInstance.on('play', handlePlay);

    // Pause event
    vimeoPlayerInstance.on('pause', handlePause);

    // End event
    vimeoPlayerInstance.on('ended', handleEnd);

    // Timeupdate
    vimeoPlayerInstance.on('timeupdate', handleTimeUpdate);

    vimeoPlayerInstance.getDuration().then((duration) => {
        videoDuration.value = duration;
        if (watchedduration.value > 0 && watchedduration.value != null && watchedduration.value != videoDuration.value) {
            const startTime = watchedduration.value; // Replace 30 with the desired time in seconds
            vimeoPlayerInstance.setCurrentTime(startTime).then(() => {
                // The video will start playing from the specified time
                // vimeoPlayerInstance.play();
            });
        }
    });
    

    vimeoPlayerInstance.ready().then(() => {
        // Player is ready, perform actions here
        emit('videoDuration', videoDuration.value)
    });
}
onMounted(() => {
    videoUrl.value = props.videoUrl;
});


const handlePlay = () => {
    emit('handlePlay');
    emit('videoDuration', videoDuration.value)
    // Call your function or perform actions when the player is played

    // Reset percentage watched when the video starts playing
    // percentageWatched.value = 0;

    // timer = setInterval(() => {
    //     if (realWatchedTime.value < videoDuration.value) {
    //         realWatchedTime.value += 1; // Increment real watch time by 1 second
    //     }
    // }, 1000);
    // if(startTime == null){

    //     startTime = Date.now();
    // }

    // console.log('Video played');
};

const handlePause = () => {

    // Call your function or perform actions when the player is paused
    // if (startTime) {
    //     const currentTime = Date.now();
    //     const timeDifference = currentTime - startTime;
    //     realWatchedTime.value += (Math.floor(timeDifference / 1000));
    //     startTime = null;
    // }
    // console.log('Video Paused');

    // clearInterval(timer);
    emit('handlePause');
};

const handleEnd = () => {
    // Call your function or perform actions when the player ends
    // console.log('Video End');
    // clearInterval(timer);
    emit('handleEnd');
};

const handleTimeUpdate = ({ seconds }) => {


    // Calculate minutes watched
    minutesWatched.value = Math.floor(seconds / 60);
    // Call your function or perform actions with minutesWatched

    // Calculate the percentage of video watched
    percentageWatched.value = (seconds / videoDuration.value) * 100;

    // if (realWatchedTime.value > seconds) {
    //     clearInterval(timer);
    // } else { 

    // }

    // if (startTime) {
    //     var currentTime = Date.now();
    //     var timeDifference = currentTime - startTime;
    //     realWatchedTime.value = Math.floor((timeDifference / 1000) + seconds);
    // }
    // if (startTime) {
    //     const currentTime = Date.now();
    //     const timeDifference = currentTime - startTime;
    //     realWatchedTime.value += (Math.floor(timeDifference / 1000));
    //     startTime = null;
    // }
    // realWatchedTime.value++;

    realPercentageWatched.value = (realWatchedTime.value / videoDuration.value) * 100;
    // console.log('Video Time Update');
    // console.log('Minutes Watched' + minutesWatched.value);
    // console.log('% Watched' + percentageWatched.value);
    emit('handleTimeUpdate', seconds);
};

</script> 
<style scoped>
.vimeo-player {
    height: var(--player-height, 500px) !important;
    width:100% !important;
    /* Set the height using CSS variable */
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
</style>