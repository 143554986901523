<script setup>
import { onMounted, computed } from 'vue';
import CardBox from '@/components/CardBox.vue';
import CommonSvgs from '@/components/CommonSvgs.vue';
import factory from "@/common/factory.js";
import { useAuthStore } from "@/store/auth.js";
import { ref } from 'vue';
import { useRoute } from "vue-router";
import { useRouter } from 'vue-router';
import { functions } from "@/common/functions.js";
import SelectBox from "@/components/SelectBox.vue";

const auth = useAuthStore();
const route = useRoute();
const router = useRouter();

var progress = ref([]);
var courses = ref([]);
var lastlog = ref('');
var completedcourses = ref(0);
var user_student_id = ref(0);

const id = ref(route.params.id);
let studentData = ref({
      student_name: '',
      username: '',
      phone1: '',
      parent_name: '',
      dob: '',
      city: '',
      email: '',
      class_name: '',
      postal_address: ''

});
var current_class = ref('');
var to_class = ref('');
var isDisabled = ref(true);
function getToken() {
      const token = {
            headers: {
                  // 'Content-Type': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded',
                  'Authorization': 'Bearer ' + auth.token,
            },
      };
      return token;
}


async function studentReport() {
      var student_id = {
            id: id.value
      };
      const StdReport = new factory(getToken(), 'single-student-report', student_id);
      await StdReport.getHttp().then((response) => {
            swal.close();
            if (response.data.status) {
                  progress.value = response.data.data ? response.data.data.progress : [];
                  lastlog.value = response.data.data ? response.data.data.lastLog : '';
                  courses.value = response.data.data ? response.data.data.courses : [];
            }
      });
}

$(document).on('shown.bs.modal','#changeClass', function(){
      $('.selected_class').find('select').change();
});
async function studentDetails() {
      var student_id = {
            id: id.value
      };
      const clsStd = new factory(getToken(), 'student-details', student_id);
      await clsStd.getHttp().then((response) => {
            swal.close();
            if (response.data.status) {
                  user_student_id.value = response.data.data.id;
                  current_class.value = response.data.data.current_class_id;
                  studentData.value.student_name = response.data.data ? response.data.data.student_name : '--';
                  studentData.value.username = response.data.data.users.username;
                  studentData.value.phone1 = response.data.data.phone1 ? response.data.data.phone1 : '--';
                  studentData.value.parent_name = response.data.data.users.latest_parent ? response.data.data.users.latest_parent.parent_name : '--';
                  studentData.value.dob = response.data.data ? response.data.data.dob : '--';
                  studentData.value.city = response.data.data.student_class ? (response.data.data.student_class.school.city.city) : '--';
                  studentData.value.email = response.data.data.email ? response.data.data.email : '--';
                  studentData.value.class_name = response.data.data.student_class ? response.data.data.student_class.class_name : '--';
                  studentData.value.postal_address = response.data.data.address ? response.data.data.address : '--';
            }
      });
}
studentDetails();
studentReport();
function filterOutCourses() {
     var fil = $('.filtercourse').find('option:selected').val();
     $('#nocourse').hide();
     if (fil == '1') {
          if($('.incompletecourse').length == 0){
               $('#nocourse').show();
          }
          $('.incompletecourse').show();
          $('.completedcourse').hide();
          $('.startcourse').hide();
     } else if (fil == '2') {
          if($('.completedcourse').length == 0){
               $('#nocourse').show();
          }
          $('.incompletecourse').hide();
          $('.completedcourse').show();
          $('.startcourse').hide();
     } else {
          if($('.courses').find('tbody').find('tr').length == 0){
               $('#nocourse').show();
          }
          $('.courses').find('tbody').find('tr').show();
     }
}
function returnProgress(completed, total) {
      if (completed > 0 && total > 0) {
            var $round = $('.round');
            var roundRadius = $round.find('circle').attr('r');
            var roundPercent = parseInt((completed / total) * 100);
            var roundCircum = 2 * roundRadius * Math.PI;
            var roundDraw = roundPercent * roundCircum / 100;
            $round.css('stroke-dasharray', roundDraw + ' 999');
            return roundPercent;

      }
      return 0;
}

onMounted(() => {
      $(document).ready(function () {
            var $round = $('.round');
            var roundRadius = $round.find('circle').attr('r');
            var roundPercent = $round.data('percent');
            var roundCircum = 2 * roundRadius * Math.PI;
            var roundDraw = 0;
            if (roundPercent > 0) {
                  roundDraw = roundPercent * roundCircum / 100
            } else {
                  roundDraw = 0;
            }
            $round.css('stroke-dasharray', roundDraw + ' 999')
      });
      schoolSelect();      
})

schoolSelect();
completedcourses.value = computed(() => {
      return courses.value.filter(
            (course) => course.completedtopics > 0 && course.completedtopics === course.totaltopics
      ).length;
});

function goBack() {
      window.history.back();     
}
function changeClassModal () {
      isDisabled.value = true;
      $('#changeClass').modal('show');
      // $('.selected_class').find('select').change();
      // studentDetails();
}

const changeClassFormSubmit = async () => {
      var student_details = {
            student_id: user_student_id.value,
            to_class: to_class.value
      };
      const clsStd = new factory(getToken(), 'change-student-class', student_details);
      await clsStd.getHttp().then((response) => {
            swal.close();
            console.log(response);
            if(response.data.status){
                  swal.fire('Done!', "Student Class Changed Successfully", 'success');
                  closeModal();
                  studentDetails();
                  studentReport();
            }
            else {
               swal.fire('Error!', "Current and Promoted Class cannot be Same", 'error');
            }
      });
};

var classes = ref([]);
function schoolSelect(){ 
  var classesApi = new factory(getToken(), 'loggedInSchoolClasses');
  classesApi.getHttp().then((response) => {
    if (response.data.status) {
      classes.value = response.data.data;
    }
  });
}

function closeModal() {
      $('.new_class').find('select').val('').change();
      $('#changeClass').modal('hide');
}

function checkToClass() {
      var new_class = '';
      new_class = $('.new_class').find('select').val();
      if(new_class != null || new_class != '') {
            isDisabled.value = false;
      }
      else {
            isDisabled.value = true;
      }
}
</script>

<template>
      <div class="modal fade" id="changeClass" aria-labelledby="changeClassLabel" aria-hidden="true">
     <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="d-flex justify-content-between" >
                  <div></div><div><button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
               </div>    
               <div class="info">
                     <h2>Change Class</h2>
                  <p class="class-selection-text">Select the class where you want to move this student to.</p>
                  <form @submit.prevent="changeClassFormSubmit()">
                        <select-box class="col-md-12 selected_class" v-model="current_class" disabled label="Current Class" :nosearch="true" placeholder="Current Class">
                              <option v-for="cls in classes" :value="cls.id" >{{ cls.name }}</option>
                        </select-box>
                        <select-box class="col-md-12 mt-2 new_class" @do-change="checkToClass()" v-model="to_class" label="To Class" :nosearch="true" placeholder="To Class">
                              <template v-for="cls in classes" >
                                    <option :value="cls.id"  v-if="cls.id != current_class" >{{ cls.name }}</option>
                              </template>
                        </select-box>
                        <div class="row">
                              <div class="col-md-6">
                                    <button type="button" class="btn cancel-btn my-btn btn-block" @click="closeModal()">Cancel</button>
                              </div>
                              <div class="col-md-6">
                                    <button class="btn btn-primary btn-block change-btn my-btn" :disabled="isDisabled">Change</button>
                              </div>
                        </div>
                  </form>
               </div>
          </div>
     </div>
</div>

      <div class="d-flex justify-content-between mb-3 btn-spacing">
            <button type="button" class="btn custom-back-btn" @click="goBack">
                  <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                  Back to Home
            </button>
            <button class="btn btn-primary change-class-btn" @click="changeClassModal()">Change Class</button>
      </div>
      <card-box :noPadding="true" class="p-0" :minimalpadding="true">
            <div class="row p-0">
                  <div class="col-md-2 col-4 p-0 d-md-flex justify-content-md-center align-items-md-center">
                        <img src="../../../public/img/student_icon.png" alt="" class="img_rounded studentimg">
                  </div>
                  <div class="col-md-10 col-8 p-0">
                        <div class="row">
                              <div class="col-md-3">
                                    <label for="" class="lables">Student Name</label>
                                    <p class="content">{{ studentData.student_name }}</p>
                              </div>
                              <div class="col-md-3">
                                    <label for="" class="lables">Username</label>
                                    <p class="content">{{ studentData.username }}</p>
                              </div>
                              <div class="col-md-3">
                                    <label for="" class="lables">Phone</label>
                                    <p class="content">{{ studentData.phone1 }}</p>
                              </div>
                              <div class="col-md-3">
                                    <label for="" class="lables">Parent Name</label>
                                    <p class="content">{{ studentData.parent_name }}</p>
                              </div>
                        </div>

                        <div class="row">
                              <div class="col-md-3">
                                    <label for="" class="lables">Date of Birth</label>
                                    <p class="content">{{ studentData.dob ? functions.dateFormat(studentData.dob) : '--' }}</p>
                              </div>
                              <div class="col-md-3">
                                    <label for="" class="lables">City</label>
                                    <p class="content">{{ studentData.city }}</p>
                              </div>
                              <div class="col-md-3">
                                    <label for="" class="lables">Email</label>
                                    <p class="content">{{ studentData.email }}</p>
                              </div>
                              <div class="col-md-3">
                                    <label for="" class="lables">Class</label>
                                    <p class="content"> {{ studentData.class_name }} </p>
                              </div>
                        </div>

                        <div class="row">
                              <div class="col-md-6">
                                    <label for="" class="lables">Postal Address</label>
                                    <p class="content mb-0">{{ studentData.postal_address }}</p>
                              </div>
                        </div>
                  </div>
            </div>
      </card-box>

      <card-box :noPadding="true" class="p-0" :minimalpadding="true" style="margin-top: 24px !important">

            <div class="col-md-4">
                  <div class="cube sub-cube1">


                        <div class="icon-container custom-round-progress">
                              <div class="point">
                                    <svg class="round" viewbox="0 0 100 100" width="100" height="100"
                                          :data-percent="returnProgress(progress.completedTopicContents, progress.totalTopicContents)">
                                          <circle cx="50" cy="50" r="12" />
                                          <circle class="border" cx="50" cy="50" r="12" />
                                    </svg>
                              </div>
                        </div>
                        <div class="container-text">
                              <p class="lables mb-0">Overall Progress</p>
                              <p class="container-data mb-0">
                                    {{ returnProgress(progress.completedTopicContents, progress.totalTopicContents) }}%
                              </p>
                        </div>
                  </div>
            </div>

            <div class="col-md-4">
                  <div class="cube sub-cube2">
                        <div class="icon-container">
                              <common-svgs type="complete-courses"></common-svgs>
                        </div>
                        <div class="container-text">
                              <p class="lables mb-0">Complete Courses</p>
                              <p class="container-data mb-0">{{ completedcourses }}/{{ courses.length }}</p>
                        </div>
                  </div>
            </div>

            <div class="col-md-4">
                  <div class="cube sub-cube3">
                        <div class="icon-container">
                              <common-svgs type="clock"></common-svgs>
                        </div>
                        <div class="container-text">
                              <p class="lables mb-0">Last Active</p>
                              <p class="container-data mb-0">{{ ((lastlog) ? ($moment(lastlog).format("DD MMMM YYYY"))
                                    : '') }}</p>
                        </div>
                  </div>
            </div>


            <div class="col-md-12" style="margin-top: 28px !important;">
                  <div class="row d-flex justify-content-between">
                        <div class="col-md-3 col-4">
                              <h4 style="margin-bottom: 32px !important;">Courses</h4>
                        </div>
                        <div class="col-md-2 col-6">
                              <select class="form-select filtercourse" aria-label="Default select example"
                                    @change="filterOutCourses();">
                                    <option value="0">All</option>
                                    <option value="1">In-Progress</option>
                                    <option value="2">Completed</option>
                              </select>
                        </div>
                  </div>
            </div>
            <div class="col-md-12 pt-24">
                  <div class="row m-0">
                        <table class="table courses coursestbl">
                              <thead>
                                    <tr>
                                          <th style="min-width:300px">Course name</th>
                                          <th>Subject</th>
                                          <th>Completed Contents</th>
                                          <th style="min-width:200px">Last Seen</th>
                                    </tr>
                              </thead>
                              <tbody>
                                    <tr v-for="course in courses"
                                          :class="(course.completedtopics > 0 && course.completedtopics == course.totaltopics) ? 'completedcourse' : (returnProgress(course.completedtopics, course.totaltopics) ? 'incompletecourse' : 'startcourse')">
                                          <td class="logo-text">
                                                <img :src="functions.displayImg(course.file_name)"
                                                      style="width: 32px; height: 32px;" />
                                                <p class="table-text-color first-cell-data">
                                                      {{ course.name }} - {{ course.book_name }}
                                                </p>
                                          </td>
                                          <td class="table-text-color">
                                                {{ course.subjectname }}
                                          </td>
                                          <td class="table-text-color">
                                                {{ course.completedtopics }}/{{ course.totaltopics }}

                                          </td>
                                          <td class="table-text-color">
                                                {{
                                                      (course.lastseen) ? functions.formatDate($moment, course.lastseen) : ''
                                                }}
                                          </td>
                                    </tr>

                              </tbody>
                        </table>
                  </div>
                  <div style="display:none;" id="nocourse" class="no-course-div">
                    <common-svgs type="no-course"></common-svgs>
                    <p class="no-course-found">No Course Found</p>
               </div>
            </div>

      </card-box>
</template>

<style scoped>
.first-cell-data {
      display: flex;
      flex-direction: row;
      vertical-align: middle;
      align-items: center;
      margin: 0px;
}

table tbody tr {
      vertical-align: middle;
}

.card-body-2 {
      padding: 64px 32px 64px 32px !important;
}

.lables {
      color: #717171;
      font-size: 13px !important;
}

.content {
      color: #212121;
      font-size: 14px !important;
}

.img_rounded {
      border-radius: 50%;
      width: 80px;
      height: 80px;
}

.cube {
      border-radius: 12px;
      padding: 20px !important;
      display: flex;
      gap: 16px;
}

.sub-cube1 {
      background: rgba(36, 99, 235, 0.08);
}

.sub-cube2 {
      background-color: rgba(0, 146, 98, 0.07);
      ;
}

.sub-cube3 {
      background-color: rgba(255, 186, 0, 0.07)
}

.icon-container {
      width: 40px;
      height: 40px;
      padding: 8px;
      background: white;
      border-radius: 8px;
}

.container-data {
      font-size: 16px;
      color: #212121;
}

table td,
table th {
      padding: 14px 16px !important;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #6B7280;

}

table thead {
      background: #F5F7FA !important;
}

.table-text-color {
      color: #212121;
      font-size: 14px;
}

.first-cell-data {
      width: 60% !important;
}

.logo-text {
      display: flex;
      gap: 12px;
}

.tbody,
tfoot,
th,
thead,
tr {
      border-style: none !important;
}

@media (max-width: 915px) {
     .coursestbl {
          display: block;
          overflow: auto;
          padding: 0px !important;
     }

     .logo-text {
          width: 280px;
     }

     .datecolumn {
          display: block;
     }

     .cube {
          margin-bottom: 8px;
     }
     .studentimg{
      margin-left: 24px;
     }
}
.back-to-courses {
      color: #89939E;
      font-size: 12px;
      font-weight: 600;
}
.change-class-btn {
      border-radius: 4px;
      background: #2463EB !important;
      font-size: 14px;
}
.modal-button-close {
      border: 1px solid #4D4D4D;
      padding: 10px !important;
      border-radius: 12px;
}
.class-selection-text {
      color: #717171;
      font-size: 16px;
      font-weight: 400;
      margin-top: 12px;
}
.my-btn {
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      padding: 10px 18px 10px 18px;
      font-size: 16px !important;
}
.btn-spacing {
      margin-top: -40px;
}
</style>