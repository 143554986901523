<template>
    <div class="container" style="padding-top: 115px;">
        <div class="row mb-5">
            <div class="col-md-12 mb-30p">
                <router-link :to="{ name: 'dashboard' }" class="btn custom-back-btn">
                    <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                    Back to Courses
                </router-link>
            </div>
            <div class="col-md-12 mb-30p">
                <div class="card">
                    <div class="card-body  coursepage-card">
                        <div class="row">
                            <div class="col-md-3 pl-0 p-0">
                                <img :src="functions.displayImg(course.file_name)" class="img-fluid course-img"
                                    style="border-radius: 6px;" />
                            </div>
                            <div class="col-md-9 text-portion p-0">
                                <h3>{{ course.name }} - {{ course.book_name }}</h3>
                                <!-- <p class="btext-3">
                                    Our content will be perfectly customised to the syllabus being taught at your school.
                                    The detailed content, created as high-quality videos, will then be uploaded after
                                    consultation with the school, covering all relevant topics, concepts, and solutions to
                                    exercises.
                                </p> -->
                                <p class="btext-3 ">
                                    {{ course.subjectname }}
                                </p>
                                <div class="progress" style="height: 8px;">
                                    <div class="progress-bar" role="progressbar" :style="{ width: courseProgress + '%' }"
                                        :aria-valuenow="courseProgress" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <p class="btext-3 ">
                                    {{ courseProgress }}% Complete
                                </p>
                            </div>
                            <div class="col-md-12 p-0">
                                <hr style="margin-top: 30px;margin-bottom: 20px;" />
                            </div>
                            <div class="col-md-6 coursestart pl-0">
                                <common-svgs type="calender-circle"></common-svgs>
                                <p>
                                    <small>Start Date</small><br />
                                    {{ (course.startdate)?$moment(course.startdate).format('D MMMM YYYY'):'' }}
                                    <!-- 13 March 2023 -->
                                </p>
                            </div>
                            <div class="col-md-6 text-right">
                                <button v-if="courseProgress < 100 && (course.startdate)" type="button" @click="resumeCourse" class="btn btn-primary">Resume Course</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-16p" v-for="(chapter, index) in chapters">
                <accordian-toggle   :iscompleted="((chapter.totalcontents > 0 && chapter.completedcontents == chapter.totalcontents))" @slideClicked="getTopics(chapter.id, index)"
                    :title="'Chapter ' + (index + 1) + ' : ' + chapter.title" :desc="chapter.nooftopics + ' Topics'">
                    <template v-if="chapter.topics">   
                        <course-accordian v-for="(topic, topicindex) in chapter.topics" 
                            @startClicked="singleTopic(topic.id)" 
                            @completeClicked="singleTopic(topic.id)"
                            @resumeClicked="singleTopicResumed(topic.id,topic.lastTopicContent)"
                            @topicClicked="singleTopic(topic.id)"
                            :title="'Topic ' + (topicindex + 1) + ': '+topic.title" 
                            :lastTopicContent="topic.lastTopicContent"
                            :completedTopics="topic.completedTopics"
                            :status="((topic.completedTopics == topic.totalTopics) ? 1 : 0)"></course-accordian>
                    </template>
                </accordian-toggle>
            </div>
            <!-- <div class="col-md-12 mb-30p">
                <accordian-toggle
                    :title="'Chapter 2 : Integers, Rational Numbers & Real Numbers Lowest common multiple numbers and algebra'"
                    :desc="'4 Topics'">
                    <course-accordian @startClicked="singleTopic" @completeClicked="singleTopic"
                        :title="'Topic 1: Quadratic Equations'" :status="1"></course-accordian>
                    <course-accordian @startClicked="singleTopic" @completeClicked="singleTopic" :title="'Topic 2: Algebra'"
                        :status="0"></course-accordian>
                    <course-accordian @startClicked="singleTopic" @completeClicked="singleTopic"
                        :title="'Topic 3: Differential Equations'" :status="0"></course-accordian>
                </accordian-toggle>
            </div> -->
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import CommonSvgs from "@/components/CommonSvgs.vue";
import AccordianToggle from "@/components/Accordian.vue";
import CourseAccordian from "@/views/course/CourseAccordian.vue";
import { useRoute, useRouter } from 'vue-router';
import factory from '@/common/factory.js';
import { useAuthStore } from "@/store/auth.js";
import { functions } from "@/common/functions.js";
const auth = useAuthStore();
const router = useRouter();
const route = useRoute();


var course = ref({});
var chapters = ref([]);
var courseProgress = ref(0);

function getToken() {
    const token = {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.token,
        },
    };
    return token;
}
onMounted(() => {
    singleCourse();
    singleCourseProgress();
});

async function singleCourse() {
    var filter = {
        subject_course_id: route.params.id
    }
    const clsStd = new factory(getToken(), 'single-course', filter);
    await clsStd.getHttp().then((response) => {
        swal.close();
        if (response.data.status) {
            course.value = response.data.data.course;
            chapters.value = response.data.data.chapters;
        }
    });
}
async function singleCourseProgress() {
    var filter = {
        id: route.params.id
    }
    const clsStd = new factory(getToken(), 'single-course-progress', filter);
    await clsStd.getHttp().then((response) => {
        swal.close();
        if (response.data.status) {
            courseProgress.value = 0;
            if (response.data.data) {
                if (response.data.data.totaltopics > 0 && response.data.data.completedtopics) {

                    courseProgress.value = parseInt((response.data.data.completedtopics / response.data.data.totaltopics) * 100);
                }
            }
        }
    });
}
async function getTopics(topicid, index) {

    var filter = {
        id: topicid
    }
    const clsStd = new factory(getToken(), 'topics', filter);
    await clsStd.getHttp().then((response) => {
        swal.close();
        if (response.data.status) {
            chapters.value[index].topics = {};
            chapters.value[index].topics = response.data.data;
        }
    });
}

function singleTopic(topicid) {
    // router.push('/topic/'+topicid);
    router.push({
        path: '/topic/' + topicid,
        query: {
            courseid: route.params.id
        }
    });
}
function singleTopicResumed(topicid,contentid) {
    // router.push('/topic/'+topicid);
    router.push({
        path: '/topic/' + topicid,
        query: {
            courseid: route.params.id,
            content: contentid
        }
    });
}
async function resumeCourse() {
    var filter = {
        coursesubjectid: route.params.id,
        courseid: course.value.id
    }

    const callApi = new factory(getToken(), 'resume-course', filter);
    await callApi.getHttp().then((response) => {
        swal.close();
        if (response.data.status) {
            if (response.data.data) { 
                if (response.data.data.topic_id) { 
                    router.push({
                        path: '/topic/' + response.data.data.topic_id,
                        query: {
                            courseid: route.params.id,
                            content:response.data.data.topic_detail_id
                        }
                    });
                }
            }
        }
    });
    // router.push({
    //     path: '/topic/' + topicid,
    //     query: {
    //         courseid: route.params.id
    //     }
    // });
}
</script>
<style scoped>
.course-img {
    max-height: 150px;
    min-height: 150px;
}

.coursestart svg {
    width: 42px;
    height: 42px;
}

.coursestart small {
    font-family: var(--def-heading-fontfamily);
    font-weight: 400;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #717171;
}

.coursestart {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    font-family: var(--def-heading-fontfamily);
    font-weight: 400;
    font-size: 16px !important;
    line-height: 20px !important;

}

p {
    margin-bottom: 0px;
}

.coursepage-card {
    display: grid;
    padding: 32px 42px !important;
    column-gap: 32px;
}

.text-portion {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.custom-back-btn:hover {
    background: #D0DEFB;
    color: var(--primary);
}

.custom-back-btn {
    border-radius: 8px;
    padding: 6px 12px 6px 10px;
    column-gap: 8px;
    color: #9CA3AF;
}

.card {
    border-radius: 12px;
}

</style>