<template>
  <div class="container" style="padding-top: 170px;">
    <div class="row mb-5">
      <div class="col-md-6 offset-md-3">
        <info-box @btn-clicked="resendLink" btntext="Resend Link" svgtype="paper-plane" title="Verify your account"
          :shortdesc="'Activation link has been sent to ' + auth.userinfo.phone1 + '. Please click on the link to verify your account and start learning.'"></info-box>
      </div>
    </div>
  </div>
</template>
<script setup>
import InfoBox from "@/components/InfoBox.vue";
import { ref } from 'vue';
import factory from '@/common/factory.js';
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";

const auth = useAuthStore();

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + auth.token,
    },
  };
  return token;
}
 

const CheckVerification = new factory(getToken(), 'user-information');
CheckVerification.getHttp().then((response) => {
  if (response.data.status) {
    if (response.data.data.is_verified == 1) {
      auth.userinfo.is_verified = 1;
      // window.location.href = '/dashboard';
      window.location.href = auth.baseUrl+"dashboard";
    }
  }
});

async function resendLink() {
  var resendlinkparms = {
    username: auth.userinfo.username,
    token: auth.token
  }
  const VerifyAccount = new factory(getToken(), 'resend-activation-link', resendlinkparms);
  await VerifyAccount.postForm().then((response) => {
    if (response.data.status) {
      swal.fire('Done!', response.data.message, 'success');
    } else {
      swal.fire('Error!', response.data.message, 'error');
    }
  });
};

</script> 