<template >
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col-12"><h2 class="heading">Can’t find what you’re looking for?</h2></div>
      <div class="col-12"><p class="sub-heading">Our friendly team is always here to help. </p></div>
      <div class="col-12"><p class="contact"><common-svgs  type="phone-icon"></common-svgs> <a href="tel:02136490048">021-36490048</a></p></div>
    </div>
  </div>
</template>

<script >

import CommonSvgs from "@/components/CommonSvgs.vue";

export default {
 components: {
    CommonSvgs,
  }

};
</script>


<style scoped>

.heading{
color: var(--gray-900, #101828);
text-align: center;
font-family: Apercu;
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 44px !important;
}

.sub-heading{
  color: var(--gray-500, #667085);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px !important;  
}

.contact , a{
font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
color:#2463EB !important;

}

.container-fluid{
  padding-top:80px;
  padding-bottom:80px;
  background: #FFFFFF;
  border-bottom: 0.6px solid #00000033
}

</style>
