<template>
  <div :class="$attrs.class ?? 'col-md-6'">
    <div :class="($attrs.noformgroup) ? '' : 'mb-3'">
      <label v-if="label && modelValue" class="form-label" :for="id">{{ label }}</label>
      <div class="conditional-field">

        <input @keyup="emitKeyup" :id="id" ref="input" v-bind="{ ...$attrs, class: null }" class="form-control"
          :class="((label && modelValue) ? ' label-input ' : ' ')" :type="type" :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)" />
        <div v-if="iconConditionValue == 'warning' && modelValue" class="conditional-field-icon"></div>
        <div v-else-if="iconConditionValue == 'success' && modelValue"
          class="fa fa-check conditional-field-icon-success text-success" style="font-size: 24px;"></div>
      </div>
      <div v-if="(helpConditionalText != '' && helpConditionalText.length > 0)" class="form-error"><span
          class="error-icon"></span> Username already taken, Try another.</div>
      <small v-if="(helpConditionalText != '' && helpConditionalText.length > 0)" class="helpconditionalpretext">{{
        helpconditionalpretext }}</small> 
      <div v-if="(typeof error == 'object' && error)" class="form-error"><span class="error-icon"></span> {{ error[0] }}
      </div>
      <div v-if="(typeof error != 'object' && error)" class="form-error"><span class="error-icon"></span> {{ error }}
      </div>
      <small v-for="suggestions in helpConditionalText" @click="autofilfeild(suggestions)" class="atext-1">
        <span class="a-link">{{ suggestions }}</span>
      </small>
      <small v-if="$attrs.helptext" class=" atext-1">{{ $attrs.helptext }}</small>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  data() {
    return {
      iconCondition: this.condition,
      helpText: this.helpconditionaltext,
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${uuid()}`
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    error: String,
    label: String,
    modelValue: String,
    helpconditionaltext: [String, Array],
    helpconditionalpretext: [String],
    condition: {
      type: String,
      default: 'warning',
    },
  },
  computed: {
    iconConditionValue() {
      return this.iconCondition = this.condition;
    },
    helpConditionalText() {
      return this.helpText = this.helpconditionaltext;
    }
  },
  emits: ['update:modelValue', 'on-change', 'on-keyup'],
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
    emitChange() {
      this.$emit('change');
      this.$emit('blur');
    },
    emitKeyup() {
      // this.$emit('on-keyup');
      var newValue = this.modelValue;
      newValue = newValue.replace(/\s/g, "");
      newValue = newValue.replace(/[^a-z0-9._-]/gi, '');
      newValue = newValue.toLowerCase();

      this.$emit('update:modelValue', newValue);
      // return name;

    },
    autofilfeild(suggested) {
      this.$emit('update:modelValue', suggested)
      this.emitChange();
    }

  },
}
</script>
<style scoped>
.form-control {
  background: rgba(137, 147, 158, 0.05);
  border: 0.5px solid #ABBED1;
  border-radius: 8px;
  /* height: 48px; */
  /* padding: 12px 20px; */
  padding: 12px 12px 12px 16px;
  font-family: var(--def-fontfamily);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #4D4D4D;
}

.label-input {
  padding: 12px 12px 12px 16px;
}

.atext-1 {
  padding-right: 20px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: var(--primary);
}

.helpconditionalpretext {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
}

.conditional-field {
  position: relative;
}

.error-icon {
  content: "";
  background-image: url('/public/files/assets/img/error.webp');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  padding-right: 20px;

}

.conditional-field .conditional-field-icon {
  content: "";
  background-image: url('/public/files/assets/img/info-circle.svg');
  background-repeat: no-repeat;
  /* background-size: 16px 16px; adjust size as needed */
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
}

.conditional-field .conditional-field-icon-success {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
}

.conditional-field input[type="text"] {
  padding-right: 30px;
}

.form-error {
  padding-top: 9px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #E02B1D;
}

label {
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* padding-left: 20px;
    padding-top: 12px; */
  padding-top: 5px;
  padding-left: 18px;
  color: #4D4D4D;
  z-index: 1;
}</style>
