<template>
    <div style="padding-left: 12px;padding-right: 12px;" @click="courseclicked($attrs.dataid)" data-id="{{ $attrs.dataid??'' }}">
        <div class="card">
            <img :src="$attrs.img" class="card-img-top">
            <div class="card-body">
                <h4 class="truncate-text">{{ title }}</h4>
                <p class="card-text btext-3 mb-0 truncate-text" v-if="secondtitle">{{ secondtitle }}</p>
                <p class="card-text btext-3 mb-0 truncate-text">{{ desc }}</p>
                <span class="card-text">
                    <span class="text-info-icon">
                        <common-svgs :style="'width:16px;height:16px'" type="calender">
                        </common-svgs>
                    </span>
                    <span class="text-info-text btext-4">
                        Start Date: {{(startdate)?$moment(startdate).format('D MMMM YYYY'):'' }}
                    </span>

                </span>
                <div class="pt8 ">
                    <div class="progress " style="height: 8px;">
                        <div class="progress-bar" role="progressbar" :style="{width: progress+'%'}" :aria-valuenow="progress"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p class="btext-3 mb-0 pt8">
                        {{progress}}% Complete
                    </p>
                </div>
            </div>
        </div>
    </div>
</template> 
<script setup>
import { ref, defineEmits, defineProps, onUpdated } from 'vue';
import CommonSvgs from "@/components/CommonSvgs.vue";
var emit = defineEmits(['courseclicked']);
// totaltopics
// completedtopics
var props = defineProps(['title', 'desc','progress','startdate','secondtitle']);
function courseclicked(id) {
    emit('courseclicked', id);
}
var title = ref(props.title);
var secondtitle = ref(props.secondtitle);
var desc = ref(props.desc);
var startdate = ref(props.startdate);
var progress = ref(props.progress);

onUpdated(() => {
    title.value = props.title;
    secondtitle.value = props.secondtitle;
    desc.value = props.desc;
    startdate.value = props.startdate;
    progress.value = props.progress;
});

</script>
<style scoped>
.card-img-top {
    max-height: 150px;
    min-height: 150px;
    border-radius: 6px;
    object-fit: cover;
}

.card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
}
.card {
    border: none;
    margin-bottom: 45px;
    padding: 20px 16px;
    border-radius: 12px;
    cursor: pointer;
}

.card-text {
    font-size: 13px;
    line-height: 20px;
    padding-top: 8px;
}

.pt8 {
    padding-top: 8px;
}

@media (max-width: 915px) {}
</style>
   
  