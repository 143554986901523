<script setup>

import { appConsts } from "@/common/constants.js";
import SearchInput from '@/components/SearchInput.vue';
import CardBox from '@/components/CardBox.vue';
import SchoolBox from '@/components/school/SchoolBox.vue';
import Paginate from '@/components/Paginate.vue';
import CommonSvgs from '@/components/CommonSvgs.vue';
import factory from "@/common/factory.js";
import { useAuthStore } from "@/store/auth.js";
import { reactive, ref, onMounted } from 'vue';
import { functions } from '@/common/functions';
import SingleStudent from '../student/SingleStudent.vue';
var fileUrl = ref(appConsts.fileBaseUrl);

const auth = useAuthStore();

const myClasses = ref('');
const classCounts = ref(0);
const isClasses = ref(true);

function getToken() {
    const token = {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.token,
        },
    };
    return token;
}
let buttons = ref([]);
var totalRecords = ref(1);
var currentpage = ref(1);
var filterData = ref('');
var dropdownopen = ref(false);
var importbtn = ref(true);

async function classes() {
    var paginate = {
        page: currentpage.value,
        filter: filterData.value,
    }
    const classes = new factory(getToken(), 'classes', paginate);
    await classes.getHttp().then((response) => {
        swal.close();
        // console.log(response);
        if (response.status == 200) {
            classCounts.value = response.data.data.classes.total;
            myClasses.value = response.data.data.classes.data;
            totalRecords = response.data.data.pagination.total;
            if (classCounts.value > 0) {
                isClasses.value = true;
            }
            else {
                isClasses.value = false;
            }
        } else {
            if (response.data.message) {
                swal.fire('Error!', response.data.message, 'error')
            } else {
                swal.fire('Error!', 'Something went wrong!', 'error')
            }
        }
    });
};
onMounted(() => {
    $(document).ready(function () {
        $('.avoidclosing').on('hide.bs.dropdown', function (e) {

            if (e.clickEvent) {
                e.preventDefault();
            }
        })
    });
    classes();
    getImportInfo();
});

function openModal() {
    $('#singleStudentModal').modal('show');
}

function hideModal() {
    classes();
    $('#singleStudentModal').modal('hide');
}
function filterClass() {
    var searchvalue = $('.filinput').find('input').val();
    filterData.value = searchvalue;
    currentpage.value = 1;
    classes();
}

// Pagintaion

function BackPage() {
    currentpage.value = currentpage.value - 1;
    classes();
}
function ForwardPage() {
    currentpage.value = currentpage.value + 1;
    classes();
}
function pageNoClicked(pageno) {
    currentpage.value = pageno;
    classes();
}
function toggleSvgs() {
    if (dropdownopen.value) {
        dropdownopen.value = false;
    } else {
        dropdownopen.value = true;
    }
}
function openImportModal() {
    $('#import_students').modal('show');
    let supported_file_desc = document.getElementsByClassName('uploadportion-desc')[0];
    supported_file_desc.innerHTML = "Supported formats: csv";
}

function importFileSelected() {
    importbtn.value = false;
    let file_name = $('#realinputfile').val();
    checkFileFormat(file_name);
    let split_file_name = file_name.split("\\");
    let supported_file_desc = document.getElementsByClassName('uploadportion-desc')[0];
    file_name != '' ? supported_file_desc.innerHTML = split_file_name[2] : supported_file_desc.innerHTML = "Supported formats: csv";
}

function checkFileFormat(file_name) {
    let format = file_name.split('.')[1];
    let wrong_format = document.getElementsByClassName('wrong-format')[0];
    if (format != 'csv' && format != 'xlsx') {
        importbtn.value = true;
        // console.log("no csv and xlsx");
        wrong_format.style.display = 'block';
        return false;
    }
    else {
        wrong_format.style.display = 'none';
        importbtn.value = false;
    }
}

async function importData() {
    var file = $('.uploadfilefield')[0].files[0];
    var formData = new FormData();
    formData.append('name', file);

    const apiCall = new factory(getToken(), 'student-bulk-import', formData);
    await apiCall.postForm().then((response) => {
        $('#import_students').modal('hide');
        $('#dropdownMenuButton1').click();
        importbtn.value = true;
        $('#realinputfile').val('');
        if (response.data.status) {
            getImportInfo();
            classes();
        }
    });
}
var ups_details = ref(false);
function dispHideUpsDetail() {
    getImportInfo();
    if (ups_details.value) {
        ups_details.value = false;
    } else {
        ups_details.value = true;
    }
}

var importinfo = ref([]);
async function getImportInfo() {
    const apiCall = new factory(getToken(), 'import-info');
    await apiCall.getHttp().then((response) => {
        swal.close();
        if (response.data.status) {
            // console.log("import infoo",response.data);
            importinfo.value = response.data.data;
        }
    });
}
async function hideImport(id) {
    var filter = {
        id: id
    }
    const apiCall = new factory(getToken(), 'hide-import-info', filter);
    await apiCall.getHttp().then((response) => {
        swal.close();
        if (response) {
            getImportInfo();
        }
    });
}
var colorindex = ref(0);
function returnColor(){
    var abc =  [
    'bg-primary',
    'bg-warning',
    'bg-success',
    'bg-danger'
  ];
   if(colorindex.value > 3){
    colorindex.value = 0;
   }
   return abc[colorindex.value];
}

</script>
<template>
    <!-- Modal -->
    <div class="modal fade" id="singleStudentModal" aria-labelledby="singleStudentModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
                <div class="modal-header p-0">
                    <h2 class="modal-title" id="singleStudentModalLabel">Add a Student</h2>
                    <button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <p class="student_modal_text">Build learning for today, tomorrow, and beyond. </p>
                <single-student @form-submit="hideModal()"></single-student>
            </div>
        </div>
    </div>
    <div class="modal fade" id="import_students" aria-labelledby="singleStudentModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md" style="min-width: 570px;">
            <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
                <div class="modal-header p-0">
                    <h2 class="modal-title" id="singleStudentModalLabel">Add Students</h2>
                    <button type="button" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <p class="student_modal_text" style="margin-bottom: 40px;">Build learning for today, tomorrow, and beyond.
                </p>
                <div class="modal-body p-0" style="display:flex;gap:40px;flex-direction: column;">
                    <div class="downloadtemplate">
                        <div class="downloadtemplate-text">
                            <h4>Download Template</h4>
                            <span class="btext-3">Create list of students in spreadsheet and use existing class
                                names.</span>
                        </div>
                        <a href="SampleFile.xlsx" download>
                            <button class="btn btn-clean btn-clean-primary col-md-6" style="border-radius: 4px;">Download
                                Template</button>
                        </a>
                    </div>
                    <div class="uploadportion" id="file-drop-area">
                        <div class="uploadportion-text">Drag and drop, or <span class="text-primary clicknearfile"
                                style="cursor: pointer;">click to browse</span></div>
                        <div class="uploadportion-desc">Supported formats: csv</div>
                        <p class="text-danger mb-0 wrong-format" style="font-size: 12px; display: none">
                            <common-svgs type="not-found"></common-svgs>
                            Invalid Format
                        </p>
                        <input name="file" type="file" class="realinputfile uploadfilefield" id="realinputfile"
                            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="importFileSelected" style="display: none;" />
                    </div>

                    <button class="btn btn-primary" @click="importData" :disabled="importbtn"
                        style="padding: 16px 32px;">Continue</button>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <h4>All Classes({{ classCounts }})</h4>
    </div>
    <div class="col-md-6 text-right">
        <!-- <button class="btn btn-primary" @click="openModal">
            <common-svgs type="plus-icon"></common-svgs>
            Add Student
        </button> -->
        <div class="dropdown">
            <button class="btn btn-primary dropdown-toggle avoidclosing dropdownbtn" @click="toggleSvgs(event)"
                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <common-svgs v-if="dropdownopen" type="minus-icon" class="minus-icon"></common-svgs>
                <common-svgs v-else type="plus-icon" class="plus-icon"></common-svgs>
                Add Student
            </button>
            <ul class="dropdown-menu dropdownbtndata" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" href="#" @click="openModal">Single Student</a></li>
                <li><a class="dropdown-item" href="#" @click="openImportModal">Multiple Students</a></li>
            </ul>
        </div>
    </div>

    <div class="col-md-12 mt-3">
        <div class="uploadprogress" v-for="importdata in importinfo" style="margin-bottom: 10px;">
            <div class="uploadprogresssection">
                <div class="ups_fileinfo">
                    <common-svgs v-if="importdata.job_status != 1" :type="'clouudpload'"></common-svgs>
                    <common-svgs v-else :type="'import-complete'"></common-svgs>
                    <div class="ups_fileinfotext">
                        <span class="ups_fileinfo_file" style="width:100%;">{{ importdata.file_name }}</span>
                        <span v-if="importdata.job_status != 1" class="ups_fileinfo_status" style="width:100%;">In
                            Progress</span>
                        <span v-else-if="importdata.job_status == 1" class="ups_fileinfo_status text-success"
                            style="width:100%;">Completed</span>
                    </div>
                </div>
                <div v-if="importdata.job_status != 1" class="ups_recordinfo" @click="dispHideUpsDetail">
                    <span class="ups_recordinfo_text">
                        Total Students: {{ importdata.total_records }}
                    </span>
                    <span class="ups_recordinfo_icon">
                        <common-svgs v-if="ups_details" :type="'arrow-up'" :width="22" :height="22"></common-svgs>
                        <common-svgs v-if="!ups_details" :type="'arrow-down'" :width="22" :height="22"></common-svgs>
                    </span>
                </div>
                <div v-else class="ups_recordinfo">
                    <span class="ups_recordinfo_completetext">
                        <span class="ups_recordinfo_text">
                            Uploaded: {{ importdata.successfull }}/{{ importdata.total_records }}
                        </span>
                        <div class="d-flex">
                            <button :href="fileUrl+importdata.attachment_file" :download="importdata.attachment_title" v-if="importdata.failed>0 && importdata.job_status == 1" class="btn download-btn downloadfile"><common-svgs :type="'download-icon'"></common-svgs> Download</button>
                            <span class="ups_recordinfo_text text-danger">
                                Failed: {{ importdata.failed }}/{{ importdata.total_records }}
                            </span>
                        </div>
                    </span>
                    <span class="ups_recordinfo_icon">
                        <button type="button" class="btn ups_btn_remove" @click="hideImport(importdata.id)">
                            <common-svgs :type="'cross'"></common-svgs>
                        </button>

                    </span>
                </div>

            </div>
            <div v-if="ups_details && importdata.job_status != 1" class="ups_details">
                <ul style="    display: -webkit-inline-box;
            gap: 30px;">
                    <li style="list-style-type: none;">Uploaded {{ importdata.successfull }} / {{ importdata.total_records
                    }}</li>
                    <li>Pending {{ ((importdata.failed + importdata.successfull) - importdata.total_records) }} / {{
                        importdata.total_records }}</li>
                    <li class="text-danger">Failed {{ importdata.failed }} / {{ importdata.total_records }}</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-md-12 mt-3">
        <card-box :noPadding="true" class="p-0" :minimalpadding="true">
            <search-input noformgroup="true" class="col-md-6 filinput" placeholder="Find Class"
                @keyup.enter="filterClass($event)"></search-input>
            <div class="col-md-3">
                <button class="btn bg-fields-color btn-border" @click="filterClass($event)">
                    <common-svgs type="filter-icon"></common-svgs>
                    Filter
                </button>
            </div>

            <div class="col-md-12" style="margin-top: 30px;">
                <div class="row classes_grid">
                    <template v-if="isClasses" v-for="(classes, key) in myClasses" :key="key">
                        <router-link class="col-md-3 class-box"
                            :to="{ name: 'class-students', params: { id: classes.id } }">
                           <school-box :shortName="functions.shortNameGenerator(classes.name)"
                                :classHeading="functions.truncate(classes.name, 8)"
                                :classStudents="classes.class_students.length"
                                :classCourses="classes.class_courses_count.length" :bgColor="returnColor()">
                            </school-box>  
                            
                        </router-link>
                    </template>
                    <div v-else>
                        No Result Found
                    </div>
                </div>
            </div>
        </card-box>
        <div class="col-md-12">
            <div>
                <Paginate @back-btn="BackPage" @forward-btn="ForwardPage" :total="totalRecords" :perpage="8"
                    :currentpage="currentpage" @page-btn-clicked="pageNoClicked"></Paginate>
            </div>
        </div>
    </div>
</template>

<style scoped>
.uploadportion-text {
    color: #717171;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.uploadportion-desc {
    color: var(--black-30, #8B8D97);
    text-align: center;
    /* Paragraph 2/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.downloadtemplate {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.forword-backword-btn {
    border: none;
    background-color: ghostwhite;
}

.bg-fields-color {
    background-color: #F9FAFB;
}

.bg-button-color {
    background-color: #E5E7EB;
}

.btn-border {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    color: #717171;
}

.paginate-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 24px;
    height: 24px;
    color: black;
    margin-left: 6px;
}

.paginate-btn:hover {
    color: black;
}

.main-paginate-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 0px;
    gap: 20px;
    margin-top: 24px;
}

.left-arrow-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
}

.paginate_btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 6px;
}

.modal-header {
    border-bottom: none !important;
    /* padding: 1rem !important; */
    /* padding-bottom: 12px !important; */
    /* padding-left: 48px !important; */
}

.student_modal_text {
    color: #717171;
    font-size: 16px;
}

.modal-button-close {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 14px !important;
    border-radius: 12px;
    border: 1px solid rgba(77, 77, 77, 0.2);
}
.download-btn {
    background: #2463EB0D;
    color: #2463EB;
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 10px 4px 10px;
    margin-right: 8px;
}
</style>