<script setup>
import { defineProps, ref, onUpdated, onMounted } from 'vue';
import CommonSvgs from '@/components/CommonSvgs.vue';

let props = defineProps(["total", "perpage", "currentpage"]);
let emits = defineEmits(["back-btn", "forward-btn"]);
var totalRecords = ref(0);
var perPage = ref(props.perpage);
var numberofpage = ref(0);

var currentpage = ref(props.currentpage);
onMounted(() => {
    currentpage.value = props.currentpage;
    totalRecords.value = props.total;
    var perpagefloat = (totalRecords.value / perPage.value);
    if (!(perpagefloat === parseInt(perpagefloat))) {
        numberofpage.value = parseInt(perpagefloat) + 1;
    } else {
        numberofpage.value = parseInt(perpagefloat);
    }
    if (numberofpage.value < 1) {
        numberofpage.value = 1;
    }
});
onUpdated(() => {
    currentpage.value = props.currentpage;
    totalRecords.value = props.total;
    var perpagefloat = (totalRecords.value / perPage.value);
    if (!(perpagefloat === parseInt(perpagefloat))) {
        numberofpage.value = parseInt(perpagefloat) + 1;
    } else {
        numberofpage.value = parseInt(perpagefloat);
    }
    if (numberofpage.value < 1) {
        numberofpage.value = 1;
    }
})
function backPageBtn() {
    emits('back-btn');
}
function forwardPageBtn() {
    emits('forward-btn');
}
function pageBtnClicked(pagenumber) {
    emits('page-btn-clicked', pagenumber);
}
function shouldDisplayPage(index){
    const startPage = Math.floor((currentpage.value - 1) / 15) * 15;
    const endPage = Math.min(startPage + 14, totalRecords.value);
    return index >= startPage && index <= endPage;
}
</script>

<template>
    <div>
        <div class="row" v-if="totalRecords > perPage">
            <div class="col-md-12 offset-md-12 p-0 text-right">
                <div class="main-paginate-div">
                    <div>
                        <button class="forword-backword-btn" @click="backPageBtn"
                            :disabled="(currentpage == 1) ? true : false" style="margin-right: 34px;">
                            <common-svgs type="pagination-left-arrow-button"></common-svgs>
                        </button>
                    </div>
                    <div class="paginate_btns"  >
                        <span v-if="(currentpage >= 16)">...</span>
                        <template v-for="(page,index) in numberofpage">
                            <button  v-if="shouldDisplayPage(index)"
                                :class="(currentpage == page) ? 'btn-primary text-light' : ''" class="btn paginate-btn"
                                @click="pageBtnClicked(page)">{{ page
                                }}</button>
                        </template>
                        
                        <span v-if="(numberofpage > (currentpage+15))">...</span>
                    </div>
                    <div>
                        <button class="forword-backword-btn" @click="forwardPageBtn"
                            :disabled="(currentpage == numberofpage) ? true : false" style="margin-left: 34px;">
                            <common-svgs type="pagination-right-arrow-button"></common-svgs>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.forword-backword-btn:disabled {
    background: ghostwhite !important;
    opacity: 0.3 !important;
    cursor: not-allowed;
}

.forword-backword-btn {
    border: none;
    background-color: ghostwhite;
}

.bg-fields-color {
    background-color: #F9FAFB;
}

.bg-button-color {
    background-color: #E5E7EB;
}

.btn-border {
    border: 1px solid #E5E7EB;
}

.paginate-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 24px;
    height: 24px;
    color: black;
    margin-left: 6px;
}

.paginate-btn:hover {
    color: black;
}

.main-paginate-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 0px;
    gap: 20px;
    margin-top: 24px;
}

.left-arrow-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
}

.paginate_btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 6px;
}
</style>