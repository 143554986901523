import {
  ref,
  reactive
} from "vue";
import {
  appConsts
} from "./constants.js";

export default class {
  token = ref("");
  endURL = ref("");
  data = reactive([]);

  constructor(token = null, endURL, data = null) {
    this.token = token;
    this.endURL = endURL;
    this.data = data;
  }

  async postForm(noloader = false) {
    try {
      if (!noloader) {
        Swal.fire({
          title: "Loading....",
          text: "Please Wait, This may take a while...",
          iconHtml: `<div class="loader-block">
      <svg id="loader2" viewBox="0 0 100 100">
      <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
      </svg>
      </div>`,
          showConfirmButton: false,
        });
      }
      const url = appConsts.baseURL + this.endURL;
      return await axios.post(url, this.data, this.token);
    } catch (error) {
      if (error != null) {
        if (error.response != null) {
          if (error.response.data != null) {
            if (error.response.data.message != null) {
              var msg = error.response.data.message;
              if (error.response.data.data != null) {
                msg += '<br />' + JSON.stringify(error.response.data.data);
              }
              swal.fire("Error!", msg, "error");
            } else {
              swal.fire("Error!", "Invalid Reponse from Server", "error");
            }
          } else {
            swal.fire("Error!", "Invalid Reponse from Server", "error");
          }
        } else {
          swal.fire("Error!", "Invalid Reponse from Server", "error");
        }
      } else {
        swal.fire("Error!", "Invalid Reponse from Server", "error");
      }
    }
  }
  async putForm() {
    try {
      Swal.fire({
        title: "Loading....",
        text: "Please Wait, This may take a while...",
        iconHtml: `<div class="loader-block">
      <svg id="loader2" viewBox="0 0 100 100">
      <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
      </svg>
      </div>`,
        showConfirmButton: false,
      });
      const url = appConsts.baseURL + this.endURL;
      return await axios.put(url, this.data, this.token);
    } catch (error) {
      if (error != null) {
        if (error.response != null) {
          if (error.response.data != null) {
            if (error.response.data.message != null) {
              var msg = error.response.data.message;
              if (error.response.data.data != null) {
                msg += JSON.stringify(error.response.data.data);
              }
              swal.fire("Error!", msg, "error");
            } else {
              swal.fire("Error!", "Invalid Reponse from Server", "error");
            }
          } else {
            swal.fire("Error!", "Invalid Reponse from Server", "error");
          }
        } else {
          swal.fire("Error!", "Invalid Reponse from Server", "error");
        }
      } else {
        swal.fire("Error!", "Invalid Reponse from Server", "error");
      }
    }
  }
  async getHttp() {
    try {
      const url = appConsts.baseURL + this.endURL;
      var encoded = '';
      if (this.data) {
        encoded = '?';
        encoded = encoded + Object.entries(this.data).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&");
      }
      return await axios.get(url + encoded, this.token);
    } catch (error) {
      if (error != null) {
        if (error.response != null) {
          if (error.response.data != null) {
            if (error.response.message != null) {
              // swal.fire("Error!", error.response.message, "error");
            } else {
              // swal.fire("Error!", "Invalid Reponse from Server", "error");
            }
          } else {
            // swal.fire("Error!", "Invalid Reponse from Server", "error");
          }
        } else {
          // swal.fire("Error!", "Invalid Reponse from Server", "error");
        }
      } else {
        // swal.fire("Error!", "Invalid Reponse from Server", "error");
      }
    }
  }
  async deleteHttp() {
    try {
      const url = appConsts.baseURL + this.endURL;
      return await axios.delete(url, this.token);
    } catch (error) {
      if (error != null) {
        if (error.response != null) {
          if (error.response.data != null) {
            if (error.response.data.message != null) {
              swal.fire("Error!", error.response.data.message, "error");
            } else {
              swal.fire("Error!", "Invalid Reponse from Server", "error");
            }
          } else {
            swal.fire("Error!", "Invalid Reponse from Server", "error");
          }
        } else {
          swal.fire("Error!", "Invalid Reponse from Server", "error");
        }
      } else {
        swal.fire("Error!", "Invalid Reponse from Server", "error");
      }
    }

  }
}