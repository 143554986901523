<template>
    <div class="topic-content" :class="(isactive ? 'topic-active' : '')" @click="topicClicked">
        <div class="topic-content-icon">
            <common-svgs v-if="iscomplete" style="margin: 0 auto;" :fill="'#1F8354'" :stroke="'#1F8354'"
                :type="'course-complete'"></common-svgs>
            <common-svgs v-else style="margin: 0 auto;" :fill="'white'" :stroke="'#1F8354'"
                :type="'tick-circle'"></common-svgs>
        </div>
        <div class="topic-data">
            <div class="topic-datatitle">
                
                <b class="btext-3" style="
                overflow: hidden; 
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow-wrap: anywhere;
                ">{{ title }}</b>
            </div>
            <div class="topic-datainfo">
                <div class="topic-datatype">
                    <common-svgs v-if="type == 'Video'" style="margin: 0 auto;" :fill="(isactive ? '#2463EBCC' : '#ABBED1')"
                        :stroke="(isactive ? '#1F8354' : '')" :type="'video-icon'"></common-svgs>
                    <common-svgs v-else="type == 'Video'" :fill="(isactive ? '#2463EBCC' : '#ABBED1')"
                        :stroke="(isactive ? '#1F8354' : '')" style="margin: 0 auto;" :class="'document-icon'"
                        :type="'document-icon'"></common-svgs>
                    <b class="btext-3" style="color:#717171;">{{ (type == 'Video') ? 'Video' : 'Content' }}</b>
                </div>
                <b class="btext-3" v-if="type == 'Video'" style="color:#717171;">{{ ((length > 0) ? parseInt(length / 60) : 0) }} min</b>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineEmits, defineProps, onUpdated, ref, onMounted } from "vue";

import CommonSvgs from "@/components/CommonSvgs.vue";
var props = defineProps(['topicid', 'title', 'iscomplete', 'isactive', 'type', 'length']);
var emits = defineEmits(['topic-clicked']);

var title = ref('');
var iscomplete = ref(false);
var isactive = ref(false);
var type = ref('');
var length = ref('');
var topicid = ref('');


onMounted(() => {
    topicid.value = props.topicid;
    title.value = props.title;
    iscomplete.value = props.iscomplete;
    isactive.value = props.isactive;
    type.value = props.type;
    length.value = props.length;
});
onUpdated(() => {
    iscomplete.value = props.iscomplete;
    isactive.value = props.isactive;
});
function topicClicked() {
    emits('topic-clicked', topicid.value)
}
</script> 
<style scoped>
.topic-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 24px;
    gap: 12px;
    cursor: pointer;
    width: 100%;
}

.topic-active .video-icon svg,
.topic-active .video-icon svg path {
    fill: #2463EBCC !important;
}

.topic-active {
    background: #FFFFFF;

    border-left: 5px solid #2463EB;
    border-radius: 12px;
}



.topic-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 0.200001px;
    gap: 8px;
}

.topic-datatype {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
}

.topic-datainfo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 97px;

}
</style>