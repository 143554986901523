<template>
  <div class="container" style="padding-top: 170px;">
    <div class="row mb-5">
      <div class="col-md-6 offset-md-3">
        <card-box v-if="step == 1" title="Join as a Parent" backtitle="Back" @back-btn-clicked="stepOneBack"
          shortdesc="Build learning for today, tomorrow, and beyond.">
          <!-- <date-input type="date" :minDate="$moment($moment()).subtract(18, 'years').format('YYYY-MM-DD')"
            class="col-md-12 dob" v-model="form.dob" label="Date of Birth" placeholder="Date of Birth"></date-input> -->
          <text-input class="col-md-12" v-model="form.full_name" label="Full Name" :error="error.full_name"
            placeholder="Full Name"></text-input>
          <conditional-input @focus="revalidate" :error="error.username" @blur="checkUserName" :condition="feildCondition"
            :helpconditionaltext="conditionalHelpText" :helpconditionalpretext="'Suggested : '" class="col-md-12 disableSpaces"
            v-model="form.username" label="Username" placeholder="Username"></conditional-input>
          <select-box class="col-md-12" v-model="form.gender" label="Gender" :nosearch="true" placeholder="Gender">
            <option value="MALE">Male</option>
            <option value="FEMALE">Female</option>
          </select-box>
          <!-- <select-box class="col-md-12" v-model="form.province_id" placeholder="Province" 
            @change="cityProvinceAutomation('province');">
            <option v-for="province in provinces" :value="province.id">{{ province.name }}</option>
          </select-box> -->
          <select-box class="col-md-12" v-model="form.city_id" label="City" placeholder="City" :error="error.city_id"
            @change="cityProvinceAutomation('city');">
            <option v-for="city in fil_cities" :value="city.id">{{ city.name }}</option>
          </select-box>
          <text-input @change="validate();" class="col-md-12" type="phone" v-model="form.phone1" :error="error.phone1"
            maxLength="11" label="Phone No" placeholder="Phone No"></text-input>
          <!-- :error="emailError" -->
          <text-input @change="validate();" class="col-md-12" v-model="form.email" :error="error.email"
            label="Email Address (Optional)" placeholder="Email Address (Optional)"></text-input>
          <password-input @blur="checkPassword" @change="validate();" v-model="form.password"
            class="col-md-12 passwordfeild" placeholder="Enter Password" label="Enter Password"
            helptext="Your password can be any combination of letters, numbers, and symbols."></password-input>

          <password-input :error="error.password" @blur="checkPassword" @change="validate();"
            v-model="form.password_confirmation" class="col-md-12 passwordfeild" placeholder="Confirm Password"
            label="Confirm Password"></password-input>

          <check-box class="col-md-12" @checkbox-clicked="termselected">
            By creating an account, you agree to the <a href="javascript:void(0);"
              onclick="openModalJs('#termscondition')" class="a-link" style="font-size: 14px;">Terms of
              Service</a> and Honor Code and <a href="javascript:void(0);" onclick="openModalJs('#privacypolicy')"
              class="a-link" style="font-size: 14px;">Privacy Policy</a>.
          </check-box>


          <div class="col-md-12 mb-3">
            <button class="btn btn-primary btn-block" :disabled="isValid" @click="signupParent">Continue</button>
          </div>
        </card-box>


      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import factory from '@/common/factory.js';
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";


import DateInput from "@/components/DateInput.vue";
import TextInput from "@/components/TextInput.vue";
import ConditionalInput from "@/components/ConditionalInput.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import CardBox from "@/components/CardBox.vue";
import CheckBox from "@/components/CheckBox.vue";

const auth = useAuthStore();
const router = useRouter()
var isValid = ref(true);
var iaccept = ref(false);
var feildCondition = ref('warning');
var inValidUsername = ref(false);
var passwordError = ref('');
var emailError = ref('');
var conditionalHelpText = ref([]);
import { functions } from '@/common/functions.js';

var form = ref({
  dob: '',
  full_name: '',
  username: "",
  gender: '',
  phone1: '',
  email: '',
  password: '',
  password_confirmation: '',
  province_id: '',
  city_id: '',
});
var step = ref(1);
var error = ref({});
function termselected(condition) {
  iaccept.value = condition;
  validate();
}
function checkPassword() {
  if(form.value.password_confirmation.length == 0)
  {
    error.value.password = '';
  }
  else
  {
    if (((form.value.password_confirmation) != '' || (form.value.password) != '') && form.value.password != form.value.password_confirmation) {
      error.value.password = "Password & Confirm Password doesn't match."
    } else {
      error.value.password = ""
    }
  }
}

function validate() {
  isValid.value = !iaccept.value;
  error.value.full_name = '';
  // if (form.value.dob == '') {
  //   isValid.value = true;
  //   // $('.dob').find('input').addClass('error-border');
  // } else {
  //   // $('.dob').find('input').removeClass('error-border');
  // }
  if (form.value.username == '' || !inValidUsername.value) {
    isValid.value = true;
    // $('.user_name').find('input').addClass('error-border');
  } else {
    // $('.user_name').find('input').removeClass('error-border');
  }
  if ((form.value.password_confirmation) == '' && form.value.password != form.value.password_confirmation) {
    isValid.value = true;
    // $('.passwordfeild').find('input').addClass('error-border');
  } else {
    // $('.passwordfeild').find('input').removeClass('error-border');

  }
  error.value.phone1 = '';
  if (form.value.phone1 != '') {
    if (form.value.phone1.length < 11) {
      isValid.value = true;
      error.value.phone1 = 'Phone Number should be atleast 11 Characters.';
    }
  } else {
    isValid.value = true;
  }
  if ((form.value.email) != '') {
    if (!validateEmail(form.value.email)) {
      isValid.value = true;
      emailError.value = 'Invalid Email.'
      error.value.email = 'Invalid Email.'
    } else {
      error.value.email = ''
      emailError.value = ''

    }
  } else {
    emailError.value = ''
    error.value.email = ''
  }

  error.value.username = '';
  if (!inValidUsername.value) {
    isValid.value = true;
    if(form.value.username != '' && form.value.username.length <= 4){
      error.value.username = 'Username should be atleast 5 Characters.';
    }
  }
  // 
}

var provinces = ref([]);

var cities = ref([]);
var fil_cities = ref([]);


function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
}

var ProvincesCities = new factory(getToken(), 'provinces-cities');
ProvincesCities.getHttp().then((response) => {
  if (response.data.status) {
    provinces.value = provinces.value = response.data.data.provinces;
    fil_cities.value = cities.value = response.data.data.cities;
  }
});


async function signupParent() {
  const SignUp = new factory(getToken(), 'parent-signup', form.value);
  await SignUp.postForm().then((response) => {
    swal.close();
    if (response.data.status) {
      auth.token = response.data.data.token;
      auth.userinfo = response.data.data;
      swal.fire('Done!', 'Sign up Successful.', 'success');
      setTimeout(() => {
        router.push('/invite-childs');
      }, 300);
    } else {
      if (response.data.message) {
        // error.value = functions.errorResponse({error:response.data.message.customMessages});
        error.value = functions.errorResponse({ error: response.data.message });
      } else {
        swal.fire('Error!', 'Something went wrong!', 'error')
      }
    }
  });
};
function cityProvinceAutomation(condition) {
  if (condition == 'city') {
    var selectedcity = cities.value.find((item) => (item.id == form.value.city_id));
    var selectedprovince = provinces.value.find((item) => (item.id == selectedcity.province_id));
    form.value.province_id = selectedprovince.id;
  } else {
    fil_cities.value = cities.value.filter((item) => (item.province_id == form.value.province_id));

  }
}
function revalidate() {
  feildCondition.value = 'none';
  inValidUsername.value = false;
  isValid.value = true;
}
async function checkUserName() {
  feildCondition.value = 'warning';
  if (form.value.username.trim() == '' || form.value.username.length <= 4) {
    feildCondition.value = 'warning';
    inValidUsername.value = false;
    validate();
    // checkStepTwoValidation();
    return false;
  }
  var checkuser = {
    'full_name': form.value.full_name,
    'user_name': form.value.username
  }
  const SignUp = new factory(getToken(), 'check-username-availability', checkuser);
  await SignUp.getHttp().then((response) => {

    // if (response.data.data != null && response.data.data != "null") {
    if (response.data.status) {
      feildCondition.value = 'warning';
      inValidUsername.value = false;
      conditionalHelpText.value = response.data.data.suggested_user_names;
    } else {
      inValidUsername.value = true;
      feildCondition.value = 'success';
      conditionalHelpText.value = [];
    }
    validate();
  });
}


function stepOneBack() {
  router.push('/');
} 
</script>