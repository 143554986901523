<script setup>
import { defineProps, ref, onUpdated, defineEmits } from 'vue';
import factory from '@/common/factory.js';
import { useAuthStore } from "@/store/auth.js";

const props = defineProps(["full_name", "school_name", "id", "age", "isModal", "invitations", "bgColor"]);
const emit = defineEmits('getStudents', 'myStudents');
const studentInvitations = ref(props.invitations);
const auth = useAuthStore();
var student_id = ref(props.id);
const clr = ref('#FAFAFA');
var words_count = ref(0);
var is_other = ref(false);
var reason = ref('');
var is_modal = ref(props.isModal);
var status = ref();

onUpdated(() => {
     student_id.value = props.id;
     studentInvitations.value = props.invitations;
     status.value = studentInvitations.value.length>0 ? studentInvitations.value[0].pivot.status : '';
});
function getToken() {
     const token = {
          headers: {
          // 'Content-Type': 'application/json',
               'Content-Type': 'application/x-www-form-urlencoded',
               'Authorization': 'Bearer ' + auth.token,
          },
     };
     return token;
}

async function inviteStudent() {
     var form = {
          'student_id': student_id.value,
     };
     const invitation = new factory(getToken(), 'invite-student', form);
     await invitation.postForm().then((response) => {
          if (response.data.status) {
               console.log(response.data.status);
               swal.fire('Done!', 'Invitation Send to the Student Successfully.', 'success');
               $('#foundChild').modal('hide');
               emit('getStudents');
               emit('myStudents');
          }
          else {
               swal.fire('Error!', 'Something went wrong.', 'error');
          }
     });
};

function wordsCount(event) {
     let counts = event.target.value.length;
     let count_span = document.getElementById('words_count');
     count_span.innerHTML = parseInt(counts);
}

function parentReport (student_id) {
     $('#foundChild').modal('hide');
     $('#student_id').val(student_id);
     $('#reportModal').modal('show');
     $(document).on('click', 'input[name="report_inputs"]', function () {
          $('input[name="report_inputs"]').not(this).prop('checked', false);
     });
}

function showTextArea (e)
{
     if($(e.target).val() == 'other')
     {
          is_other.value = !is_other.value;
          reason.value = '';
     }
     else
     {
          is_other.value = false;
          reason.value = $(e.target).val() ;
     }
}

$(document).on('click', '.cancel-btn', function(){
     closeModalWithContent();
});

async function submitReport() {
     let student_id = $('#student_id').val();
     var report_data = {
          content: reason.value,
          student_id: student_id
     }
     const report = new factory(getToken(), 'store-report', report_data);
     await report.postForm().then((response) => {
     if (response.data.status) {
          is_other.value = false;
          $('#reportModal').modal('hide');
          $('.report_form')[0].reset()
          swal.fire('Done!', 'Student Reported.', 'success');
          emit('getStudents');
     } 
     else {
          swal.fire('Error!', 'Please Select Reason.', 'error');
     }
  });
};

function closeModalWithContent()
{
     $('#reportModal').modal('hide');
     $('.report_form')[0].reset()
     is_other.value = false;
}

</script>

<template>
     <div class="card-body rounded-border " :style="[ bgColor ? {backgroundColor: '#FAFAFA !important'}: {backgroundColor: '#FFFFFF !important'}]">
          <div class="p-0" :class="isModal ? 'col-md-2' : 'col-md-1'">
               <img src="../../../public/img/student_icon.png" alt="" class="img_rounded" style="width: 100%;">
          </div>
          <div class="mt-2 content" :class="isModal ? 'col-md-5' : 'col-md-6'">
               <p class="name mb-0">{{ full_name }}</p>
               <p class="years_old mb-0">{{ age }} years old</p>
               <p class="school_name mb-0">{{ school_name }}</p>
          </div>
          <div v-if="studentInvitations == 0 || studentInvitations==[]" class="col-md-5 buttons_group_else">
               <button  v-if="!is_modal" class="btn text-danger report-btn" @click="parentReport(student_id)">Report</button>
               <button class="btn btn-primary invite_child_btn" @click="inviteStudent()">Invite Child</button>
          </div>
          <div v-else class="col-md-5 buttons_group_else">
               <button v-if="status == 'Accepted'" class="btn btn-primary" disabled>Already Child</button>
               <button v-else class="btn btn-primary resend_button" @click="inviteStudent()">Resend Invite</button>
          </div>
     </div>

<!-- Modal -->

<div class="modal fade " id="reportModal" aria-labelledby="reportLabel" aria-hidden="true">
     <div class="modal-dialog  modal-dialog-centered modal-width-487">
          <div class="modal-content" style="padding: 48px !important; border-radius: 12px !important;">
               <div class="modal-header p-0">
                    <h2 class="modal-title" id="reportLabel">Report</h2>
                    <button type="button" @click="closeModalWithContent()" class="btn-close p-0 m-0 modal-button-close" data-bs-dismiss="modal"
                         aria-label="Close">
                    </button>
                    <!-- <button type="button" class="btn custom-back-btn" :id="$attrs.id" @click="formInputReset()">
                    <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                    back
                    </button> -->
               </div>
               <p class="understand_happening mb-0">Help us understand what’s happening </p>
               <form @submit.prevent="submitReport" class="report_form">
                    <div>
                         <input type="hidden" name="student_id" id="student_id">
                         <input type="checkbox" class="form-check-input" data-text=""  @click="showTextArea($event)" name="report_inputs" value="This is not my child">
                         <span class="fs-14 ml-20">This is not my child</span>
                    </div>
                    <div class="mt-16">
                         <input type="checkbox" class="form-check-input"  @click="showTextArea($event)" name="report_inputs" value="i am afraid my number is being misused">
                         <span class="fs-14 ml-20">I am afraid my number is being misused</span>
                    </div>
                    <div class="mt-16">
                         <input type="checkbox" class="form-check-input" @click="showTextArea($event)" name="report_inputs" value="other">
                         <span class="fs-14 ml-20">Other (Fill in the reason below).</span>
                    </div>
                    <div v-if="is_other">
                         <div class="mt-16">
                              <text-area-input></text-area-input>
                              <textarea v-model="reason" class="form-control other_reason" @keyup="wordsCount($event)" id="other_reason" name="other_reason" maxlength="200" rows="5" placeholder="Type Message Here"></textarea>
                         </div>
                         <div class="d-flex justify-content-end mt-8">
                              <span class="fs-12 gray"><span id="words_count">{{ words_count }}</span>/200</span>
                         </div>
                    </div>
                    <div class="my-btns">
                         <button type="button" class="btn btn-secodary w-50 br-4 cancel-btn" @click="closeModalWithContent()">Cancel</button>
                         <button class="btn btn-primary w-50 br-4">Submit</button>
                    </div>
               </form>
          </div>
     </div>
</div>
</template>

<style scoped>
.card {
     background: #FFFFFF;
     box-shadow: 0px 15px 30px rgba(186, 186, 186, 0.1);
     border-radius: 12px;
}

.custom-back-btn:hover {
     background: #D0DEFB;
     color: var(--primary);
}

.custom-back-btn {
     border-radius: 8px;
     padding: 6px 12px 6px 10px;
     gap: 8px;
     color: #9CA3AF;
}

.card-body {
     padding: 16px;
     background: #FFFFFF !important;
     display: flex;
     align-items: center;
     margin-bottom: 12px;
}

.card-body .bgimg {
     position: absolute;
     right: 0px;
     top: 0px;
}

.content {
     padding-left: 12px !important;
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     gap: 4px;
}

.name {
     font-size: 16px !important;
     color: #000000 !important;
     line-height: 19px !important;
}

.years_old {
     color: #717171 !important;
     font-size: 12px !important;
}

.school_name {
     font-size: 12px !important;
     color: #212121 !important;
}

.delete_btn {
     color: #89939E !important;
     background-color: inherit;
     border-color: snow;
}

.invite_child_btn {
     background: rgba(36, 99, 235, 0.1);
     font-size: 14px !important;
     color: #2463EB !important;
     border-radius: 4px !important;
     border: none;
     padding: 8px 24px !important;
}

.buttons_group {
     display: flex;
     align-items: center;
     justify-content: center;
}

.buttons_group_else {
     display: flex;
     align-items: end;
     justify-content: end;
}
.rounded-border {
     border-radius: 12px !important;
}
.resend_button {
     background: inherit;
     color: #2463EB;
     font-size: 14px !important;
     border-radius: 4px !important;
     border: none;
     padding: 8px 24px !important;
}
.report-btn {
     font-size: 16px !important;
     font-weight: 400 !important;
}
.report-btn:focus,.report-btn:active {
   outline: none !important;
   box-shadow: none;
}
.modal-width-487 {
     max-width: 487px !important;
}
.modal-button-close {
    /* position: absolute;
    right: 20px;
    top: 20px; */
    padding: 10px !important;
    border-radius: 12px;
    border: 1px solid rgba(77, 77, 77, 0.2);
    margin-top: -80px !important;
    margin-right: -20px !important;
}
.understand_happening  {
     /* margin-top: 12px !important; */
     font-size: 16px !important;
     font-weight: 400;
}
.modal-header {
     border-bottom: 0px !important;
}
.form-check-input:checked[type=checkbox] {
     background-image: none !important;
}
.report_form {
     margin-top: 24px !important;
}
.ml-20 {
     margin-left: 20px !important;
}
.mt-16 {
     margin-top: 16px !important;
}
.fs-14 {
     font-size: 14px !important;
}
.fs-12 {
     font-size: 12px !important;
}
.gray {
     color: #89939E !important;
}
.mt-8 {
     margin-top: 8px !important;
}

.my-btns {
     display: flex;
     justify-content: space-between;
     margin-top: 56px !important; 
}
.br-4 {
     border-radius: 4PX !important;
}
.cancel-btn {
     border: 1px solid #D0D5DD !important;
     margin-right: 12px !important;
}
</style>