<template>
  <div class="row  ">
    <div class="headingblock">
      <h2>{{ $attrs.heading }} <u>{{ $attrs.headinghighlight }}</u><u class="questionmark">{{ $attrs.headinghighlightend }}</u></h2>
      <h6>{{ $attrs.shortdesc }}</h6>
    </div>
  </div>
</template>
<style scoped>
.headingblock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  row-gap: 12px;
  isolation: isolate;
  margin-top: 150px;
  margin-bottom: 72px;
}

h2 u {
  border-bottom: 2px solid var(--primary);
  text-decoration: none;
}
.questionmark{
  font-family: 'Arbutus Slab';
  font-weight: 400;
  font-size: 40px;
  line-height: 44px; 
}

@media (max-width: 915px) {
  .headingblock h6{
    padding-left: 40px;
    padding-right: 40px;
  }
  .headingblock{
    margin-top: 72px;
  }
  .questionmark{
    
    font-size: 30px;

  }

}
</style>
 
