<template>
     <form @submit.prevent="addChild()" id="addNewChildForm">
       <div class="row">
         <div class="col-md-12">
              
              <text-input @change="validate" class="col-md-12 fullname" v-model="form.full_name" :error="error.full_name" label="Full Name"  placeholder="Full Name"></text-input>
             <date-input @on-change="validate" type="text dob" label="Date of Birth" class="col-md-12" :minDate="$moment($moment()).subtract(4, 'years').format('YYYY-MM-DD')" v-model="form.dob" placeholder="Date of Birth"></date-input>

             <text-input @change="validate();" class="col-md-12" v-model="form.email" :error="emailError"
            label="Email Address (Optional)" placeholder="Email Address (Optional)"></text-input>

             <!-- <text-input class="col-md-12" v-model="form.email" :error="email" placeholder="Email Address (Optional)" label="Email Address (Optional)"></text-input> -->
             <select-box class="col-md-12 cities" id="city" v-model="form.city_id" placeholder="City"  label="City"
               @do-change="validate();citySelect(); checkStepTwoValidation();" required>
               <option v-for="city in fil_cities" :value="city.id">{{ city.name }}</option>
             </select-box>
             <select-box class="col-md-12 schools" id="school" v-model="form.school_id" placeholder="School"  label="School"
               @do-change="validate(); schoolSelect(); checkStepTwoValidation();" required>
               <option v-for="school in schools" :value="school.id">{{ school.name }}</option>
             </select-box>
             <select-box class="col-md-12 classes" id="classes" v-model="form.class_id" placeholder="Class" label="Class"
               @do-change="validate(); checkStepTwoValidation();" required>
               <option v-for="cl in fil_classes" :value="cl.id">{{ cl.name }}</option>
             </select-box>
             <check-box id="terms" class="col-md-12" style="margin-bottom: 12px" v-model="terms"  @checkbox-clicked="termselected">
               By creating an account, you agree to the <a href="#" class="a-link" style="font-size: 14px;">Terms of
                 Service</a> and Honor Code and <a href="#" class="a-link" style="font-size: 14px;">Privacy Policy</a>.
             </check-box>
   
   
             <div class="col-md-12" style="padding-top: 24px !important; cursor: pointer;"> 
               <!-- <button class="btn btn-primary btn-block" :disabled="isValid" data-bs-toggle="modal" data-bs-target="#accountCreated">Create</button> -->
               <button class="btn continue-btn btn-block fs-18" :disabled="isValid">Create</button>
             </div>
   
         </div>
       </div>
     </form>
   </template>
   
   <script setup>
   import { ref, defineEmits } from 'vue';
   import factory from '@/common/factory.js';
   import { useRouter } from 'vue-router';
   import { useAuthStore } from "@/store/auth.js";   
   import DateInput from "@/components/DateInput.vue";
   import moment from "moment";
   import TextInput from "@/components/TextInput.vue";
   import SelectBox from "@/components/SelectBox.vue";
   import CheckBox from "@/components/CheckBox.vue";
   import {functions} from '@/common/functions.js';

   const emit = defineEmits('UsernamePassword', 'getStudents', 'myStudents');
    // const emit = defineEmits('getStudents', 'myStudents');
   
   const auth = useAuthStore(); 
   const router = useRouter();
   var isValid = ref(true);
   var inValidUsername = ref(false);
   var isInValidTwo = ref(true);
   var iaccept = ref(false);
   var feildCondition = ref('warning');
   var conditionalHelpText = ref([]);
   const username = ref('');
   const password = ref('');
   var provinces = ref([]);
   var error = ref({});
   var cities = ref([]);
   var fil_cities = ref([]);
  var emailError = ref('');

   
   var schools = ref([]);
   
   var classes = ref([]);
   var fil_classes = ref([]);

   var form = ref({
     dob: '',
     full_name: '',
     phone1: '',
     email: '',
     city_id: '',
     school_id: '',
     class_id: '',
     pass: ''
   });
   var terms = ref(false);
   var step = ref(1);
   
   function getToken() {
     const token = {
       headers: {
         // 'Content-Type': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
         'Authorization': 'Bearer ' + auth.token,
       },
     };
     return token;
   }

  //  function
   async function addChild() {
      var random_pass = generatePassword();
      form.value.dob = moment(form.value.dob).format("YYYY-MM-DD");
      form.value.pass = random_pass; 
      const newChild = new factory(getToken(), 'add-child-association-with-parent', form.value);
      await newChild.postForm().then((response) => {
        // console.log("Child Created", response.data.data);
        if (response.data.status) {
            swal.fire('Done!', 'Student Created Successfully.', 'success');

          // console.log("new child", response.data.data);
          username.value = response.data.data.username; 
          password.value = random_pass; 
          // inviteStudent(response.data.data.student_id);
          // swal.fire('Done!', 'Student Created And Invited Successfully.', 'success');
          setTimeout(() => {
            emit('UsernamePassword',username.value,password.value);
          }, 1000);
          form.value.dob = '';
          form.value.full_name = '';
          form.value.email = '';
          $('.form-check-input').prop('checked',false)
          $("#school").val('').trigger('change');
          $("#city").val('').trigger('change');
          $("#classes").val('').trigger('change');
          emit('getStudents');
          emit('myStudents');
        } else {
          if(response.data.message){
            console.log(response.data.message);
            error.value = functions.errorResponse({error:response.data.message});

            // swal.fire('Error!',response.data.message,'error')
          }else{
            swal.fire('Error!','Something went wrong!','error')
          }
        }
      });
    }

    async function inviteStudent(student_id) {
     var form = {
          'student_id': student_id,
     };
     const invitation = new factory(getToken(), 'invite-student', form);
     await invitation.postForm().then((response) => {
      if (response.data.status) {
            // swal.fire('Done!', 'Student Created And Invited Successfully.', 'success');
          //  $('#foundChild').modal('hide');
            emit('getStudents');
            emit('myStudents');
      }
      else {
            swal.fire('Error!', 'Something went wrong.', 'error');
      }
     });
};
   
   function termselected(condition) {
     iaccept.value = condition;
     validate();
   }
   function validate() {
     isValid.value = !iaccept.value;
      if(form.value.full_name == '') {
        isValid.value = true;
        // $('.fullname').find('input').addClass('error-border');
      }
      else{
        // $('.fullname').find('input').removeClass('error-border');
      }

      if(form.value.dob == '') {
        isValid.value = true;
        // $('.dob').find('input').addClass('error-border');
      }
      else{
        // $('.dob').find('input').removeClass('error-border');
      }

      if(form.value.class_id == '') {
        isValid.value = true;
        // $('.classes').find('input').addClass('error-border');
      }
      else{
        // isValid.value = true;
        // $('.classes').find('input').removeClass('error-border');
      }

      if(form.value.school_id == '') {
        isValid.value = true;
        // $('.schools').find('input').addClass('error-border');
      }
      else{
        // $('.schools').find('input').removeClass('error-border');
      }

      if(form.value.city_id == '') {
        isValid.value = true;
        // $('.cities').find('input').addClass('error-border');
      }
      else{
        // $('.cities').find('input').removeClass('error-border');
      }
      if ((form.value.email) != '') {
        if (!validateEmail(form.value.email)) {
          isValid.value = true;
          emailError.value = 'Invalid Email.'
        } else {
          emailError.value = ''

        }
      } else {
        emailError.value = ''

      }
     
   }
  
   var ProvincesCities = new factory(getToken(), 'provinces-cities');
   ProvincesCities.getHttp().then((response) => {
     if (response.data.status) {
       provinces.value = provinces.value = response.data.data.provinces;
       fil_cities.value = cities.value = response.data.data.cities;
     }
   });
   
   function cityProvinceAutomation(condition) {
     if (condition == 'city') {
       var selectedcity = cities.value.find((item) => (item.id == form.value.city_id));
       var selectedprovince = provinces.value.find((item) => (item.id == selectedcity.province_id));
       form.value.province_id = selectedprovince.id;
     } else {
       fil_cities.value = cities.value.filter((item) => (item.province_id == form.value.province_id));
   
     }
   }

   function citySelect(){ 
     var payload = {
       city_id: form.value.city_id
     }
     var schoolApi = new factory(getToken(), 'city-schools',payload);
     schoolApi.getHttp().then((response) => {
       fil_classes.value = [];
       if (response.data.status) {
         form.value.school_id = '';
         form.value.class_id = '';
         schools.value = response.data.data;
       }else{
         schools.value = []; 
       }
     });
   }
   function schoolSelect(){ 
     var payload = {
       school_id: form.value.school_id
     }
     var classesApi = new factory(getToken(), 'school-classes',payload);
     classesApi.getHttp().then((response) => {
       if (response.data.status) {
         form.value.class_id = '' ;
         fil_classes.value = response.data.data;
       }else{
         fil_classes.value = [];
       }
     });
   }
   // function schoolClassesFilter(condition) {
   //   if (condition == 'classes') {
   //     var selectedclass = classes.value.find((item) => (item.id == form.value.class_id));
   //     var selectedschool = schools.value.find((item) => (item.id == selectedclass.school_id));
   //     form.value.school_id = selectedschool.id;
   //   } else {
   //     fil_classes.value = classes.value.filter((item) => (item.school_id == form.value.school_id));
   
   //   }
   // }
   
   
   function checkStepTwoValidation() { 
     isInValidTwo.value = false;
     if (form.value.province_id == null || form.value.province_id == undefined || form.value.province_id == '') {
       isInValidTwo.value = true;
     }
     if (form.value.city_id == null || form.value.city_id == undefined || form.value.city_id == '') {
       isInValidTwo.value = true;
     }
     if (form.value.school_id == null || form.value.school_id == undefined || form.value.school_id == '') {
       isInValidTwo.value = true;
     }
     if (form.value.class_id == null || form.value.class_id == undefined || form.value.class_id == '') {
       isInValidTwo.value = true;
     }
     if (!inValidUsername.value) {
       isInValidTwo.value = true;
     }
   }

   function openAccCreatedModal() { 
    emit('openAccountCreatedModal');
   }

   function generatePassword() {
      var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
    return retVal;
  }
  
   </script>

   <style scoped>
  .continue-btn {
      background: #2463EB;
      color: #FFFFFF;
      padding: 14px 0px !important;
  }
  .continue-btn:hover {
      background: #1453DC;

  }
  .fs-18 {
    font-size: 18px !important;
  }
  </style>