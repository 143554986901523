<template>
  <div class="content col-md-8 col-12">
    <div>
      <ul class="breadcrumb">
        <li>
          <router-link :to="{ name: 'account-help' }"
            ><span class="breadcrumb-text">Help Center</span></router-link
          >
        </li>
        <li>
          <router-link to=""
            ><span
              class="breadcrumb-text active"
              style="text-transform: capitalize"
              >{{ $route.params.role }}</span
            ></router-link
          >
        </li>
      </ul>
    </div>

    <div v-if="jsonData[role]">
      <div>
        <h3 class="collapsable-heading" id="heading">{{ questions.title }}</h3>

        <div v-for="(data, index) in questions.data" :key="index">
          <div
            :id="data.id"
            class="collapsable-wrapper mt-3 mb-3"
            :class="{ 'border-bottom': index !== questions.data.length - 1 }"
          >
            <p
              :id="index"
              @click="onClickCollapsable(index)"
              class="d-flex justify-content-between align-items-center collapsable-sub-heading collapsed"
              data-bs-toggle="collapse"
              :href="'#collapse_id-' + index"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {{ data.question }}
              <common-svgs :id="'cs'+index" style="min-width:20px !important;width:20px !important;" type="arrow-down">
              
              </common-svgs>

              
        
            </p>

            <div class="collapse" :id="'collapse_id-' + index">
              <div class="collapsable-content">
                <p>{{ data.answer }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonSvgs from "@/components/CommonSvgs.vue";
import jsonData from "@/static/help-content.json";


export default {
  components: {
    CommonSvgs,
  },
  data() {
    return {
      jsonData: jsonData,
      role: this.$route.params.role,
      option: this.$route.params.option,
      id: this.$route.query.id,
      questions: null,
    };
  },
  created() {
    
    this.updateQuestions();
  },
  mounted(){
 
    if(this.id != undefined && this.id != ''){
    

        const element = document.getElementById(this.id); 
        if (element) {
          const childDiv = element.querySelector('.collapse');
      
      if (childDiv) {
        childDiv.classList.add('show');
      }

      const offset = -90; // 20 pixels above the element
      const scrollPosition = element.getBoundingClientRect().top + window.scrollY + offset;

      // Scroll to the calculated position
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
    }
  },
  watch: {
    $route(to, from) {

      if (
        to.params.role !== from.params.role ||
        to.params.option !== from.params.option
      ) {
         const elementToScrollTo = document.getElementById('heading');

      if (elementToScrollTo) {
        const scrollPosition = elementToScrollTo.offsetTop - 150;
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
        this.role = to.params.role;
        this.option = to.params.option;
        this.updateQuestions();
      }
    },
  },
  methods: {
  onClickCollapsable(id){
   event.preventDefault();
    const element = document.getElementById(id);
    const commonSvg = document.getElementById('cs'+id);
    console.log(commonSvg)
    if(element.classList.contains('collapsed')){
        commonSvg.setAttribute('style', 'transform: rotate(0deg); width: 20px');
    }else{
      commonSvg.setAttribute('style', 'transform: rotate(180deg); width: 20px');
    }
  },

    updateQuestions() {
      this.questions = jsonData[this.role]?.find(
        (item) => item.category === this.option
      );
    },
  },

  
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 0.6px solid #abbed1;
}

.collapsable-wrapper {
    /* width:70%; */
}

.breadcrumb-text {
  color: var(--neutral-l-grey, #89939e);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: ">\00a0";
}

div.content {
  /* margin-left: 400px;
  margin-top: -186px; */
  padding: 1px 16px;
  min-height: 500px;
  /* width: 60vw; */
}

@media screen and (max-width: 900px) {
  div.content {
    margin-top: 0px;
    /* width: 90%; */
  }

  .collapsable-wrapper {
    
        width:100%;
}
  div.content {
    margin-left: 0;
  }
}

.active {
  color: black !important;
  font-weight: bold !important;
}

.collapsable-heading {
  color: #212121;
  font-family: Apercu;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
.collapsable-sub-heading {
  color: #2463eb;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.collapsable-content {
  color: rgba(25, 25, 25, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
</style>
