<script setup>
import { onMounted, inject, ref, computed, onUpdated } from "vue";
import { useAuthStore } from "@/store/auth.js";
import SubHeader from "@/views/parent/SubHeader.vue";
import CardBox from '@/components/CardBox.vue';
import CommonSvgs from '@/components/CommonSvgs.vue';
import HeadingCard from "@/components/HeadingCard.vue";
import Modal from "./Modal.vue";
import factory from "@/common/factory.js";
import { useRoute } from "vue-router";
import { useRouter } from 'vue-router';
import { functions } from "@/common/functions.js";
import Toastr from "@/components/Toastr.vue";

const route = useRoute();
const router = useRouter();

const bgColor = ref(true);
const student = ref([]);
const accepted_students = ref([]);
const id = ref(route.params.id);
student.value = inject('student');

const auth = useAuthStore();
const loggedInId = ref(auth.userinfo.id);
function getToken() {
     const token = {
          headers: {
               // 'Content-Type': 'application/json',
               'Content-Type': 'application/x-www-form-urlencoded',
               'Authorization': 'Bearer ' + auth.token,
          },
     };
     return token;
}

async function accepStatusStudent() {
     const students = new factory(getToken(), 'accepted-status-students');
     await students.getHttp().then((response) => {
          swal.close();
          if (response.data.status && response.data.data.length > 0) {
               accepted_students.value = response.data.data;
          }
          else {
               accepted_students.value = [];
          }
     });
};

var progress = ref([]);
var lastlog = ref('');
var courses = ref([]);
var completedcourses = ref(0);
var student_details = ref({
     "user_id": '',
     "username": '',
     "phone": '',
     "dob": '',
     "class": '',
     "city": '',
     "email": '',
     "postal_address": ''
});
const creater_user_id = ref('');
async function studentReport() {
     var student_id = {
          id: route.params.id
     };
     const StdReport = new factory(getToken(), 'single-student-report', student_id);
     await StdReport.getHttp().then((response) => {
          swal.close();
          if (response.data.status) {
               progress.value = response.data.data ? response.data.data.progress : [];
               lastlog.value = response.data.data ? response.data.data.lastLog : '';
               courses.value = response.data.data ? response.data.data.courses : [];
               if (courses.value.length == 0) {
                    $('#nocourse').show();
               }
               student_details.value.user_id = response.data.data.studentinfo ? response.data.data.studentinfo.user_id : '';
               student_details.value.username = response.data.data.studentinfo ? response.data.data.studentinfo.users.username : '';
               student_details.value.phone = response.data.data.studentinfo ? response.data.data.studentinfo.student_phone_no : '';
               student_details.value.dob = response.data.data.studentinfo ? response.data.data.studentinfo.dob : '';
               student_details.value.class = response.data.data.studentinfo ? response.data.data.studentinfo.student_class.class_name : '';
               student_details.value.city = response.data.data.studentinfo ? response.data.data.studentinfo.student_class.school.city.city : '';
               student_details.value.email = response.data.data.studentinfo ? response.data.data.studentinfo.email : '';
               student_details.value.postal_address = response.data.data.studentinfo ? response.data.data.studentinfo.address : '';
               creater_user_id.value = response.data.data.studentinfo ? response.data.data.studentinfo.users.creater_user_id : '';
          }
     });
}

accepStatusStudent();
onMounted(() => {
     studentReport();
});
onUpdated(() => {
     var $round = $('.round');
     var roundRadius = $round.find('circle').attr('r');
     var roundPercent = 0;
     var roundCircum = 2 * roundRadius * Math.PI;
     var roundDraw = 0;

     if (roundPercent > 0) {
          roundDraw = roundPercent * roundCircum / 100
     } else {
          roundDraw = 0;
     }
     $round.css('stroke-dasharray', roundDraw + ' 999')
     studentReport();

});
function returnProgress(completed, total, noprogerss = false) {
     if (completed > 0 && total > 0) {
          if (noprogerss) {
               var $round = $('.round');
               var roundRadius = $round.find('circle').attr('r');
          }
          var roundPercent = parseInt((completed / total) * 100);
          if (noprogerss) {
               var roundCircum = 2 * roundRadius * Math.PI;
               var roundDraw = roundPercent * roundCircum / 100;
               $round.css('stroke-dasharray', roundDraw + ' 999');
          }
          return roundPercent;

     }
     return 0;
}
onMounted(() => {
     $(document).ready(function () {
          var $round = $('.round');
          var roundRadius = $round.find('circle').attr('r');
          var roundPercent = $round.attr('data-percent');
          var roundCircum = 2 * roundRadius * Math.PI;
          var roundDraw = 0;

          if (roundPercent > 0) {
               roundDraw = roundPercent * roundCircum / 100
          } else {
               roundDraw = 0;
          }
          $round.css('stroke-dasharray', roundDraw + ' 999')
     })
})

completedcourses.value = computed(() => {
     return courses.value.filter(
          (course) => course.completedtopics > 0 && course.completedtopics === course.totaltopics
     ).length;
});
function filterOutCourses() {
     var fil = $('.filtercourse').find('option:selected').val();
     $('#nocourse').hide();
     if (fil == '1') {
          if ($('.incompletecourse').length == 0) {
               $('#nocourse').show();
          }
          $('.incompletecourse').show();
          $('.completedcourse').hide();
          $('.startcourse').hide();
     } else if (fil == '2') {
          if ($('.completedcourse').length == 0) {
               $('#nocourse').show();
          }
          $('.incompletecourse').hide();
          $('.completedcourse').show();
          $('.startcourse').hide();
     } else {
          if ($('.courses').find('tbody').find('tr').length == 0) {
               $('#nocourse').show();
          }
          $('.courses').find('tbody').find('tr').show();
     }
}
$(".custom-toaster").removeClass('d-none');

async function resendDetails(student_id) {
     const students = new factory(getToken(), 'resend-details/' + student_id);
     functions.initLoader();

     await students.getHttp().then((response) => {
          if (response.status == 200) {
               swal.fire('Done!', 'Student Details Send Successfully.', 'success');
               setTimeout(() => {
                    swal.close();
               }, 2000);
          } else {
               swal.fire('Done!', 'Student Details Not Send.', 'danger');
          }
     });
};
</script>

<template>
     <modal :bgColor="bgColor" :student="student" @getChildren="accepStatusStudent"></modal>
     <div class="col-md-12 mb-3">
          <heading-card :short_desc="'These are your children’s accounts. Click your child’s name to view their progress.'"
               v-if="auth.userinfo.type != 'School'" pretitle="Welcome " :title="auth.userinfo.full_name"
               :bgimg="auth.baseUrl + 'files/assets/img/parent_bg.jpg'"></heading-card>
     </div>
     <div class="row m-0">
          <div :class="accepted_students == [] ? 'offset-9' : ''" class="col-md-3 p-0 display-xs"
               style="text-align: end;display:none;">
               <button class="btn btn-primary" style="    padding: 8px 24px !important;
    margin-bottom: 24px;" data-bs-toggle="modal" data-bs-target="#addAChild">
                    <common-svgs type="plus-icon"></common-svgs>
                    Add Child
               </button>
          </div>
          <div v-if="auth.userinfo.type == 'Parent'" class="col-md-9 p-0">
               <sub-header :class="'parent-students-list'" :parent_child="true"
                    :accepted_students="accepted_students"></sub-header>
          </div>
          <div :class="accepted_students == [] ? 'offset-9' : ''" class="col-md-3 p-0 hidden-xs" style="text-align: end;">
               <button class="btn btn-primary" style="padding: 14px 32px !important;" data-bs-toggle="modal"
                    data-bs-target="#addAChild">
                    <common-svgs type="plus-icon"></common-svgs>
                    Add Child
               </button>
          </div>
     </div>

     <card-box :noPadding="true" class="p-0 mt-4" :minimalpadding="true">
          <div class="row p-0">
               <div class="col-md-2 col-4 p-0 d-md-flex justify-content-md-center align-items-md-center">
                    <img src="../../../public/img/student_icon.png" alt="" class="img_rounded studentimg">
               </div>
               <div class="col-md-10 col-8 p-0">
                    <div class="row">
                         <div class="col-md-2">
                              <label for="" class="lables">Username</label>
                              <p class="content">{{ student_details.username ?? '--' }}</p>
                         </div>
                         <div class="col-md-3">
                              <label for="" class="lables">Phone</label>
                              <p class="content">{{ student_details.phone ?? '--' }}</p>
                         </div>
                         <div class="col-md-2 p-0">
                              <label for="" class="lables">Date of Birth</label>
                              <p class="content">{{ student_details.dob ? functions.dateFormat(student_details.dob) : '--' }}
                              </p>
                         </div>
                         <div class="col-md-2">
                              <label for="" class="lables">Class</label>
                              <p class="content"> {{ student_details.class ?? '--' }} </p>
                         </div>
                         <div v-if="loggedInId == creater_user_id" class="col-md-3">
                              <button class="btn btn-primary resend-details"
                                   @click="resendDetails(student_details.user_id)">Resend Details</button>
                         </div>
                    </div>

                    <div class="row">
                         <div class="col-md-2">
                              <label for="" class="lables">City</label>
                              <p class="content">{{ student_details.city ?? '--' }}</p>
                         </div>
                         <div class="col-md-3">
                              <label for="" class="lables">Email</label>
                              <p class="content">{{ student_details.email ?? '--' }}</p>
                         </div>
                         <div class="col-md-4 p-0">
                              <label for="" class="lables">Postal Address</label>
                              <p class="content mb-0">{{ student_details.postal_address ?? '--' }}</p>
                         </div>
                    </div>
               </div>
          </div>
     </card-box>

     <card-box :noPadding="true" class="p-0" :minimalpadding="true" style="margin-top: 24px !important">

          <div class="col-md-4">
               <div class="cube sub-cube1">


                    <div class="icon-container custom-round-progress">
                         <div class="point">

                              <svg class="round" viewbox="0 0 100 100" width="100" height="100"
                                   :data-percent="returnProgress(progress.completedTopicContents, progress.totalTopicContents, true)">
                                   <circle cx="50" cy="50" r="12" />
                                   <circle class="border" cx="50" cy="50" r="12" />
                              </svg>
                         </div>
                    </div>
                    <div class="container-text">
                         <p class="lables mb-0">Overall Progress</p>
                         <p class="container-data mb-0">
                              {{ returnProgress(progress.completedTopicContents, progress.totalTopicContents, true) }}%
                         </p>
                    </div>
               </div>
          </div>

          <div class="col-md-4">
               <div class="cube sub-cube2">
                    <div class="icon-container">
                         <common-svgs type="complete-courses"></common-svgs>
                    </div>
                    <div class="container-text">
                         <p class="lables mb-0">Complete Courses</p>
                         <p class="container-data mb-0">{{ completedcourses }}/{{ courses.length }}</p>
                    </div>
               </div>
          </div>

          <div class="col-md-4">
               <div class="cube sub-cube3">
                    <div class="icon-container">
                         <common-svgs type="clock"></common-svgs>
                    </div>
                    <div class="container-text">
                         <p class="lables mb-0">Last Active</p>
                         <p class="container-data mb-0">{{ ((lastlog) ? ($moment(lastlog).format("DD MMMM YYYY"))
                              : '') }}</p>
                    </div>
               </div>
          </div>

          <div class="col-md-12" style="margin-top: 28px !important;">
               <div class="row d-flex justify-content-between">
                    <div class="col-md-3 col-4">
                         <h4 style="margin-bottom: 32px !important;">Courses</h4>
                    </div>
                    <div class="col-md-2 col-6">
                         <select class="form-select filtercourse" aria-label="Default select example"
                              @change="filterOutCourses();">
                              <option value="0">All</option>
                              <option value="1">In-Progress</option>
                              <option value="2">Completed</option>
                         </select>
                    </div>
               </div>
          </div>
          <div class="col-md-12 pt-24">
               <div class="row m-0">
                    <table class="table courses coursestbl">
                         <thead>
                              <tr>
                                   <th style="min-width:300px">Course name</th>
                                   <th>Subject</th>
                                   <th>Completed Contents</th>
                                   <th style="min-width:200px">Last Seen</th>
                              </tr>
                         </thead>
                         <tbody>
                              <tr v-for="course in courses"
                                   :class="(course.completedtopics > 0 && course.completedtopics == course.totaltopics) ? 'completedcourse' : (returnProgress(course.completedtopics, course.totaltopics) ? 'incompletecourse' : 'startcourse')">
                                   <td class="logo-text">
                                        <img :src="functions.displayImg(course.file_name)"
                                             style="width: 32px; height: 32px;" />
                                        <p class="table-text-color first-cell-data">
                                             {{ course.name }} - {{ course.book_name }}
                                        </p>
                                   </td>
                                   <td class="table-text-color">
                                        {{ course.subjectname }}
                                   </td>
                                   <td class="table-text-color">
                                        {{ course.completedtopics }}/{{ course.totaltopics }}
                                        <div class="progress">
                                             <div class="progress-bar" role="progressbar"
                                                  :style="{ width: returnProgress(course.completedtopics, course.totaltopics) + '%' }"
                                                  :aria-valuenow="returnProgress(course.completedtopics, course.totaltopics)"
                                                  aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                   </td>
                                   <td class="table-text-color datecolumn">
                                        {{ (course.lastseen) ? functions.formatDate($moment, course.lastseen) : '' }}
                                   </td>
                              </tr>

                         </tbody>
                    </table>
               </div>
               <div style="display:none;" id="nocourse" class="no-course-div">
                    <common-svgs type="no-course"></common-svgs>
                    <p class="no-course-found">No Course Found</p>
               </div>
          </div>

     </card-box>
</template>


<style scoped>
table tbody tr {
     vertical-align: middle;
}

.first-cell-data {
     display: flex;
     flex-direction: row;
     vertical-align: middle;
     align-items: center;
     margin: 0px;
}

.cube {
     border-radius: 12px;
     padding: 20px !important;
     display: flex;
     gap: 16px;
}

.sub-cube1 {
     background: rgba(255, 186, 0, 0.07)
}

.sub-cube2 {
     background: rgba(0, 146, 98, 0.07);
}

.sub-cube3 {
     background: rgba(224, 43, 29, 0.07);
}

.icon-container {
     width: 40px;
     height: 40px;
     padding: 8px;
     background: white;
     border-radius: 8px;
}

.container-data {
     font-size: 16px;
     color: #212121;
}

table td,
table th {
     padding: 14px 16px !important;
     font-family: 'Inter';
     font-weight: 400;
     font-size: 14px;
     line-height: 17px;
     color: #6B7280;

}

.card-body-2 {
     padding: 64px 32px 64px 32px !important;
}

.lables {
     color: #717171;
     font-size: 13px !important;
}

.content {
     color: #212121;
     font-size: 14px !important;
}

.img_rounded {
     border-radius: 50%;
     width: 80px;
     height: 80px;
}

table thead {
     background: #F5F7FA !important;
}

.table-text-color {
     color: #212121;
     font-size: 14px;
}

.first-cell-data {
     width: 60% !important;
}

.logo-text {
     display: flex;
     gap: 12px;
}

.tbody,
tfoot,
th,
thead,
tr {
     border-style: none !important;
}

.progress {
     height: 8px !important;
     margin-top: 4px !important;
}

.resend-details {
     color: #2463EB;
     background: #2463EB1A;
     font-size: 14px !important;
     border: 0px;
     border-radius: 4px;
     padding: 8px 16px;
     ;
}

/* ===**************** Media Queries ****************=== */
@media (max-width: 915px) {
     .coursestbl {
          display: block;
          overflow: auto;
          padding: 0px !important;
     }

     .logo-text {
          width: 280px;
     }

     .datecolumn {
          display: block;
     }

     .cube {
          margin-bottom: 8px;
     }

     .hidden-xs {
          display: none;
     }

     .display-xs {

          display: block !important;
     }

}
</style>