<template>
  <div class="container" style="padding-top: 170px;">
    <div class="row mb-5">
      <div class="col-md-6 offset-md-3">
        <card-box class="SubmitOnEnter" title="Log in" shortdesc="Build learning for today, tomorrow, and beyond.">
          <text-input class="col-md-12" v-model="form.username" placeholder="Username"></text-input>
          <password-input :noformgroup="true" v-model="form.password" class="col-md-12 passwordfeild"
            placeholder="Enter Password"></password-input>
          <div class="col-md-12 text-right mb-3">
            <router-link class="a-link" style="font-size: 14px;" :to="{ name: 'forgot-password' }">
              Forgot Password?
            </router-link>
          </div> 
          <div class="col-md-12 mb-3">
            <button class="btn btn-primary btn-block submitItBtn"  @click="logIN()">Login</button>
          </div>
        </card-box>


      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import factory from '@/common/factory.js';
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";

const auth = useAuthStore(); 
import TextInput from "@/components/TextInput.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import CardBox from "@/components/CardBox.vue"; 

const router = useRouter();
var form = ref({
  username: "",
  password: '',
});   

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
} 


async function logIN() {
  const LoginUser = new factory(getToken(), 'login', form.value);
  await LoginUser.postForm().then((response) => {
    swal.close(); 
    if (response.data.status) {
      auth.token = response.data.data.token;
      auth.userinfo = response.data.data;
      setTimeout(() => {
        window.location.href = auth.baseUrl+"login";
      }, 300);

    } else {
      if(response.data.message){
        swal.fire('Error!',response.data.message,'error')
      }else{
        swal.fire('Error!','Something went wrong!','error')
      }
    }
  });
}; 
</script>