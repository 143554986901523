<template>
  <div class="container" style="padding-top: 170px;">
    <div class="row mb-5">
      <div class="col-md-6 offset-md-3">
        <card-box title="Create your password"
          shortdesc="Your password can be any combination of letters, numbers, and symbols.">
          <password-input @change="validate();" v-model="form.password" class="col-md-12 passwordfeild"
            placeholder="Enter Password"></password-input>

          <password-input @change="validate();" v-model="form.password_confirmation" class="col-md-12 passwordfeild"
            placeholder="Confirm Password"></password-input>

          <div class="col-md-12 mb-3">
            <button class="btn btn-primary btn-block" :disabled="isValid" @click="resetPassword">Continue</button>
          </div>
        </card-box>


      </div>
    </div>
  </div>
</template>
<script setup>

import { ref } from 'vue';
import factory from '@/common/factory.js';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";

import PasswordInput from "@/components/PasswordInput.vue";
import CardBox from "@/components/CardBox.vue";

const auth = useAuthStore();
const route = useRoute();
const router = useRouter();

var isValid = ref(true);
var form = ref({
  password: '',
  password_confirmation: ''
});

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
}
function validate() {
  isValid.value = false;
  if ((form.value.password_confirmation) == '' || form.value.password != form.value.password_confirmation) {
    isValid.value = true;
    $('.passwordfeild').find('input').addClass('error-border');
  } else {
    $('.passwordfeild').find('input').removeClass('error-border');

  }
}
async function resetPassword() {
  var checkVer = {
    user_name: route.query.user_name,
    key: route.query.key,
    password: form.value.password,
    password_confirmation: form.value.password_confirmation,
  }
  const ChangePass = new factory(getToken(), 'reset-password', checkVer);
  await ChangePass.postForm().then((response) => {
    if (response.data.status) {
      swal.fire('Done!', response.data.message, 'success');
      setTimeout(function(){
        // window.location.href = '/login';
        window.location.href = auth.baseUrl+"login";
      },500) 
    } else {
      swal.fire('Error!', response.data.message, 'error');
    }
  });
}
function stepOneBack() {
  router.push('/');
}
</script> 