import { reactive } from 'vue'

export const appConsts = reactive({
  appName: 'LMS',
  appTag: 'Learning Managment System', 
  // baseURL: 'http://127.0.0.1:8000/api/',
  baseURL: process.env.VUE_APP_API_URL,
  fileBaseUrl: process.env.VUE_APP_FILESYSTEM_URL,
  assetBaseUrl:process.env.BASE_URL
});


export default {
  name: "Constants",
  data() {
      return {
          appConsts, 
      }
  },
}
