<template>
  <div class="card">
    <div class="card-body p-5">
      <div class="row" style="row-gap: 30px;">
        <div class="col-md-12  text-center" v-if="$attrs.svgtype">
           <common-svgs :type="$attrs.svgtype" :width="$attrs.svgwidth" :height="$attrs.svgwidth"></common-svgs>
        </div>
        <div class="col-md-12  text-center" v-if="$attrs.title">
          <h2>{{ $attrs.title }}</h2>
        </div>
        <div class="col-md-12  text-center" v-if="$attrs.shortdesc">
          <p class="btext-2">{{ $attrs.shortdesc }}</p>
        </div> 
        <div class="col-md-12  text-center" v-if="$attrs.btntext"  >
          <button @click="btnClicked" class="btn btn-primary">{{ $attrs.btntext }}</button>
        </div> 
      </div>
    </div>
  </div>
</template>
<script setup>
import defineEmits from "vue";
import CommonSvgs from "@/components/CommonSvgs.vue";

var emits = defineEmits(['btn-clicked']);

function btnClicked(){
  emits('btn-clicked');
}
</script>
<style scoped>
/* Form Card CSS Start */
.card {
  border-radius: 12px;
}

.custom-back-btn:hover{
  background: #D0DEFB;
  color: var(--primary);
}
.custom-back-btn{
  border-radius: 8px;
  padding: 6px 12px 6px 10px;
gap: 8px;
color: #9CA3AF; 
}

/* Form Card CSS End */
</style>
