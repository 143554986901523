import {
    createRouter,
    createWebHistory
} from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import ClassStudents from "@/views/class/ClassStudents.vue";
import HomePage from "../views/HomePage.vue";
import {
    useAuthStore
} from "@/store/auth.js";
import LoginPage from "../views/LoginPage.vue";
import LogoutPage from "../views/LogoutPage.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import SignUp from "../views/SignUp.vue";
import SchoolSignup from "../views/SchoolSignup.vue";
import CoursesPage from "../views/CoursesPage.vue";
import TopicPage from "../views/course/TopicPage.vue";
import ContactPage from "../views/ContactPage.vue";
import JoinStudent from "../views/JoinStudent.vue";
import JoinParent from "../views/JoinParent.vue";
import CompunentBuilder from "../components/CompunentBuilder.vue";
import VerifyAccount from "../views/VerifyAccount.vue";
import VerifyPage from "../views/VerifyPage.vue";
import VerifyEmailPage from "../views/VerifyEmailPage.vue";
import ResetPage from "../views/ResetPage.vue";
import AccountSetting from "../views/AccountSetting.vue";
import AccountHelp from "../views/AccountHelp.vue";
import AccountHelpSearch from "../views/AccountHelpSearch.vue";
import CoursePage from "../views/course/CoursePage.vue";
import SingleStudent from "@/views/student/SingleStudent.vue";
import StudentDetails from "@/views/student/StudentDetails.vue";
import ParentStudents from "@/views/parent/ParentStudents.vue";
import ParentStudentDashboard from "@/views/parent/ParentStudentDashboard.vue";
import ParentChild from "../views/AddParentChild.vue";
import StudentImport from "../views/school/StudentImport.vue";
import SearchIndividualStudent from "@/views/school/SearchIndividualStudent";

const routes = [{
        path: "/",
        name: "home",
        component: HomePage,
        meta: {
            title: "Alternates - Your bright future begins here",
            requiresAuth: false,
            isWebsite: true,
            description: "An interactive online learning platform that offers illustrative course related video lessons for primary & middle school students.",

        },
    },
    {
        path: "/compunentbuilder",
        name: "compunentbuilder",
        component: CompunentBuilder,
    },
    {
        path: "/login",
        name: "login",
        component: LoginPage,
        meta: {
            title: "Alternates - Login",
            requiresAuth: false,
            isWebsite: true,
        },
    },
    {
        path: "/logoutpage",
        name: "logoutpage",
        component: LogoutPage,
        meta: {
            title: "Alternates - Logout",
            requiresAuth: false,
            isWebsite: true,
            noFooter: true,
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
        meta: {
            title: "Alternates - Forgot Password",
            requiresAuth: false,
            isWebsite: true,
        },
    },
    {
        path: "/contactus",
        name: "contactus",
        component: ContactPage,
        meta: {
            title: "Alternates - Contact US",
            requiresAuth: false,
            isWebsite: true,
        },
    },
    {
        path: "/courses",
        name: "courses",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () =>
        // import(/* webpackChunkName: "about" */ "../views/CoursesPage.vue"),
        component: CoursesPage,
        meta: {
            title: "Alternates - Courses",
            requiresAuth: false,
            isWebsite: true,
        },
    },
    {
        path: "/topic/:id",
        name: "topic",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () =>
        // import(/* webpackChunkName: "about" */ "../views/CoursesPage.vue"),
        component: TopicPage,
        meta: {
            title: "Alternates - Topic",
            requiresAuth: true,
            isWebsite: true,
            noFooter: true,
            noHeaderMenu: true
        },
        props: (route) => ({
            prop: route.prop
        }),
    },
    {
        path: "/school-signup",
        name: "school-signup",
        component: SchoolSignup,
        meta: {
            title: "Alternates - Signup",
            requiresAuth: false,
            isWebsite: true,
        },
    },
    {
        path: "/signup",
        name: "signup",
        component: SignUp,
        meta: {
            title: "Alternates - Signup",
            requiresAuth: false,
            isWebsite: true,
        }
    },
    {
        path: "/join-student",
        name: "join-student",
        component: JoinStudent,
        meta: {
            title: "Alternates - Signup",
            requiresAuth: false,
            isWebsite: true,
        }
    },
    {
        path: "/single-student-signup",
        name: "single-student-signup",
        component: SingleStudent,
        meta: {
            title: "Alternates - Signup",
            requiresAuth: false,
            isWebsite: true,
        }
    },
    {
        path: "/join-parent",
        name: "join-parent",
        component: JoinParent,
        meta: {
            title: "LMS Parent - Signup",
            requiresAuth: false,
            isWebsite: true,
        }
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            title: "Alternates - Dashboard",
            requiresAuth: true,
            isWebsite: false,
            noFooter: true,
            noHeaderMenu: true
        }
    },
    {
        path: "/class-students/:id",
        name: "class-students", //url or route
        component: ClassStudents,
        meta: {
            title: "Alternates - Class Students",
            requiresAuth: true,
            isWebsite: false,
            noFooter: true,
            noHeaderMenu: true
        },
        props: (route) => ({
            prop: route.prop
        }),
    },
    {
        path: "/student-details/:id",
        name: "student-details", //url or route
        component: StudentDetails,
        meta: {
            title: "Alternates - Student Details",
            requiresAuth: true,
            isWebsite: false,
            noHeaderMenu: true,
            noFooter: true,
        },
        props: (route) => ({
            prop: route.prop
        }),
    },
    {
        path: "/verify",
        name: "verify",
        component: VerifyAccount,
        meta: {
            title: "Alternates - Verify Account",
            requiresAuth: true,
            isWebsite: true,
        }
    },
    {
        path: "/verify-account",
        name: "verify-account",
        component: VerifyPage,
        meta: {
            title: "Alternates - Verify Account",
            requiresAuth: false,
            isWebsite: true,
        },
        props: (route) => ({
            query: route.query
        }),
    },
    {
        path: "/verify_email",
        name: "verify_email",
        component: VerifyEmailPage,
        meta: {
            title: "Alternates - Verify Account",
            requiresAuth: false,
            isWebsite: true,
            noFooter: true,
        },
        props: (route) => ({
            query: route.query
        }),
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: ResetPage,
        meta: {
            title: "Alternates - Reset Password",
            requiresAuth: false,
            isWebsite: true,
        },
        props: (route) => ({
            query: route.query
        }),
    },
    {
        path: "/account-settings",
        name: "account-settings",
        component: AccountSetting,
        meta: {
            title: "Alternates - Account Settings",
            requiresAuth: true,
            isWebsite: false,
            noFooter: true,
            noHeaderMenu: true
        },
        props: (route) => ({
            query: route.query
        }),
    },

    {
        path: "/account-help",
        name: "account-help",
        component: AccountHelp,
        meta: {
            title: "Alternates - Account Help",
            requiresAuth: false,
            isWebsite: true,
            noFooter: false,
            noHeaderMenu: true
        },
    },
    {
        path: "/account-help-s",
        name: "account-help-search",
        component: AccountHelpSearch,
        meta: {
            title: "Alternates - Account Help",
            requiresAuth: false,
            isWebsite: true,
            noFooter: false,
            noHeaderMenu: true
        },
    },

    {
        path: '/account-help-s/search',
        component: AccountHelpSearch,
        name: 'account-help-search',
        props: (route) => ({
            keyword: route.query.keyword,
        }),
        meta: {
            title: "Alternates - Search",
            requiresAuth: false,
            isWebsite: true,
            noFooter: false,
            noHeaderMenu: true
        },
    },



    {
        path: "/account-help-s/:role?",
        name: "account-help-role",
        component: AccountHelpSearch,
        meta: {
            title: "Alternates - Account Help",
            requiresAuth: false,
            isWebsite: true,
            noFooter: false,
            noHeaderMenu: true
        },
    },
    {
        path: "/account-help-s/:role?/:option?",
        name: "account-help-role-option",
        component: AccountHelpSearch,
        props: (route) => ({
            id: route.query.id,
        }),
        meta: {
            title: "Alternates - Account Help",
            requiresAuth: false,
            isWebsite: true,
            noFooter: false,
            noHeaderMenu: true
        },
    },
   
    




    {
        path: "/course/:id",
        name: "course",
        component: CoursePage,
        meta: {
            title: "Alternates - Course",
            requiresAuth: true,
            isWebsite: true,
            noFooter: true,
            noHeaderMenu: true
        },
        props: (route) => ({
            query: route.query
        }),
    },
    {
        path: "/loggedInSchoolClasses",
        name: "loggedInSchoolClasses",
        component: AccountHelp,
        meta: {
            title: "Alternates - School Classes",
            requiresAuth: true,
            isWebsite: false,
        },
    },
    {
        path: "/student-imports",
        name: "student-imports",
        component: StudentImport,
        meta: {
            title: "Alternates - Student Import",
            requiresAuth: true,
            isWebsite: false,
        },
    },
    {
        path: "/parent-students",
        name: "parent-students",
        component: ParentStudents,
        meta: {
            title: "Alternates - Parent Childrens",
            requiresAuth: true,
            isWebsite: false,
        },
    },
    {
        path: "/parent-student-details/:id",
        name: "parent-student-details",
        component: ParentStudentDashboard,
        meta: {
            title: "Alternates - Parent Child Dashboard",
            requiresAuth: true,
            isWebsite: false,
        },
        props: (route) => ({
            query: route.query
        }),
    },
    {
        path: "/invite-childs",
        name: "invite-childs",
        component: ParentChild,
        meta: {
            title: "Alternates - Invite Childs",
            requiresAuth: true,
            isWebsite: true,
        },
    },
    {
        path: "/school-students",
        name: "school-students",
        component: SearchIndividualStudent,
        meta: {
            title: "Alternates - Search Student",
            requiresAuth: true,
            isWebsite: false,
            noFooter: true,
            noHeaderMenu: true
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkExactActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    var auth = useAuthStore();

    // if (localStorage.getItem("state")) { 
    //     state = JSON.parse(localStorage.getItem("state"));
    //      auth = state.auth;
    //     }
    window.scrollTo(0, 0);
    if ($('.select2-container--open').length > 0) {
        $('.select2-container--open').remove();
    }
    document.title = to.meta.title;
    var newDescription = to.meta.description ?? 'An interactive online learning platform that offers illustrative course related video lessons for primary & middle school students.';
    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.setAttribute('content', newDescription);


    auth.curentEndPoint = to.name;
    auth.isWebsite = to.meta.isWebsite;
    auth.noFooter = to.meta.noFooter ?? false;
    auth.noHeaderMenu = to.meta.noHeaderMenu ?? false;
    if (to.meta.requiresAuth) {
        if (auth.token) {
            if (auth.userinfo.is_verified == 0 && to.name != 'verify' && to.name != 'verify-account') {
                next('/verify');
            } else {
                next();
            }
        } else {
            next('/login');
        }
    } else {
        if (auth.token) {
            if (auth.userinfo.is_verified == 0 && to.name != 'verify' && to.name != 'verify-account') {
                next('/verify');
            } else {
                if (to.name == 'login' || to.name == 'join-student' || to.name == 'join-parent' || to.name == 'home' || to.name == "courses" || to.name == "contactus") {
                    auth.isWebsite = false;
                    auth.noFooter = true;
                    auth.noHeaderMenu = true;
                    next('/dashboard');
                    // next('/parent-childs');
                } else {
                    next();
                }
            }
        } else {
            next();
        }
    }
    // if (to.name == 'login') {
    //   if (auth.token) {
    //     if (auth.userinfo.is_verified == 0) {
    //       next('/verify');
    //     } else {
    //       next({
    //         name: 'dashboard'
    //       });
    //     }
    //   } else {
    //     next();
    //   }
    // } else if (auth.token) {
    //   if (auth.userinfo.is_verified == 0 && to.name != 'verify' && to.name != 'verify-account') {
    //     next('/verify');
    //   } else {
    //     next();
    //   }
    // } else {
    //   next();
    // }
});
export default router;