<script setup>
import { defineEmits } from 'vue';

var emit = defineEmits('menuclicked');
function menuclicked(condition,event) {
    emit('menuclicked', condition);
    $(event.target).closest('.row').find('.active').removeClass('active');
    $(event.target).addClass('active');
}
</script>
<template>
    <div class="card">
        <div class="card-body p-0">
            <div class="row m-0" style="padding:8px 16px;">
                <div class="col-lg-2 col-md-4">
                    <button class="btn btn-clean btn-block active" @click="menuclicked('all',$event)">All</button>
                </div>
                <div class="col-lg-2 col-md-4">
                    <button class="btn btn-clean btn-block" @click="menuclicked('progress',$event)">In Progress</button>
                </div>
                <div class="col-lg-2 col-md-4">
                    <button class="btn btn-clean btn-block" @click="menuclicked('completed',$event)">Completed</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
/* Form Card CSS Start */
.btn-clean:focus {
    border:unset;
    box-shadow:unset;
}
.btn-clean {
    background: white;
    border-radius: 8px;
    color: #89939E;
    border: unset;
}

.btn-clean.active {
    background: rgba(36, 99, 235, 0.1) !important;
    border-radius: 8px;
    color: var(--primary);
}

.card {
    background: #FFFFFF;

    box-shadow: 0px 15px 30px rgba(186, 186, 186, 0.1);
    border-radius: 12px;
    ;
}

.custom-back-btn:hover {
    background: #D0DEFB;
    color: var(--primary);
}

.custom-back-btn {
    border-radius: 8px;
    padding: 6px 12px 6px 10px;
    gap: 8px;
    color: #9CA3AF;
}

.card-body .bgimg {
    position: absolute;
    right: 0px;
    top: 0px;
}

/* Form Card CSS End */</style>