<template>
  <div class="container page-maincontainer">
    <div class="row mb-5">
      <div class="col-md-6 " style="z-index: 11;">
        <card-box title="Contact us" class="SubmitOnEnter">

          <text-input @keyup="Validate();" @blur="ValidateOnBlur();" v-model="form.contact_email" :error="emailError" class="col-md-12"
            label="Your Email address" placeholder="Your Email address"></text-input>
          <text-input @keyup="Validate();" v-model="form.contact_subject" class="col-md-12" label="Subject"
            placeholder="Subject"></text-input>
          <textarea-input @keyup="Validate();" v-model="form.contact_description" class="col-md-12"
            label="Add description" placeholder="Add description" maxlength="220"></textarea-input>
          <p class="atext-1">Please enter the details of your request. A member of our support staff will respond as
            soon as possible.</p>

          <file-input v-model="form.contact_attachment"></file-input>
          <div class="col-md-12 mb-3">
            <button class="btn btn-primary btn-block submitItBtn" :disabled="invalid" @click="submitContactus();">Submit
              request</button>
          </div>
        </card-box>

      </div>
      <!-- <div class="col-md-6 " style="margin:0 auto;">
        <div class="contactusimg">
          <h1>Love to hear from you, <br />
            Get in touch</h1>
        </div>
      </div> -->
    </div>
  </div>
  <img class="contactusimg" :src="auth.baseUrl + 'files/assets/img/contactus.webp'" />
</template>
<style scoped>
.contactusimg {
  position: absolute;
  /* background-image: url('/public/files/assets/img/contactus.webp'); */
  /* background-repeat: no-repeat; */
  /* background-size: auto; */
  /* width: 795px; */
  height: 646.88px;
  right: 0;
  top: 250px;
}

.page-maincontainer {
  padding-top: 162px;
}

/* .contactusimg h1 {
  color: white;
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
} */

@media (max-width: 915px) {
  .contactusimg h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
  }

  .page-maincontainer {
    padding-top: 152px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
<script setup>
import { ref } from "vue";
import { useAuthStore } from '@/store/auth.js';

// import CardBlock from "@/components/website/CardBlock.vue";
import TextInput from "@/components/TextInput.vue";
import TextareaInput from "@/components/TextareaInput.vue";
import FileInput from "@/components/FileInput.vue";
import CardBox from "@/components/CardBox.vue";
import factory from '@/common/factory.js';

var auth = useAuthStore();
var emailError = ref('');
var form = ref({
  contact_email: '',
  contact_subject: '',
  contact_description: '',
  contact_attachment: 0
});
var invalid = ref(true);
function Validate() {
  invalid.value = false;
  if (form.value.contact_email == '') {
    invalid.value = true;
  } else {
    if (!validateEmail(form.value.contact_email)) {
      invalid.value = true;
    }else{
      emailError.value = ''
    } 
  }
  if (form.value.contact_subject == '') {
    invalid.value = true;
  }
  if (form.value.contact_description == '') {
    invalid.value = true;
  }
}
function ValidateOnBlur(){
  if (!validateEmail(form.value.contact_email)) {
      emailError.value = 'Invalid Email.'
    } else {
      emailError.value = ''
    }
}

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
}

async function submitContactus() {
  const ContactUS = new factory(getToken(), 'contactus_add', form.value);
  await ContactUS.postForm().then((response) => {
    if (response.data.status) {
      swal.fire('Done!', 'Our staff will will contact you soon.', 'success');
      form.value = {
        contact_email: '',
        contact_subject: '',
        contact_description: '',
        contact_attachment: 0
      };
    } else {
      console.log(response.data.message);
    }
  });
}; 
</script>
