<template>
  <div :class="$attrs.class ?? 'col-md-6'">
    <div :class="($attrs.noformgroup) ? '' : 'mb-3'">
      <label v-if="label && passwordvalue" class="form-label" :for="id">{{ label }}</label>
      <div class="password-field">
        <input autocomplete="new-password" v-model="passwordvalue" v-if="typevalue == 'password'" :id="id" ref="input"
          v-bind="{ ...$attrs, class: null }" class="form-control" :class="((label && passwordvalue) ? ' label-input ' : ' ')"
          type="password" :data-secuirty="typevalue" @input="$emit('update:modelValue', passwordvalue)" />
        <input autocomplete="new-password" v-model="passwordvalue" v-else :id="id" ref="input"
          v-bind="{ ...$attrs, class: null }" class="form-control" :class="((label && passwordvalue) ? ' label-input ' : ' ')"
          type="text" @input="$emit('update:modelValue', passwordvalue)" />
        <div class="password-field-icon" id="toggle-eye-bar" @click="changeType($event)"></div>
      </div>
      <small v-if="$attrs.helptext" class=" atext-1">{{ $attrs.helptext }}</small>
      <div v-if="(typeof error == 'object' && error)" class="form-error"><span class="error-icon"></span> {{ error[0] }}</div>
      <div v-if="(typeof error != 'object' && error)" class="form-error"><span class="error-icon"></span> {{ error }}</div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  data() {
    return {
      typevalue: this.type,
      passwordvalue: this.modelValue
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${uuid()}`
      },
    },
    type: {
      type: String,
      default: 'password',
    },
    error: String,
    label: String,
    modelValue: String,
  },
  updated() {
    this.loadValue();
  },
  emits: ['update:modelValue'],
  watch: {
    passwordvalue(val) {
      this.$emit('update:modelValue', val);
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
    changeType(event) {
      if (this.typevalue == 'text') {
        this.typevalue = 'password';
        $(event.target).addClass('password-field-icon').removeClass('password-field-icon-show');
      } else {
        this.typevalue = 'text';
        $(event.target).addClass('password-field-icon-show').removeClass('password-field-icon');
      }
    },
    loadValue() {
      this.passwordvalue = this.modelValue;
    },
  },
}
</script>
<style scoped>
.form-control {
  background: rgba(137, 147, 158, 0.05);
  border: 0.5px solid #ABBED1;
  border-radius: 8px;
  /* height: 48px; */
  /* padding: 12px 20px;*/
  padding: 12px 12px 12px 16px;
  font-family: var(--def-fontfamily);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #4D4D4D;
}

.atext-1 {
  padding-left: 20px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #89939E;
}

.password-field {
  position: relative;
}

.password-field .password-field-icon {
  content: "";
  /* //eye.png */
  background-image: url('/public/files/assets/img/pass-hide.svg'); 
  background-repeat: no-repeat;
  /* background-size: 16px 16px; adjust size as needed */
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
}
.password-field .password-field-icon-show {
  content: "";
  /* //eye.png */
  background-image: url('/public/files/assets/img/eye.png'); 
  background-repeat: no-repeat;
  /* background-size: 16px 16px; adjust size as needed */
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
}

.password-field input[type="password"] {
  padding-right: 30px;
}

.label-input {
  padding: 12px 12px 12px 16px !important;
}

label {
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  /* padding-left: 20px;
    padding-top: 12px; */
  padding-top: 6px;
  padding-left: 18px;
  color: #4D4D4D;
  z-index: 1;
}

.form-error {
  padding-top: 9px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #E02B1D;
}

.error-icon {
  content: "";
  background-image: url('/public/files/assets/img/error.webp');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  padding-right: 20px;

}
</style>
