<template>
  <search-banner></search-banner>
    
      <div class="container"> 
          <div class="row">

            <div class="col-12 mb-3">
              <h2 class="heading">Search by role</h2>           
            </div>
          </div>

            
          <div class="row " style="justify-content: space-between;">
                <div class="col-lg-4 mt-2 mb-2">
                  <role-card title="Student" customClass="btn-clean-primary-1" svgType="student"  stroke="" />
                </div>

                <div class="col-lg-4 mt-2 mb-2">
                  <role-card title="Parent" customClass="btn-clean-success" svgType="parent"  stroke="#009262" />
                </div>

                <div class="col-lg-4 mt-2 mb-2">
                <role-card title="School" customClass="btn-clean-danger" svgType="school"  stroke="#E02B1D" />
                </div> 

          </div>


      </div>


  <help-contact-banner></help-contact-banner>



</template>




<style scoped>

  .heading{
  font-family: Apercu;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color:#212121;
  }

  .container{
  margin-top:40px;
  margin-bottom:100px;
  }

</style>

<script > 
import roleCard from "@/components/help/roleCard.vue";
import search from "@/components/help/search.vue";
import helpContactBanner from "@/components/help/helpContactBanner.vue";


export default {
      
    components: {
      'role-card':roleCard,
      'help-contact-banner':helpContactBanner,
      'search-banner':search
    }
}


</script>