<template>
  <div :class="$attrs.class ?? 'col-md-6'">
    <div :class="($attrs.noformgroup) ? '' : 'mb-3'">
      <label v-if="label && modelValue" class="form-label" :for="id">{{ label }}</label>
      <input :maxlength="maxLength" :id="id" ref="input" v-bind="{ ...$attrs, class: null }" class="form-control"
        :class="((label && modelValue) ? ' label-input ' : ' ')" :type="type" :value="modelValue" @input="inputTriggerd($event)" />
      <small v-if="$attrs.helptext" class=" atext-1">{{ $attrs.helptext }}</small>
      <div v-if="(typeof error == 'object' && error)" class="form-error"><span class="error-icon"></span> {{ error[0] }}</div>
      <div v-if="(typeof error != 'object' && error)" class="form-error"><span class="error-icon"></span> {{ error }}</div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  data(){
    return {
      numericValue: ''
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${uuid()}`
      },
    },
     
    type: {
      type: String,
      default: 'text',
    },
    error: String,
    label: String,
    modelValue: String,
    maxLength: [Number, String]
  },
  emits: ['update:modelValue'],
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
    inputTriggerd(event) {
      if (this.type == 'phone') {
        const inputValue = event.target.value;
        const numericRegex = /^[0-9]+$/;

        if (!numericRegex.test(inputValue)) {
          // Remove non-numeric characters from the input value
          this.numericValue = inputValue.replace(/[^0-9]/g, '');
        } else {
          this.numericValue = inputValue;
        }
        event.target.value = this.numericValue;
        this.$emit('update:modelValue', this.numericValue)
      } else {
        this.$emit('update:modelValue', event.target.value)
      }
    }
  },
}
</script>
<style scoped>
.form-control {
  background: rgba(137, 147, 158, 0.05);
  border: 0.5px solid #ABBED1;
  border-radius: 8px;
  /* height: 48px; */
  /* padding: 12px 20px; */
  padding: 12px 12px 12px 16px;
  font-family: var(--def-fontfamily);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #212121;
}

.label-input {
  /* padding: 22px 12px 14px 16px !important; */
  padding: 12px 12px 12px 16px !important
}

.atext-1 {
  padding-left: 20px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #89939E;
}

label {
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* padding-left: 20px; */
  /* padding-left: 16px;
  padding-top: 12px; */
  padding-top: 5px;
  padding-left: 18px;
  color: #4D4D4D;
  z-index: 1;
}
.form-error {
  padding-top: 9px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #E02B1D;
}

.error-icon {
  content: "";
  background-image: url('/public/files/assets/img/error.webp');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  padding-right: 20px;

}
</style>
