<template>
    <ul class="nav flex-column sidebar large-screen-menu">
        <li class="nav-item">
            <router-link class="nav-link"
                :class="((auth.curentEndPoint == 'class-students' || auth.curentEndPoint == 'student-details') ? 'router-link-active' : '')"
                :to="{ name: 'dashboard' }">
                <common-svgs type="sidebar-home-icon"></common-svgs>
                Dashboard
            </router-link>
        </li>
        <li class="nav-item" v-if="auth.userinfo.type == 'School'">
            <router-link class="nav-link" :class="(auth.curentEndPoint == 'school-students' ? 'router-link-active' : '')"
                :to="{ name: 'school-students' }">
                <common-svgs type="students-icon"></common-svgs>
                Students
            </router-link>
        </li>
        <!-- <li class="nav-item" v-if="auth.userinfo.type == 'Parent'">
            <router-link class="nav-link"
                :class="((auth.curentEndPoint == 'parent-student-dashboard' || auth.curentEndPoint == 'parent-student-dashboard') ? 'router-link-active' : '')"
                :to="{ name: 'parent-student-dashboard' }">
                <common-svgs type="sidebar-home-icon"></common-svgs>
                Dashboard
            </router-link>
        </li> -->
        <span class="navbar-text hidetext">
            Accounts Setting & Support
        </span>
        <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'account-settings' }">
                <common-svgs type="sidebar-setting-icon"></common-svgs>
                Account
            </router-link>
        </li>
        <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'account-help' }">
                <common-svgs type="sidebar-info-icon"></common-svgs>
                Help
            </router-link>
        </li>
    </ul>
    <ul class="nav flex-column sidebar tablet-screen" style="display: none;">
        <li class="nav-item">
            <button class="nav-link tab-breadcum" type="button" @click="hideShowTabMenu('show')">
                <common-svgs type="menu-breadcrumps"></common-svgs>
            </button>
        </li>
        <li class="nav-item">
            <router-link class="nav-link"
                :class="((auth.curentEndPoint == 'class-students' || auth.curentEndPoint == 'student-details') ? 'router-link-active' : '')"
                :to="{ name: 'dashboard' }">
                <common-svgs type="sidebar-home-icon"></common-svgs>
            </router-link>
        </li>
        <li class="nav-item" v-if="auth.userinfo.type == 'School'">
            <router-link class="nav-link" :class="(auth.curentEndPoint == 'school-students' ? 'router-link-active' : '')"
                :to="{ name: 'school-students' }">
                <common-svgs type="students-icon"></common-svgs>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'account-settings' }">
                <common-svgs type="sidebar-setting-icon"></common-svgs>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'account-help' }">
                <common-svgs type="sidebar-info-icon"></common-svgs>
            </router-link>
        </li>
    </ul>

    <div class="tablet-menu-expanded" style="display: none;">
        <ul class="nav flex-column sidebar  " style="display: flex;row-gap: 12px;">
            <li class="nav-item">
                <button class="nav-link tab-breadcum" type="button" @click="hideShowTabMenu('hide')">
                    <common-svgs type="menu-breadcrumps"></common-svgs>
                </button>
            </li>
            <li class="nav-item">
                <router-link class="nav-link"
                    :class="((auth.curentEndPoint == 'class-students' || auth.curentEndPoint == 'student-details') ? 'router-link-active' : '')"
                    :to="{ name: 'dashboard' }">
                    <common-svgs type="sidebar-home-icon"></common-svgs>
                    Dashboard
                </router-link>
            </li>
            <li class="nav-item" v-if="auth.userinfo.type == 'School'">
                <router-link class="nav-link"
                    :class="(auth.curentEndPoint == 'school-students' ? 'router-link-active' : '')"
                    :to="{ name: 'school-students' }">
                    <common-svgs type="students-icon"></common-svgs>
                    Students
                </router-link>
            </li>
            <span class="navbar-text hidetext">
                Accounts Setting & Support
            </span>
            <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'account-settings' }">
                    <common-svgs type="sidebar-setting-icon"></common-svgs>
                    Account
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'account-help' }">
                    <common-svgs type="sidebar-info-icon"></common-svgs>
                    Help
                </router-link>
            </li>
        </ul>
    </div>
</template> 
<!-- position: absolute;
width: 20px;
height: 20px;
left: calc(50% - 20px/2);
top: 0px -->
<script setup>
import { useAuthStore } from "@/store/auth.js";
import CommonSvgs from "@/components/CommonSvgs.vue";

const auth = useAuthStore();
function hideShowTabMenu(cond) {
    if (cond == 'hide') {
        $('.tablet-menu-expanded').fadeOut();
        $('.right-router-view').removeClass('blurit');
    } else {
        $('.right-router-view').addClass('blurit');
        $('.tablet-menu-expanded').fadeIn();

    }
}
</script>
<style scoped> .nav {
     display: grid;
     column-gap: 24px;
 }

 .sidebar .nav-link:hover svg {
     fill: var(--primary);
 }

 .nav-item {
     display: flex;
     flex-direction: row;
     align-items: center;
     padding: 8px 32px 8px 16px;
     column-gap: 12px;
 }

 .router-link-active svg {
     fill: var(--primary) !important;
 }

 .nav-link:hover {
     background: #FFFFFF;
     box-shadow: 0px 2px 15px rgba(240, 240, 240, 0.25);
     border-radius: 12px;
 }

 .router-link-active {
     background: #FFFFFF;
     box-shadow: 0px 2px 15px rgba(240, 240, 240, 0.25);
     border-radius: 12px;
     fill: var(--primary) !important;
     color: var(--primary) !important;
 }

 .nav-link {
     display: flex;
     flex-direction: row;
     align-items: center;
     column-gap: 12px;
     font-family: 'Inter';
     font-style: normal;
     font-weight: 600;
     font-size: 14px;
     line-height: 24px;
     color: #89939E;
     width: 188px;

 }

 .navbar-text {
     font-family: 'Inter';
     font-style: normal;
     font-weight: 600;
     font-size: 12px;
     line-height: 15px;
     color: #7A8B94;
     padding: 8px 32px 8px 16px;
 }

 @media (max-width: 915px) {
     .sidebar {
         display: block
     }

     .hidetext {
         display: none;
     }

     .large-screen-menu {
         display: none;
     }

     .tablet-screen {
         display: inline-flex !important;
         /* display: flex; */
         flex-direction: column;
         align-items: flex-start;
         gap: 10px;
     }

     .nav-link {
         width: 50px;
     }

     .nav-item {
         padding: 0px;
     }

     .tab-breadcum {
         display: flex;
         padding: 10px var(--16, 16px);
         flex-direction: column;
         align-items: flex-start;
         gap: var(--12, 12px);
         background: transparent;
         border: none;
         cursor: pointer;
     }
 }

 .tablet-menu-expanded .nav-item .nav-link.active {
     border-radius: 12px;
     background: rgba(36, 99, 235, 0.10);
 }

 .tablet-menu-expanded .nav-link {
     width: 188px;
 }

 .tablet-menu-expanded {
     position: absolute;
     top: 70px;
     left: 0px;
     z-index: 22;
     display: inline-flex;
     height: 100vh;
     overflow-y: scroll;
     width: 250px;
     padding: var(--40, 40px) var(--24, 24px) 421px var(--48, 48px);
     align-items: flex-start;
     gap: 10px;
     flex-shrink: 0;
     background: var(--white-100, #FFF);
 }

 /* @media () {
    tablet-screen
 } */
</style>