<template>
  <!-- Types:
  lirt - Left Image Right Text
  ltri - Left Text Right Image
  -->
  <div class="row">
    <!-- computer-training -->
    <div class="col-md-6 " v-if="$attrs.type == 'lirt'">
      <img :src="$attrs.img" class="img-fluid d-sm-none " />
      <img :src="$attrs.img" class="bigscreenimg d-none d-sm-block" />
    </div>
    <div class="col-md-6 text-right d-sm-none" style="float: right;" v-if="$attrs.type == 'ltri'">
      <img :src="$attrs.img" class="img-fluid d-sm-none" />
    </div>
    <div class="col-md-6 text-section">
      <h3>{{ $attrs.heading }}</h3>
      <h6>{{ $attrs.shortdesc }}</h6>
    </div>
    <div class="col-md-6 text-right" style="float: right;" v-if="$attrs.type == 'ltri'">
      <img :src="$attrs.img" class="bigscreenimg d-none d-sm-block" style="float:right" />
    </div>
  </div>
</template>
<style scoped>
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 83px;
}

.bigscreenimg {
  /* max-width: 300px;  */
}

.text-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}

h6 {
  text-align: left;
}

@media (max-width: 915px) {
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 20px;
  }

  .text-section {
    gap: 8px;
    margin-top: 32px;
  }

}
</style>
 
