<template>
  
    <router-link :to="'/account-help-s/' + title.toLowerCase() + '/general'" :class="['btn', 'btn-clean','mm-0', customClass]">
      <common-svgs style="width:80px;height:80px"  :type="svgType"></common-svgs>
      <span class="title" :style="{'color':stroke_}">{{ title }}</span>
      <common-svgs  type="arrow-right" :stroke="stroke"></common-svgs>
    </router-link>   
 
</template>

<script >

import CommonSvgs from "@/components/CommonSvgs.vue";

export default {
  props: {
    customClass: String,
    title: String,
    svgType: String,
    stroke: String,
  },
  components: {
    CommonSvgs,
  },
  methods:{
  
  }
};
</script>

<style scoped>

.btn-clean {
  display: flex;
  flex-direction: row;
  align-items:center !important;
  justify-content: space-between !important;

}

.title {
  font-weight: 500;
  font-family: Apercu;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}
</style>
