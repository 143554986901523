<template>
    <nav class="navbar navbar-expand-lg navbar-expand-md navbar-light nav-header navbar-fixed ">
        <div class="container nav-container pr-0">
            <router-link class="navbar-brand" :to="{ name: 'home' }">
                <img :src="auth.baseUrl + 'files/assets/img/logo.svg'">
            </router-link>

            <button v-if="(auth.token != '' && auth.token != null)" class="notification-btn d-block d-lg-none" type="btn"
                style="border: none; background: transparent;">
                <span class="badge badge-danger counter">{{ auth.notification_counter }}</span>
                <img :src="auth.baseUrl + 'files/assets/img/avatarplaceholder.png'" class="avatar-img"
                    @click="toggleNotifications()" />
            </button>
            <button class="navbar-toggler collapsed " type="button" @click="toggleMenu($event)"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>


            <div class="collapse navbar-collapse   " id="navbarSupportedContent">
                <ul v-if="auth.userinfo.type != 'Parent' && !auth.noHeaderMenu"
                    class="navbar-nav me-auto mb-2 mb-lg-0 d-flex ">
                    <li class="nav-item">
                        <router-link class="nav-link hidetopmenu" :to="{ name: 'courses' }">Courses</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link hidetopmenu" :to="{ name: 'contactus' }">Contact us</router-link>
                    </li>

                </ul>
                <ul v-if="(auth.token == '' || auth.token == null)" class="navbar-nav mr-auto ms-auto mb-2 mb-lg-0 d-flex ">
                    <li class="nav-item menutext"
                        v-if="auth.curentEndPoint == 'join-student' || auth.curentEndPoint == 'signup' || auth.curentEndPoint == 'join-parent'">
                        Already have an account?
                    </li>
                    <li class="nav-item menutext"
                        v-if="auth.curentEndPoint == 'login' || auth.curentEndPoint == 'forgot-password'">
                        Dont have an account?
                    </li>
                    <li class="nav-item" style="margin-right: 8px;">
                        <router-link v-if="auth.curentEndPoint != 'login' && auth.curentEndPoint != 'forgot-password'"
                            :class="(auth.curentEndPoint != 'join-student' && auth.curentEndPoint != 'signup' && auth.curentEndPoint != 'join-parent') ? ' btn-clean btn-clean-primary ' : ' btn-primary '"
                            class="btn  btn-block hidetopmenu  mr-2" :to="{ name: 'login' }">Login</router-link>
                    </li>

                    <li class="nav-item"
                        v-if="auth.curentEndPoint != 'join-student' && auth.curentEndPoint != 'signup' && auth.curentEndPoint != 'join-parent'">
                        <router-link class="btn hidetopmenu btn-primary btn-block"
                            :to="{ name: 'signup' }">Register</router-link>
                    </li>
                </ul>

                <ul v-else class="navbar-nav mr-auto ms-auto mb-2 mb-lg-0 d-flex ">
                    <li class="nav-item menutext">
                        <button class="notification-btn hidden-xs" type="btn"
                            style="border: none; background: transparent;">
                            <span class="badge badge-danger counter">{{ auth.notification_counter }}</span>
                            <img :src="auth.baseUrl + 'files/assets/img/avatarplaceholder.png'" class="avatar-img"
                                @click="toggleNotifications()" />
                        </button>

                        <div class="dropdown">
                            <button class="btn  dropdown-toggle dropdownbtn menu-dropdown-toggle" type="button" id="dd123"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                {{ auth.userinfo.full_name ?? auth.userinfo.username }}
                                <common-svgs :type="'arrow-down'"></common-svgs>
                            </button>
                            <ul class="dropdown-menu dropdownbtndata " aria-labelledby="dd123">
                               
                                <li v-if="(auth.userinfo.is_verified)">
                                    <router-link
                                        class="dropdown-item" :to="{ name: 'dashboard' }">
                                        <common-svgs :type="'dashboard-icon'"></common-svgs> &nbsp;Dashboard
                                    </router-link>
                                </li>
                                <li v-if="(auth.userinfo.is_verified)"><router-link
                                        class="dropdown-item profilebtn" :to="{ name: 'account-settings' }">
                                        <common-svgs :type="'profile'"></common-svgs> &nbsp;Account
                                    </router-link>
                                </li>
                                 
                                <li>
                                    <button class="dropdown-item text-danger" @click="logout">
                                        <common-svgs :type="'logout-icon'"></common-svgs> Logout
                                    </button>
                                </li>
                            </ul>
                        </div>

                    </li>

                </ul>
            </div>
        </div>
    </nav>
    <div class="notifications" style="display: none;" v-if="(auth.token != '' && auth.token != null)">
        <div class="col-md-12 notification-area" style="">
            <h4 v-if="auth.notification_counter > 0 && parent_invitations.length > 0" class="notification-label">
                Notification</h4>
            <div class="d-flex" v-if="notifications.length > 0" v-for="notification in notifications">
                <div class="col-md-1 mt-36">
                    <img src="../../../public/files/assets/img/avatarplaceholder.png" class="avatar-img">
                </div>
                <div class="col-md-9">

                    <p class="parent-invitation-label mb-0 mt-36">{{ parseReturnValue(notification.data, 'title') }}</p>
                    <p class="invitation-text">{{ parseReturnValue(notification.data, 'message') }}</p>
                    <div v-if="notification.status == 'Pending'">
                        <button class="btn btn-primary col-md-5 reject-btn"
                            @click="rejectInvitation(notification.id, 'Rejected')">
                            Reject
                        </button>
                        <button class="btn btn-primary col-md-6 accept-btn"
                            @click="acceptInvitation(notification.id, 'Accepted')">
                            Accept as Parent
                        </button>
                    </div>
                    <div class="date-field">
                        <p>{{ modifyInvitationDate(notification.created_at) }}</p>
                    </div>
                    <hr>
                </div>
            </div>
            <div v-else class="mt-2 ml-3">
                <div class="no-notification-found">
                    <common-svgs :type="'bell-icon'"></common-svgs>
                </div>
                <h4 class="no-notification-found-text">Updates to keep you on track</h4>
                <p class="personalised-text">See personalised study reminders and timely <br />updates
                    from enrolled courses, and more.</p>
            </div>

        </div>
    </div>
    <privacy-policy></privacy-policy>
    <terms-condition></terms-condition>
    <toaster :text="''"></toaster>
</template> 
<script setup>
import { ref, watch, onMounted, onCreated } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";
import factory from "@/common/factory.js";
import CommonSvgs from "@/components/CommonSvgs.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsCondition from "@/views/TermsCondition.vue";
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import { register } from 'register-service-worker';
// import { getMessaging, onMessage,firebaseApp } from "firebase/messaging";
// import { messaging, onMessage, getToken } from '@/common/firebase.js';
import toaster from "@/components/Toaster.vue";

const route = useRoute();
const router = useRouter();

const auth = useAuthStore();
var is_invitations = ref(true);
var modify_date = ref('');
var modify_month = ref('');
var modify_year = ref('');
var modify_created_at = ref('');
var parent_invitations = ref(0);
var notificationCount = ref(0);
var notifications = ref([]);
// var count = ref(0);
function toggleMenu(e) {
    if ($('#navbarSupportedContent').css("display") === "none") {
        $('.navbar-toggler-icon').addClass('navbar-toggler-icon-cross');
        $('.navbar-toggler-icon-cross').removeClass('navbar-toggler-icon');
    } else {
        $('.navbar-toggler-icon-cross').addClass('navbar-toggler-icon');
        $('.navbar-toggler-icon').removeClass('navbar-toggler-icon-cross');
        // myDiv is not hidden
    }
    $('#navbarSupportedContent').fadeToggle();
}

$(document).on('click', '.hidetopmenu', function () {
    $('.navbar-toggler-icon-cross').addClass('navbar-toggler-icon');
    $('.navbar-toggler-icon').removeClass('navbar-toggler-icon-cross');
    $('#navbarSupportedContent').fadeToggle();
});
function parseReturnValue(jsonstring, key) {
    var jsondata = JSON.parse(jsonstring);
    return jsondata[key];
}
register('firebase-messaging-sw.js')
onMounted(() => {
    initFcm();
    getNotifications();
});
function initFcm() {

    const firebaseConfig = {
        apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID
    };

    // Initialize the Firebase app
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    if ((auth.fcmToken == null || auth.fcmToken == undefined) && auth.token != null) {
        initFcmToken(messaging);
    }
    // Onmessage called


    onMessage(messaging, (payload) => {
        console.log(payload);
        getNotifications();
        // invitations();
    });

    navigator.serviceWorker.addEventListener('message', event => {
        // console.log(event);
        // invitations();
        console.log(event);
        getNotifications();
    });

}
async function initFcmToken(messaging) {
    await getToken(messaging).then((token) => {
        swal.close();
        auth.fcmToken = token;
        console.log('FCMTOKEN: ' + token);
        const storeToken = new factory(getHeaderToken(), 'save-fcm-token', { token: token });
        storeToken.postForm(true).then((response) => {

        });
    });
}
function logout() {
    // Swal.fire({
    //   title: "Loading....",
    //   text: "Please Wait, This may take a while...",
    //   iconHtml: `<div class="loader-block">
    // <svg id="loader2" viewBox="0 0 100 100">
    // <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
    // </svg>
    // </div>`,
    //   showConfirmButton: false
    // });
    // window.localStorage.clear();
    // auth.token = null;
    // auth.userinfo = {};
    // window.localStorage.removeItem('token');
    // window.localStorage.removeItem('user');
    // this.user = null;
    // this.$router.push({ name: 'Login', key: "$route.fullPath" });
    // window.location.href = "login";
    // window.location.href = auth.baseUrl + "dashboard";
    router.push({ name: 'logoutpage' });
}

function toggleNotifications() {
    invitations();
    getNotifications();
    $('.notifications').toggle();
    markNotificationRead();
}

async function markNotificationRead() {
    const notificationApi = new factory(getHeaderToken(), 'mark-read-notification');
    await notificationApi.getHttp().then((response) => {
        swal.close();
        auth.notification_counter = 0;
    });
}

function getHeaderToken() {
    const token = {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.token,
        },
    };
    return token;
}

async function getNotifications() {
    if (auth.token != null) {
        const notificationApi = new factory(getHeaderToken(), 'get-notifications');
        await notificationApi.getHttp().then((response) => {
            swal.close();
            // notificationCount.value = response.data.data.length;
            auth.notification_counter = response.data.data?.unreadcount ?? 0;
            notifications.value = response.data.data?.notifications ?? [];

        });
    }
};
async function invitations() {

    const students = new factory(getHeaderToken(), 'parent-requests');
    await students.getHttp().then((response) => {
        swal.close();

        // console.log("res",response);
        if (response != undefined) {
            if (response.status == 200) {
                if (response.data.data != null) {
                    if (response.data.data.length > 0) {
                        is_invitations.value = true;
                        parent_invitations.value = response.data.data;
                        // count.value = response.data.data.length;
                        // auth.notification_counter = response.data.data.length;

                    }
                    else {
                        // auth.notification_counter = 0;
                        is_invitations.value = false;
                    }
                    is_invitations.value = true;
                }
                else {
                    // auth.notification_counter = 0;

                    is_invitations.value = true;
                }
            }
            else {
                is_invitations.value = false;
            }
        }
        else {
            is_invitations.value = false;
        }
    });
};

async function rejectInvitation(id, status) {
    var form = {
        'invitation_id': id,
        'status': status
    };
    const invitation = new factory(getHeaderToken(), 'change-invitation-status', form);
    await invitation.postForm().then((response) => {
        // console.log(response);
        if (response.data.status) {
            swal.fire('Done!', 'Invitation Rejected.', 'success');
            invitations();
        }
        else {
            swal.fire('Error!', 'Something went wrong.', 'error');
            // console.log(response.data.message);
        }
    });
}

async function acceptInvitation(id, status) {
    var form = {
        'invitation_id': id,
        'status': status
    };
    const invitation = new factory(getHeaderToken(), 'change-invitation-status', form);
    await invitation.postForm().then((response) => {
        // console.log(response);
        if (response.data.status) {
            swal.fire('Done!', 'Invitation Accepted.', 'success');
            invitations();
        }
        else {
            swal.fire('Error!', 'Something went wrong.', 'error');
            // console.log(response.data.message);
        }
    });
}

function modifyInvitationDate(invitation_creation_date) {
    const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const date = new Date(invitation_creation_date);
    modify_date.value = date.getDate();
    modify_month.value = month[date.getMonth()];
    modify_year.value = date.getFullYear();
    modify_created_at.value = modify_date.value + ' ' + modify_month.value + ' ' + modify_year.value;
    return modify_created_at.value;
}
watch(() => auth.curentEndPoint, (newValue, oldValue) => {
    // console.log("type",auth.userinfo.type);
    getNotifications();
    if (auth.userinfo.type == 'Student') {
        // invitations();
    }
    if (auth.userinfo.type == 'Student' || auth.userinfo.type == 'Parent') {
        if (auth.userinfo.email_verification != 1) {
            getUserInfo();
        }
    }
});
function getUserInfo() {
    const UserInfo = new factory(getToken(), 'user-information');
    UserInfo.getHttp().then((response) => {
        if (response.data.status) {
            auth.userinfo.email_verification = response.data.data.email_verification;
        }
    });
}

</script>

<style scoped>
.dropdown-menu {
    min-width: 15rem;
}

.dropdown-item {
    padding: 1.25rem 1rem;
}

.btn-clean-primary {
    border-radius: 4px;
}

.navbar-light .navbar-toggler:focus {
    color: white;
}

.navbar-light .navbar-toggler {
    border: none;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('/public/files/assets/img/menu.svg');
}

.navbar-light .navbar-toggler-icon-cross {
    background-image: url('/public/files/assets/img/cross.svg');
    height: 24px;
    width: 24px;
    display: block;
}

.nav-link {
    padding: 12px !important;
}

.router-link-active:not(.navbar-brand) {
    background: rgba(0, 63, 136, 0.05);
    border-radius: 4px;
    color: var(--primary) !important;
}

.router-link-active.profilebtn {
    color: black !important;

}

.fa-times {
    font-size: 24px !important;
    color: black;
    font-weight: 500;
}

.menutext {
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-right: 12px;
}

.loggedin-dev {
    padding: 0px;
    column-gap: 24px;
}

.avatar-img {
    padding: 0px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.loggedin-img {}

.loggedin-dropdown {
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 4px;
}

.mt-36 {
    margin-top: 36px !important;
}

.parent-invitation-label {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 4px !important;
}

.invitation-text {
    font-size: 14px;
    color: #212121;
    font-weight: 400;
}

.ml-16 {
    margin-left: 16px !important;
}

.reject-btn {
    background: #FFFFFF;
    box-shadow: 0px 15px 30px rgba(186, 186, 186, 0.1);
    border-radius: 12px;
    margin-right: 6px;
    color: #2463EB;
    font-size: 10px !important;
}

.date {
    color: #626262;
    font-size: 12px;
}

.notifications {
    position: absolute;
    background-color: #fff;
    top: 90px;
    width: 460px;
    right: 255px;
    max-height: 500px;
    display: none;
    border-radius: 12px;
    overflow-y: auto;
    z-index: 9999;
}

.notification-area {
    padding: 32px 24px;
}

.notification-area .col-md-9 {
    margin-left: 16px;
}

.reject-btn-text {
    font-size: 10px !important;
    margin-bottom: 0px !important;
    color: #2463EB;
}

.accept-btn {
    font-size: 10px !important;
    margin-bottom: 0px !important;
    font-size: 10px !important;
}

.date-field {
    padding-top: 16px !important;
    margin-left: 4px !important
}

.counter {
    position: relative;
    top: -20px;
    right: -47px;
    background: red;
    color: white !important;
    border-radius: 50%;
}

.notifications::-webkit-scrollbar {
    display: none;
}

/* For Notification */
@media (max-width: 768px) {
    .notifications {
        top: 135px;
        width: 94%;
        left: 3%;
        right: unset;
    }

    .notification-area {
        padding: 15px 12px;
    }

    .notification-area .col-md-9 {
        margin-left: 2px;
    }

    .hidden-xs {
        display: none;
    }

    .navbar .nav-container {
        column-gap: 0px;
    }
}

.no-notification-found {
    display: flex;
    justify-content: center;
}

.no-notification-found-text {
    text-align: center;
}

.personalised-text {
    color: #4D4D4D;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
}
</style>