<template>
  <div class="row">
    <div class="col-md-12 mb-24p">
      <heading-card v-if="auth.userinfo.type == 'Student' || auth.userinfo.type == 'School'" title="Account Settings"
        :bgimg="auth.baseUrl + 'files/assets/img/cardbg.png'"></heading-card>
      <heading-card v-else title="Account Settings"
        :bgimg="auth.baseUrl + 'files/assets/img/parent_bg.jpg'"></heading-card>
    </div>
    <div class="col-md-12 mb-3">
      <card-box class="SubmitOnEnter" title="General Information" htype="h4">

        <date-input :disabled="dobDisable" v-if="auth.userinfo.type == 'Student'"
          :minDate="$moment($moment()).subtract(4, 'years').format('YYYY-MM-DD')" type="date" class="col-md-6 dob"
          v-model="accountinfo.dob" placeholder="Date of Birth" label="Date of Birth"></date-input>
        <text-input disabled class="col-md-6 fname" v-model="accountinfo.full_name" placeholder="Full Name"
          label="Full Name"></text-input>
        <text-input disabled class="col-md-6 uname" readonly v-model="username" placeholder="Username"
          label="Username"></text-input>

        <!-- <select-box class="col-md-6" v-model="accountinfo.gender" label="Gender" v-if="auth.userinfo.type == 'Parent'">
          <option value="MALE">Male</option>
          <option value="FEMALE">Female</option>
          <option value="OTHER">Other</option>
        </select-box> -->


        <text-input disabled class="col-md-6 phone1" type="phone" v-model="accountinfo.phone1" maxLength="11"
          :placeholder="((auth.userinfo.type == 'Parent') ? 'Phone Number' : 'Parent\'s Phone Number')"
          :label="((auth.userinfo.type == 'Parent') ? 'Phone Number' : 'Parent\'s Phone Number')"></text-input>
        <text-input disabled v-if="auth.userinfo.type == 'Student'" maxLength="11" class="col-md-6 phone2"
          v-model="accountinfo.phone2" placeholder="Personal Phone Number" label="Personal Phone Number"></text-input>
        <text-input disabled class="col-md-6 email" v-model="accountinfo.email" placeholder="Email Address"
          label="Email Address"></text-input>
        <text-input disabled v-if="auth.userinfo.type == 'Student'" readonly class="col-md-6" v-model="schoolname"
          placeholder="School" label="School"></text-input>
        <text-input disabled class="col-md-6" v-model="cityname" readonly placeholder="City" label="City"></text-input>
        <text-input disabled v-if="auth.userinfo.type == 'Student'" readonly class="col-md-6" v-model="classname"
          placeholder="Class" label="Class"></text-input>
        <text-input disabled v-if="auth.userinfo.type == 'Student'" class="col-md-6 postal_address"
          v-model="accountinfo.postal_address" placeholder="Postal Address" label="Postal Address"></text-input>
        <div class="col-md-3 mb-3 text-right offset-md-9" v-if="!editinfo">
          <button class="btn btn-primary btn-block" @click="editInfo">Edit</button>
        </div>
        <div class="col-md-12 mb-3 text-right " v-if="editinfo">
          <button class="btn btn-primary-outline mr-12 " @click="cancelAccountInfo">Cancel</button>
          <button class="btn btn-primary submitItBtn" v-if="editinfo" @click="updateAccountInfo">Save</button>
        </div>
      </card-box>
    </div>
    <div class="col-md-12">
      <div class="card" v-if="changePassStep" style="border-radius: 12px;">
        <div class="card-body" style="padding: 48px !important;">
          <div class="row"><!--v-if-->
            <div class="col-md-6 mb-3" style="display: flex;
    justify-content: center;
    flex-direction: column;">
              <h4>Change password</h4>
            </div>
            <div class="col-md-6 mb-3 text-right">
              <button class="btn btn-primary " @click="changePasswordToggle()">Change Password</button>
            </div>
          </div>
        </div>
      </div>
      <card-box class="SubmitOnEnter" title="Change password" htype="h4" v-if="!changePassStep">
        <password-input v-model="form.current_password" :error="error.current_password" class="col-md-6 current_password"
          label="Current Password" placeholder="Current Password"></password-input>

        <password-input v-model="form.password" :error="error.password" class="col-md-6 password" label="Enter Password"
          placeholder="Enter Password"></password-input>

        <password-input v-model="form.password_confirmation" :error="error.password_confirmation"
          class="col-md-6 password_confirmation" label="Confirm Password" placeholder="Confirm Password"></password-input>

        <div class="col-md-12 mb-3 text-right">
          <button class="btn btn-primary submitItBtn" @click="changePassword">Change Password</button>
        </div>
      </card-box>
    </div>
  </div>
</template> 
<script setup>

import { ref, reactive } from 'vue';
import moment from 'moment';
import factory from '@/common/factory.js';
import { functions } from '@/common/functions.js';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";

import DateInput from "@/components/DateInput.vue";
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import CardBox from "@/components/CardBox.vue";
import HeadingCard from "@/components/HeadingCard.vue";

const auth = useAuthStore();
const route = useRoute();
const router = useRouter();

var changePassStep = ref(true);
function changePasswordToggle() {
  if (changePassStep.value) {
    changePassStep.value = false;

  } else {
    changePassStep.value = true;

  }
}

var editinfo = ref(false);
var dobDisable = ref(true);
function editInfo() {
  if (editinfo.value == false) {
    editinfo.value = true;
    $('.dob').find('input').removeAttr('disabled');
    $('.fname').find('input').removeAttr('disabled');
    $('.phone1').find('input').removeAttr('disabled');
    $('.phone2').find('input').removeAttr('disabled');
    $('.email').find('input').removeAttr('disabled');
    $('.postal_address').find('input').removeAttr('disabled');
    dobDisable.value = false;
  } else {
    $('.dob').find('input').attr('disabled', true);
    $('.fname').find('input').attr('disabled', true);
    $('.phone1').find('input').attr('disabled', true);
    $('.phone2').find('input').attr('disabled', true);
    $('.email').find('input').attr('disabled', true);
    $('.postal_address').find('input').attr('disabled', true);
    dobDisable.value = true;
    editinfo.value = false;
  }
}
var form = reactive({
  current_password: '',
  password: '',
  password_confirmation: ''
});
var accountinfo = ref({
  dob: '',
  full_name: '',
  username: '',
  gender: '',
  phone1: '',
  phone2: '',
});
var error = ref({});
// accountinfo.value.dob = moment(accountinfo.value.dob);
var username = ref('');
var cityname = ref('');
var schoolname = ref('');
var classname = ref('');

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + auth.token,
    },
  };
  return token;
}
function getUserInfo() {
  const UserInfo = new factory(getToken(), 'user-information');
  UserInfo.getHttp().then((response) => {
    if (response.data.status) {
      if (route.query.addemail == 1) {
        editInfo();
      }
      accountinfo.value = response.data.data;
      // accountinfo.value.dob = moment(accountinfo.value.dob);
      username.value = response.data.data.username;
      cityname.value = response.data.data.city;
      schoolname.value = response.data.data.school;
      classname.value = response.data.data.class; 
      auth.userinfo.email_verification = response.data.data.email_verification;
      // swal.fire('Done!', response.data.message, 'success');
    } else {
      // swal.fire('Error!', response.data.message, 'error');
    }
  });
}
getUserInfo();

async function changePassword() {

  const ChangePass = new factory(getToken(), 'change-password', form);
  await ChangePass.postForm().then((response) => {
    if (response.data.status) {
      swal.fire('Done!', response.data.message, 'success');
      changePassStep.value = true;
      form.current_password = '';
      form.password = '';
      form.password_confirmation = '';
    } else {
      error.value = functions.errorResponse(response.data);
    }
  });
}
function cancelAccountInfo() {
  editInfo();
  getUserInfo();
}
async function updateAccountInfo() {
  const AccountInfo = new factory(getToken(), 'update-account', accountinfo.value);
  await AccountInfo.postForm().then((response) => {
    if (response.data.status) {
      if (auth.userinfo.email != accountinfo.value.email) {
        auth.userinfo.email_verification = 0;
      }
      auth.userinfo.full_name = accountinfo.value.full_name;
      auth.userinfo.phone1 = accountinfo.value.phone1;
      auth.userinfo.phone2 = accountinfo.value.phone2;
      if (auth.userinfo.email != accountinfo.value.email) {
        displayToaster('Verification link sent');
      }
      auth.userinfo.email = accountinfo.value.email;
      editInfo();
      swal.fire('Done!', response.data.message, 'success');
    } else {
      swal.fire('Error!', response.data.message, 'error');
    }
  });
}
function stepOneBack() {
  router.push('/');
}
</script> 