<script setup>
import { ref, onMounted, onUpdated } from "vue";
import TopicBox from "@/components/topic/TopicBox.vue";
import TopicVideo from "@/components/topic/TopicVideo.vue";
import TopicPdf from "@/components/topic/TopicPdf.vue";
import CommonSvgs from "@/components/CommonSvgs.vue";
import factory from '@/common/factory.js';
import { useAuthStore } from '@/store/auth.js';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
var auth = useAuthStore();
var currenttopicindex = ref(0);
var currenttopicindexVal = ref(0);
var currenttopic = ref({
    type: 'Video'
});
var currenttopicdata = ref({});
var courseid = ref(route.query.courseid);
var topicdetailid = ref(route.query.content);
onUpdated(() => {
    courseid.value = route.query.courseid;
    topicdetailid.value = route.query.content;
})


var topicContents = ref([]);
var settings = ref({});
var topic = ref({});
var othertopics = ref([]);
var subjectclass_id = ref(route.params.courseid);
function getToken() {
    const token = {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.token,
        },
    };
    return token;
}


async function topicDetails() {
    var filter = {
        id: route.params.id,
        content: topicdetailid.value
    }
    const clsStd = new factory(getToken(), 'topic-details', filter);
    await clsStd.getHttp().then((response) => {
        swal.close();
        if (response.data.status) {
            topic.value = response.data.data.topic;
            topicContents.value = response.data.data.topic_contents;
            settings.value = response.data.data.settings;
            othertopics.value = response.data.data.othertopics;
            // console.log(othertopics.value);
            // currenttopicdata = (response.data.data.topic_contents[0])?response.data.data.topic_contents[0]:{}
            if (topicdetailid.value > 0) {
                topicSelected(topicdetailid.value);
            } else {
                currenttopic.value = (response.data.data.topic_contents[0]) ? response.data.data.topic_contents[0] : {}
            }

            currenttopicindexVal.value = othertopics.value.findIndex((item) => (item.id == topic.value.id));
        }
    });
}
onMounted(() => {
    topicDetails();
    courseid.value = route.query.courseid;
    // if(currenttopic)
});

//     {
//         'topicid': 1,
//         'title': 'Factors',
//         'type': 'video',
//         'length': 20,
//         'iscomplete': true,
//     },
//     {
//         'topicid': 2,
//         'title': 'Prime & Composite Numbers',
//         'type': 'video',
//         'length': 18,
//         'iscomplete': false,
//     },
//     {
//         'topicid': 3,
//         'title': 'Index Notation & Prime Factorization',
//         'type': 'content',
//         'length': 0,
//         'iscomplete': false,
//     },
// ];

function docStatusChanged() {
    topicContents.value[currenttopicindex.value].status = true;
}

function prevBtn() {
    if (currenttopicindex.value > 0) {
        currenttopicindex.value = currenttopicindex.value - 1;
        currenttopic.value = topicContents.value[currenttopicindex.value];
        window.scrollTo(0, 0);
    }
}
function nextBtn() {
    if (currenttopicindex.value >= 0 && currenttopicindex.value < (topicContents.value.length - 1)) {
        currenttopicindex.value = currenttopicindex.value + 1;
        currenttopic.value = topicContents.value[currenttopicindex.value];
        window.scrollTo(0, 0);
    }
}
function prevTopicBtn(){
    currenttopicindexVal.value = currenttopicindexVal.value - 1;
    var topic = othertopics.value[currenttopicindexVal.value];
    window.scrollTo(0, 0);

    changeRoute(topic.id);
}
function nextTopicBtn() {
    currenttopicindexVal.value = currenttopicindexVal.value + 1;
    var topic = othertopics.value[currenttopicindexVal.value];
    window.scrollTo(0, 0);

    changeRoute(topic.id);
}
function openTopic(id){
    currenttopicindexVal.value = othertopics.value.findIndex((item) => (item.id == id));
    window.scrollTo(0, 0);
    changeRoute(id);
}
// Function to change the route
const changeRoute = (id) => {
    // Use router.push with the desired route and query parameters
    router.push({
        name: 'topic',
        params: { id: id },
        query: {
            courseid: route.query.courseid
        }
    }).then(() => {
        topicDetails();
        currenttopicindex.value = 0;
    });
};

function topicSelected(topicid) {
    var topicindex = topicContents.value.findIndex((item) => (item.id == topicid));
    currenttopicindex.value = topicindex;
    currenttopic.value = topicContents.value[currenttopicindex.value];
    window.scrollTo(0, 0);
}

</script> 
<template>
    <div class="container">

        <div class="row">
            <div class="col-md-3" style="border-right: 1px solid rgba(113, 113, 113, 0.1);">
                <div class="row">
                    <div class="col-md-12 page-maincontainer  sidemenu-topic">
                        <router-link :to="{ path: '/course/' + courseid }" class="btn custom-back-btn">
                            <common-svgs style="margin: 0 auto;" type="arrow-left"></common-svgs>
                            Back to Course
                        </router-link>
                        <h4>{{ topic.chaptername }}</h4>
                        <div class="topics">
                            <div class="topic" v-for="topics in othertopics" >
                                <div class="topic-title" style="position: relative;" @click="openTopic(topics.id)">
                                    <b>Topic {{ (parseInt(topics.sort_order ?? 0) + 1) }}: {{ topics.title }}</b>
                                    <common-svgs style="position: absolute;right: 0px;" :stroke="'black'"
                                        :type="'arrow-down'"></common-svgs>
                                </div>
                                <div class="topic-contents" v-if="topics.id == topic.id">
                                    <topic-box v-for="(topic, key) in topicContents" :topicid="topic.id"
                                        @topic-clicked="topicSelected" :title="topic.title" :type="topic.type"
                                        :length="topic.video_duration" :isactive="(key == currenttopicindex)"
                                        :iscomplete="topic.status"></topic-box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9 topic-content-view">
                <div class="breadcrumps">
                    <div class="breadcrump-content-layer">
                        <router-link :to="'/'" class="crump">Home</router-link>
                        <common-svgs style="margin: 0 auto;" :type="'crumb-right-arrow'"></common-svgs>
                        <b class="crump">{{ currenttopic.title }}</b>
                    </div>
                </div>

                <div class="card">
                    <b class="card-title-n"> {{ currenttopic.title }}</b>
                    <!-- <button type="button" class="btn btn-clean-primary">Next Chapter</button> -->
                </div>

                <div class="topic-content-description"> 
                    <topic-video @status_complete="docStatusChanged" :defaultcomplete="settings.video_completion_percentage"
                        :watchedduration="currenttopic.resume_from" :data="currenttopic" :subjectclass_id="courseid"
                        :topicid="topic.id" :chapterid="topic.chapter_id" v-if="currenttopic.type == 'Video'"></topic-video>
                    <topic-pdf @status_complete="docStatusChanged" :data="currenttopic" :subjectclass_id="courseid"
                        :topicid="topic.id" :chapterid="topic.chapter_id" v-else></topic-pdf>
                    <div class="topic-actions">
                        <button
                            v-if="currenttopicindexVal > 0 && currenttopicindex == 0"
                            type="button" @click="prevTopicBtn" class="btn  btn-prev" >Previous
                            Topic</button>
                        <button v-if="currenttopicindex > 0" type="button" @click="prevBtn" class="btn  btn-prev"
                            style="float:left">Previous</button>
                        <button v-if="currenttopicindex < (topicContents.length - 1)" type="button" @click="nextBtn"
                            class="btn btn-primary"    style="float:right;padding: 12px 32px;">Next</button>
                        <button
                            v-if="currenttopicindexVal != (othertopics.length - 1) && currenttopicindex == (topicContents.length - 1)"
                            type="button" @click="nextTopicBtn" class="btn btn-primary" style="float:right;padding: 12px 32px;">Next
                            Topic</button>
                        <router-link
                            v-if="currenttopicindexVal == (othertopics.length - 1) && currenttopicindex == (topicContents.length - 1)"
                            :to="{ path: '/course/' + courseid }" class="btn btn-primary" style="float:right;padding: 12px 32px;">
                            Back to Course
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<style scoped>
.btn-prev {
    border-radius: 4px;
    background: rgba(36, 99, 235, 0.10);
    display: flex;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--primary);
    float:left;
}

.topic-actions {
    display: block;
    /* justify-content: space-between; */
    /* align-items: flex-start; */
    width: 100%;
}


.topic-content-description {
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    padding: 0px;
    gap: 32px;
}

.btn-clean-primary:hover {
    background-color: rgba(36, 99, 235, 0.1);
}

.btn-clean-primary {
    background-color: white;
}

.card-title-n {
    font-family: 'Apercu';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.card {
    background: #FFFFFF;
    box-shadow: 0px 15px 30px rgba(186, 186, 186, 0.1);
    border-radius: 12px;
    padding: 24px;
    flex-direction: row;
    justify-content: space-between;
}

.crump {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #676E76;
}

.breadcrump-content-layer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
}

.topic-content-view {
    padding-top: 136px;
    padding-left: 96px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 100px;
}

.breadcrumps {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}



.topic-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}

.topic-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 0px;
    gap: 186px;

}

.topic {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    padding: 0px;
    gap: 24px;
    width: 100%;
    cursor: pointer;
}

.topics {
    width: 100%;
}

.sidemenu-topic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    padding-right: 32px;
}

.page-maincontainer {
    padding-top: 126px !important;
}
</style>

  