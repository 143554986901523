<template>
  <div class="container" style="padding-top: 170px;">
    <div class="row mb-5">
      <div class="col-md-6 offset-md-3">

      </div>
    </div>
  </div>
</template>
<script setup>
import factory from '@/common/factory.js';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth.js";  

const auth = useAuthStore();
const route = useRoute();
const router = useRouter();

function getToken() {
  const token = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer ' + authStore.token,
    },
  };
  return token;
}
 
var resendlinkparms = {
  activation_code: route.query.activation_code,
  activation_token: route.query.activation_token
}
const VerifyAccount = new factory(getToken(), 'user-verification', resendlinkparms);
await VerifyAccount.putForm().then((response) => {
  if (response.data.status) {
    swal.fire('Done!', response.data.message, 'success');
    if(auth.token != '' && auth.token != null){
      auth.userinfo.is_verified = 1;
    }
    // window.location.href = '/dashboard';
    window.location.href = auth.baseUrl+"dashboard";
  } else {
    swal.fire('Error!', response.data.message, 'error'); 
  }
});

</script> 