<template>
  <div :class="$attrs.class ?? 'col-md-6'">
    <div class="form-check mb-3">
      <label v-if="label" class="form-check-label" :for="id">
        {{ label }}
      </label>
      <label class="form-label" :for="id">
        <div class="checkbox-portion">
          <input class="form-check-input" :id="id" ref="input" v-bind="{ ...$attrs, class: null }" type="checkbox"
            :value="modelValue" :checked="modelValue" @input="$emit('update:modelValue', $event.target.value)" @click="checkboxClicked($event)">
        </div>
        <div class="label-protion">
          <slot></slot>
        </div>
      </label>
      <small v-if="$attrs.helptext" class=" atext-1">{{ $attrs.helptext }}</small>
    </div>
  </div>
   
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${uuid()}`
      },
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    error: String,
    label: String,
    modelValue: [Boolean,String],
  },
  emits: ['update:modelValue','checkbox-clicked'],
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
    checkboxClicked(e){
      this.$emit('checkbox-clicked',$(e.target).prop('checked'))
    }
  },
}
</script>
<style scoped>
label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #4D4D4D;
  display: flex;
  align-items: center;
}

.checkbox-portion {
  width: 25px;
  float: left;
}
.form-check-input{
  width: 24px;
  height: 24px;
}
</style>
