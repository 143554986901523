<script setup>
import VimeoPlayer from '@/components/VimeoPlayer.vue';
import { defineEmits, defineProps, onMounted, onUpdated, ref,onBeforeUnmount,watch } from 'vue';
import { useAuthStore } from '@/store/auth.js';
import factory from '@/common/factory.js';

var auth = useAuthStore();

var props = defineProps(['data', 'topicid', 'chapterid', 'subjectclass_id', 'watchedduration', 'defaultcomplete']);
var data = ref(props.data);
var emit = defineEmits(['status_complete']);

var topicid = ref(props.topicid);
var chapterid = ref(props.chapterid);
var subjectclass_id = ref(props.subjectclass_id);
var watchedduration = ref(props.watchedduration);
var defaultcomplete = ref(props.defaultcomplete);

var vimeoPlayer = ref(null);

var percentageWatched = ref(0);
var minutesWatched = ref(0);
var secondsWatched = ref(0);
var realWatchedTime = ref(0);
var realPercentageWatched = ref(0);
var videoDuration = ref(0);
let timer = null;
let recusiveCallTimer = null;

onMounted(() => {
    data.value = props.data;
    realWatchedTime.value = 0;
    realPercentageWatched.value = 0;
    watchedduration.value = props.watchedduration;
    defaultcomplete.value = props.defaultcomplete;
    percentageWatched.value = 0;
})
onUpdated(() => {
    // recursiveCalls();
    data.value = props.data;
    topicid.value = props.topicid;
    chapterid.value = props.chapterid;
    subjectclass_id.value = props.subjectclass_id;
    watchedduration.value = props.watchedduration;
    defaultcomplete.value = props.defaultcomplete;
});
// Watch for changes on the prop value
watch(() => props.data.id, (newValue, oldValue) => {
    percentageWatched.value = 0;
    minutesWatched.value = 0;
    secondsWatched.value = 0;
    realWatchedTime.value = 0;
    realPercentageWatched.value = 0;
});
function handlePlay() {
    // console.log('Parent function handlePlay Called')
    if (!timer) {
        timer = setInterval(() => {
            if (realWatchedTime.value < videoDuration.value) {
                realWatchedTime.value += 1; // Increment real watch time by 1 second
            }
        }, 1000);
    }
    recursiveCalls();
}
function handlePause() {
    // console.log('Parent function handlePause Called')
    clearInterval(timer);
    timer = null;
    videoInfoStore();
}
function handleEnd() {
    // console.log('Parent function handleEnd Called')
    clearInterval(timer);
    timer = null;
    videoInfoStore();
}
function handleTimeUpdate(seconds) {
    // console.log('Parent function handleTimeUpdate Called')
    minutesWatched.value = Math.floor(seconds / 60);
    secondsWatched.value = seconds;
    percentageWatched.value = (seconds / videoDuration.value) * 100;
    realPercentageWatched.value = parseInt((realWatchedTime.value / videoDuration.value) * 100);

    if (data.value.status == 0 && parseInt(percentageWatched.value) > parseInt(defaultcomplete.value ?? 49)) {
        console.log('all condition true');
        videoInfoStore();
    }
    if (seconds < realWatchedTime.value) {
        clearInterval(timer);
        timer = null;
    } else {
        if (!timer) {
            timer = setInterval(() => {
                if (realWatchedTime.value < videoDuration.value) {
                    realWatchedTime.value += 1; // Increment real watch time by 1 second
                }
            }, 1000);
        }
    }
}
function getVideoDuration(duration) {
    videoDuration.value = duration;
    realWatchedTime.value = 0;
    realPercentageWatched.value = 0;
}



function getToken() {
    const token = {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + auth.token,
        },
    };
    return token;
}


async function videoInfoStore() {
    
    // if (data.value.status == null || data.value.status == 0 || (data.value.watched_duration < realWatchedTime.value)) {
    var filter = {
        topicdetailid: data.value.id,
        topicid: topicid.value,
        chapterid: chapterid.value,
        subjectclass_id: subjectclass_id.value,
        // watched_percentage: parseInt(realPercentageWatched.value),
        watched_percentage: parseInt(percentageWatched.value),
        watched_duration: parseInt(secondsWatched.value),
        resume_from: secondsWatched.value,
        type: 'Video'
    }
    // console.log(filter);
    // console.log('Completed api');
    // console.log(filter);
    const clsStd = new factory(getToken(), 'topic-content-complete', filter);
    await clsStd.getHttp().then((response) => {
        swal.close();
        // if (realPercentageWatched.value > (defaultcomplete.value ?? 49)) {
        if (parseInt(percentageWatched.value) > (defaultcomplete.value ?? 49)) {
            emit('status_complete');
        }
    });
    // }
}
function recursiveCalls() {
    // Should Look for a better option
    clearInterval(recusiveCallTimer);
    recusiveCallTimer = null;

    recusiveCallTimer = setInterval(function () {
        // console.log('recursive function called');
        videoInfoStore();
    }, 4000);
    // Should Look for a better option
}
onBeforeUnmount(()=>{
    percentageWatched.value = 0;
    clearInterval(recusiveCallTimer);
})
</script>
<template> 
    <div class="topic-content-video">
        <vimeo-player :watchedduration="watchedduration" :key="props.data.id" @videoDuration="getVideoDuration"
            @handlePlay="handlePlay" @handlePause="handlePause" @handleEnd="handleEnd" @handleTimeUpdate="handleTimeUpdate"
            :videoUrl="data.path"></vimeo-player>
    </div>
    <div class="topic-description" v-if="data.description">
        <h4>Description</h4>
        <p class="topic-description-text">
            {{ data.description }}
        </p>
    </div>

    <span v-if="false">
        <!-- This code is for testing purpose will be removed in future -->
        {{ parseInt(percentageWatched) + '%' }} <br />
        {{ minutesWatched }} min <br />
        Real Watch Time {{ realWatchedTime }} seconds <br />
        Real Watch Time {{ (realWatchedTime > 0) ? parseInt(realWatchedTime / 60) : 0 }} Min <br />
        Real Watch Percentage {{ parseInt(realPercentageWatched) + '%' }}
        <br />
        <!-- This code is for testing purpose -->
    </span>
</template>
<style scoped>
.topic-content-video {
    /* padding: 56.25% 0 0 0; */
    position: relative;
    width: 100%;
}

.topic-content-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.topic-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
}

.topic-description-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #212121;
}
</style>